<template>
  <div>
    <div class="vis-topbar vis-ma--none vis-setting-bar">
      <div class="vis-row vis-height-100">
        <div
          class="vis-dashboard-custom-col vis-flex--Ycenter vis-mobile-hidden vis-right-border"
        >
          <div class="vis-settings-tab-link">
            <div class="link-item">
              <span>{{ $t("dashboard.datasets") }}</span>
            </div>
            <i
              v-if="showDatamodelAddDatasetAction"
              @click="addNewDataset"
              class="vis-cursor-pointer vis-display-flex vis-flex--Ycenter add-new-dataset-button"
              :class="customIcon.AddBox"
              aria-hidden="true"
            />
          </div>
        </div>
        <div
          class="vis-col vis-mb--none vis-text--right vis-flex--Xright vis-flex--Ycenter vis-mobile-hidden vis-icon-position"
        >
          <div
            v-if="showDatamodelActions"
            class="vis-display-flex vis-flex--Ycenter"
          >
            <el-tooltip
              v-for="icon in iconList"
              :key="icon.id"
              :disabled="!icon.tooltip"
              popper-class="vis-bg-neon-blue"
              placement="bottom"
              effect="light"
            >
              <i
                class="vis-icon vis-cursor-pointer"
                :class="[
                  icon.className,
                  {
                    'vis-active-setting-icon': icon.isActiveClass,
                  },
                  { 'vis-display-none': !icon.show },
                ]"
                @click="icon.action()"
                aria-hidden="true"
              ></i>
              <div
                slot="content"
                class="vis-mx--05 vis-display-flex vis-flex-direction--column"
                v-if="isDataModelLocked"
              >
                <span class="vis-color-quartz vis-text--xsmall">{{
                  $t("generalPages.Locked By")
                }}</span>
                <span class="vis-color-white">{{
                  datamodelLockingInfo.lockCreatedBy
                }}</span>
                <span class="vis-color-quartz vis-text--xsmall vis-mt--05">{{
                  $t("generalPages.Locked Date")
                }}</span>
                <span class="vis-color-white">{{
                  formatISOToDateTime(
                  datamodelLockingInfo.lockCreatedDate)
                }}</span>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { formatISOToDateTime } from "../../util/moment";
import { Notify } from "../../commons/helper.js";
import { notificationType } from "../../commons/notificationTypes";

export default {
  props: {
    datamodelName: {
      type: String,
      default: "",
    },
    showDatamodelActions: {
      type: Boolean,
      default: false,
    },
    showDatamodelAddDatasetAction: {
      type: Boolean,
      default: false,
    },
    isSaveAvailable: {
      type: Boolean,
      default: true,
    },
    isDataModelPropertiesToggled: {
      type: Boolean,
      default: true,
    },
    isDataModelLocked: {
      type: Boolean,
      default: false,
    },
    datamodelLockingInfo: {
      type: Object,
      required: false,
    },
    currentUserUsername: {
      type: String,
      default: "",
    },
  },
  watch: {
    datamodelName: {
      handler(val) {
        if (val) this.name = this.datamodelName;
      },
    },
  },
  data() {
    return {
      name: "",
      isProjectNamePopupOpen: false,
      customIcon: CustomIcon,
      formatISOToDateTime: formatISOToDateTime,
    };
  },
  computed: {
    iconList() {
      return [
        {
          guid: 0,
          className: this.isDataModelLocked
            ? `${this.customIcon.LockClose} vis-mr--2`
            : `${this.customIcon.LockOpen} vis-mr--2`,
          action: () => this.$emit("lockDatamodel", !this.isDataModelLocked),
          isActiveClass: this.isDataModelLocked,
          show: true,
          tooltip: this.isDataModelLocked,
        },
        {
          guid: 1,
          className: `${this.customIcon.Filter} vis-mr--2`,
          action: () => this.$emit("isDataModelPropertiesVisible", true),
          isActiveClass: this.isDataModelPropertiesToggled,
          show: true,
        },
        {
          guid: 2,
          className: `${this.customIcon.SaveOutlined} vis-mr--2`,
          action: () => this.$emit("openProjectNamePopup", true),
          show: this.datamodelName && this.isSaveAvailable,
        },
        {
          guid: 3,
          className: `${this.customIcon.Close} vis-mr--1 e-icons e-large`,
          action: () => this.$router.push("/"),
          show: true,
        },
      ];
    },
  },
  methods: {
    addNewDataset() {
      if (
        this.isDataModelLocked &&
        this.currentUserUsername !== this.datamodelLockingInfo?.lockCreatedBy
      ) {
        Notify(
          this.$t("errorCode.dap_ec_144_data_model_is_locked"),
          notificationType.ERROR
        );
      } else {
        this.$emit("addNewDataset");
      }
    },
  },
};
</script>

<style scoped>
.vis-settings-tab-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 5px;
}
.vis-settings-tab-link .link-item {
  display: flex;
  padding: 0 14px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  transition: all 0.3s ease;
}
.vis-settings-tab-link .link-item a {
  display: flex;
  align-items: center;
}

.vis-settings-tab-link .link-item.active {
  background-color: var(--primary-lighteen-1);
  width: 100%;
}
.vis-settings-tab-link .link-item.active span,
.vis-settings-tab-link .link-item.active i {
  display: inline-block;
  color: #333333 !important;
}

.add-new-dataset-plus-icon {
  font-size: 25px;
  margin-right: 10px;
}
.data-model-top-bar-breadcrumb {
  margin-left: 20px;
}
::v-deep .el-breadcrumb__inner {
  font-weight: bold !important;
}
.add-new-dataset-button {
  margin-right: 16px;
}
.vis-icon-position {
  padding-right: 4px !important;
}
</style>
