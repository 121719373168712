<template>
  <div>
    <div v-if="!isShowDataModelProperties">
      <DataModelRightPropertiesPanelCSV v-if="type === importTypes.CSV" />
      <DatasetRightPropertiesPanelDB
        v-else-if="type === importTypes.DB"
        :selectedDataset="selectedDataset"
        :connectionName="connectionName"
        :type="type"
        :sqlViewVisible="sqlViewVisible"
        :connectionLength="connectionLength"
        :selectedDatasetFieldsLength="selectedDatasetFieldsLength"
        @dbGeneralPropertyChanged="
          (value) => $emit('dbGeneralPropertyChanged', value)
        "
      />
      <div v-else></div>
      <!-- when nothing selected -->
    </div>
    <div v-if="isShowDataModelProperties">
      <DataModelRightPropertiesPanelDB
        :selectedDataset="selectedDataset"
        :sqlViewVisible="sqlViewVisible"
        :selectedDataModel="selectedDataModel"
        :isDataModelLocked="isDataModelLocked"
        @setDatamodelName="
          (value) => $emit('setDatamodelName', value)
        "
        @setDatamodelPropertiesValue="(params) => $emit('setDatamodelPropertiesValue', params)"
        @setQueryTimeout="(value) => $emit('setQueryTimeout', value)"
        @setQueryStatus="(value) => $emit('setQueryStatus', value)"
      />
    </div>
  </div>
</template>

<script>
import DataModelRightPropertiesPanelCSV from "./dataModelProperties/DataModelRightPropertiesPanelCSV";
import DatasetRightPropertiesPanelDB from "./dataModelProperties/DatasetRightPropertiesPanelDB";
import DataModelRightPropertiesPanelDB from "./dataModelProperties/DataModelRightPropertiesPanelDB";
import { importTypes } from "./../../commons/dataModelTypes";

export default {
  components: {
    DataModelRightPropertiesPanelCSV,
    DataModelRightPropertiesPanelDB,
    DatasetRightPropertiesPanelDB,
  },
  props: {
    selectedDataset: {
      type: Object,
    },
    type: {
      type: String, //csv, db, null
    },
    connectionName: {
      type: String,
    },
    sqlViewVisible: {
      type: Boolean,
    },
    connectionLength: {
      type: Number,
    },
    selectedDatasetFieldsLength: {
      type: Number,
    },
    isShowDataModelProperties: {
      type: Boolean,
    },
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
    isDataModelLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      importTypes: importTypes,
    };
  },
};
</script>
