/* eslint-disable */
import { STATE, ACTION, MUTATION, GETTER, DASHBOARD_STATE_OBJECT_KEYS } from "./types";
import { MUTATION_GENERAL } from "../General/types";
/* import _ from "lodash"; */
import Vue from "vue";
import { vuexActionHandler } from "../../../../util/storeHelper";
import { serviceMethodParent, serviceMethodSub } from "../../../../api/ApiConstants";
import { dashboardData } from "../../../../commons/dashboardDefaultData"
import cloneDeep from "clone-deep";
import { NOTIFY_TITLE } from "../../../../commons/notifierTypes";
import { allPropertiesKeys } from "../../../../commons/dashboardProperties";
import { SettingsLeftTabLinkEnum } from "../../../../commons/settingsLeftTabLinkEnum";

export const visDashboard = {
  state: {
    dashboards: [],
    dashboard: cloneDeep(dashboardData),
    [STATE.ACTIVE_SETTING_TAB]: SettingsLeftTabLinkEnum.Visualize,
    [STATE.IS_DASHBOARD_PROPERTIES_ACTIVE]: true,
    [STATE.DASHBOARD_LIST_BY_DATAMODEL_ID]: [],
    [STATE.FILTER_GRID_LAYOUT_PROPERTIES]: {
      [DASHBOARD_STATE_OBJECT_KEYS.isFilterGridLayoutActive]: false,
      [DASHBOARD_STATE_OBJECT_KEYS.isFilterGridLayoutPinIconActive]: true,
    },
    [STATE.DASHBOARD_SELECTION_PANE_PROPERTIES]: {
      [DASHBOARD_STATE_OBJECT_KEYS.isDashboardSelectionPaneActive]: false,
      [DASHBOARD_STATE_OBJECT_KEYS.isDashboardSelectionPanePinIconActive]: false,
    },
  },
  getters: {
    [GETTER.GET_DASHBOARDS]: (state) => {
      return state.dashboards;
    },
    [GETTER.GET_DASHBOARD]: (state) => {
      return state.dashboard;
    },
    [GETTER.GET_CUSTOM_COLOR_PALETTES]: (state) => {
      return state.dashboard?.properties?.style?.[allPropertiesKeys.CUSTOM_COLOR_PALETTES];
    },
    [GETTER.GET_ACTIVE_SETTING_TAB]: (state) => {
      return state[STATE.ACTIVE_SETTING_TAB];
    },
    [GETTER.GET_IS_DASHBOARD_PROPERTIES_ACTIVE]: (state) => {
      return state[STATE.IS_DASHBOARD_PROPERTIES_ACTIVE];
    },
    [GETTER.GET_DASHBOARD_LIST_BY_DATAMODEL_ID]: (state) => {
      return state[STATE.DASHBOARD_LIST_BY_DATAMODEL_ID];
    },
    [GETTER.GET_FILTER_GRID_LAYOUT_PROPERTIES]: (state) => {
      return state[STATE.FILTER_GRID_LAYOUT_PROPERTIES];
    },
    [GETTER.GET_DASHBOARD_SELECTION_PANE_PROPERTIES]: (state) => {
      return state[STATE.DASHBOARD_SELECTION_PANE_PROPERTIES];
    },
  },
  actions: {
    [ACTION.UPDATE_DASHBOARD_NAME]: async ({ commit }, { bodyParam }) => {
      await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.updateDashboardName,
          bodyParam,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true
        },
      );
    },
    [ACTION.FETCH_DASHBOARDS]: async ({ commit }, { loadingComponent, folderId }) => {
      await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.readDashboardList,
          successMutation: MUTATION.SET_DASHBOARDS,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          queryParam: folderId,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true,
          withSuccessNotify: false,
          loadingComponent
        },
      );
    },
    [ACTION.FETCH_DASHBOARD_LIST_BY_DATAMODEL_ID]: async ({ commit }, { loadingComponent, dataModelId }) => {
      await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.readDashboardListByDatamodelId,
          successMutation: MUTATION.SET_DASHBOARD_LIST_BY_DATAMODEL_ID,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          queryParam: dataModelId,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true,
          withSuccessNotify: false,
          loadingComponent
        },
      );
    },
    [ACTION.FETCH_DASHBOARDS_BY_ID]: async ({ commit }, { loadingComponent, dashboardId }) => {
      await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.readDashboardById,
          successMutation: MUTATION.SET_DASHBOARD,
          queryParam: dashboardId,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true,
          loadingComponent,
          withSuccessNotify: false,
        },
      );
    },
    [ACTION.CREATE_DASHBOARD]: async ({ commit, dispatch }, { loadingComponent, bodyParam, folderId }) => {
      let result = await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.createDashboard,
          successMutation: MUTATION.SET_DASHBOARD,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          bodyParam,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true,
          loadingComponent,
          notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
        },
      );
      if (result) {
        await dispatch(ACTION.FETCH_DASHBOARDS, { loadingComponent: null, folderId })
        return result;
      }

    },
    [ACTION.UPDATE_DASHBOARD]: async ({ commit }, { loadingComponent, payload }) => {
      return await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.updateDashboard,
          successMutation: MUTATION.SET_DASHBOARD,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          bodyParam: payload.data,
          queryParam: payload.dashboardId,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true,
          loadingComponent,
          notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
        },
      );
    },
    [ACTION.COPY_DASHBOARD]: async ({ commit }, { loadingComponent, payload }) => {
      await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.copyDashboard,
          queryParam: payload,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true,
          loadingComponent,
          notifyTitle: NOTIFY_TITLE.SAVE_SUCCESS,
        },
      );
    },
    [ACTION.DELETE_DASHBOARD]: async ({ commit }, { queryParam }) => {
      await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.deleteDashboard,
          queryParam,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true
        },
      );
    },
    [ACTION.MOVE_DASHBOARD]: async ({ commit }, { itemId, targetFolderId }) => {
      await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.moveDashboard,
          queryParam: { itemId, targetFolderId: targetFolderId ?? "" },
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withLoading: true,
          withNotify: true
        },
      );
    },
    [ACTION.DELETE_DASHBOARD_CALCULATION]: async (
      { commit },
      { loadingComponent, queryParam }
    ) => {
      await vuexActionHandler({
        commit,
        serviceMethodParent: serviceMethodParent.Dashboard,
        serviceMethodSub: serviceMethodSub.deleteDashboardCalculation,
        queryParam,
        errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
        resultSelector: (result) => result.data,
        withLoading: true,
        withNotify: true,
        loadingComponent,
      });
    },
    [ACTION.FETCH_HIGHEST_PRIORITY_BY_DASHBOARD_ID]: async ({ commit }, { dashboardId }) => {
      return await vuexActionHandler(
        {
          commit,
          serviceMethodParent: serviceMethodParent.Dashboard,
          serviceMethodSub: serviceMethodSub.readHighestPriorityByDashboardId,
          queryParam: dashboardId,
          errorMutation: MUTATION_GENERAL.ADD_NOTIFY,
          resultSelector: (result) => result.data,
          withLoading: true,
        },
      );
    },
  },
  mutations: {
    [MUTATION.SET_SELECTED_PANEL](state, payload) {
      const foundPanelIndex = state.dashboard.tabs[payload.tabIndex].charts.findIndex(p => p.i === payload.panelId);
      Vue.set(state.dashboard.tabs[payload.tabIndex].charts, foundPanelIndex, payload.value);
    },
    [MUTATION.SET_PANELS](state, payload) {
      Vue.set(state.dashboard.tabs[payload.tabIndex], "charts", payload.value)
    },
    [MUTATION.SET_DASHBOARDS](state, items) {
      Vue.set(state, "dashboards", items);
    },
    [MUTATION.SET_DASHBOARD_LIST_BY_DATAMODEL_ID](state, items) {
      Vue.set(state, [STATE.DASHBOARD_LIST_BY_DATAMODEL_ID], items);
    },
    [MUTATION.SET_DASHBOARD](state, payload) {
      Vue.set(state, "dashboard", payload);
    },
    [MUTATION.RESET_DASHBOARD](state) {
      const x = cloneDeep(dashboardData)
      Vue.set(state, "dashboard", x);
    },
    [MUTATION.SET_DASHBOARD_PANELS](state, item) {
      let tab = state.dashboard.tabs[item.properties.tabIndex]
      tab.charts.push(item);
    },
    [MUTATION.UPDATE_DASHBOARD_PANEL_LIST](state, item) {
      let selectedTab = state.dashboard.tabs[item.tabIndex];
      selectedTab.charts = item.arr;
    },
    [MUTATION.SET_DASHBOARD_PROPERTY](state, item) {
      if (!state.dashboard?.properties) {
        Vue.set(state.dashboard, 'properties', {});
      }
      Vue.set(state.dashboard?.properties, "style", { ...(state?.dashboard?.properties?.style || {}), [item?.property]: item?.value });
    },
    [MUTATION.SET_ACTIVE_SETTING_TAB] (state, item) {
      Vue.set(state, [STATE.ACTIVE_SETTING_TAB], item);
    },
    [MUTATION.SET_DASHBOARD_PROPERTIES_ACTIVE] (state) {
      state[STATE.IS_DASHBOARD_PROPERTIES_ACTIVE] = !state[STATE.IS_DASHBOARD_PROPERTIES_ACTIVE];
    },
    [MUTATION.SET_FILTER_GRID_LAYOUT_ACTIVE] (state, param) {
      state[STATE.FILTER_GRID_LAYOUT_PROPERTIES][DASHBOARD_STATE_OBJECT_KEYS.isFilterGridLayoutActive] = param;
    },
    [MUTATION.SET_FILTER_GRID_LAYOUT_PIN_ICON_ACTIVE] (state, param) {
      state[STATE.FILTER_GRID_LAYOUT_PROPERTIES][DASHBOARD_STATE_OBJECT_KEYS.isFilterGridLayoutPinIconActive] = param;
    },
    [MUTATION.SET_DASHBOARD_SELECTION_PANE_ACTIVE] (state, param) {
      state[STATE.DASHBOARD_SELECTION_PANE_PROPERTIES][DASHBOARD_STATE_OBJECT_KEYS.isDashboardSelectionPaneActive] = param;
    },
    [MUTATION.SET_DASHBOARD_SELECTION_PANE_PIN_ICON_ACTIVE] (state, param) {
      state[STATE.DASHBOARD_SELECTION_PANE_PROPERTIES][DASHBOARD_STATE_OBJECT_KEYS.isDashboardSelectionPanePinIconActive] = param;
    },
  },

};
