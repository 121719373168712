<template>
  <el-popover
    placement="right"
    trigger="manual"
    popper-class="vis-dropdown-popper vis-custom-popper"
    :value="isAnyPopupVisible"
    class="vis-custom-poper"
  >
    <CalculatePopup
      v-if="isCalculatePopupVisible"
      @closeCalculate="closeCalculatePopup"
      @deleteCalculateItem="deleteCalculateItem"
      :datasetFields="datasetFieldsByDatasetId"
    />
    <TopBottomPopup
      v-if="isOrderByPopupVisible"
      @closeOrderBy="closeOrderByPopup"
      :datasetFields="datasetFieldsByDatasetId"
      :measuresValue="measuresfirstValue"
    />
    <SectionBackgroundPopup
      v-if="isSectionBackgroundPopupVisible"
      :selectedField="selectedPanelSectionsWithBackground"
      @closeBackgroundPopup="closeSectionBackgroundPopup"
    />
    <div slot="reference">
      <PanelCommonsBox
        popups="popups"
        :boxList="selectedPanelSections"
        :boxName="$t(boxName)"
        :contextMenuId="contextMenuId"
        :draggableList="sectionList"
        :panelDataBoxEmptyClass="panelDataBoxEmptyClass"
        :activeFieldBoxClass="activeFieldBoxClass"
        :contextMenuItems="contextMenuItems"
        :selectedDataModel="selectedDataModel"
        @changeDraggableList="onDraggableChanged($event)"
        @openContextMenu="sectionContext"
        @setSelectType="setSection"
        @aggregrationHighlight="aggregrationHighlight"
      >
      </PanelCommonsBox>
    </div>
  </el-popover>
</template>
<script>
import cloneDeep from "clone-deep";
import CalculatePopup from "../../components/panel/bars/CalculatePopup.vue";
import TopBottomPopup from "../../components/panel/bars/TopBottomPopup.vue";
import SectionBackgroundPopup from "../../components/panel/bars/SectionBackgroundPopup.vue";
import PanelCommonsBox from "./PanelCommonsBox.vue";
import {
  getSliceItem,
  DatamodelContextDefaults,
} from "../../commons/dataModelTypes";
import {
  getCommonsFieldColumnsAndSections,
  setSelectedPanelDetailAggregationAndMetrics,
} from "../../util/data-table/tableCommonsHelper";
import { BOX_KEY_ENUM, properties } from "../../commons/dashboardAndPanel";

export default {
  components: {
    CalculatePopup,
    TopBottomPopup,
    PanelCommonsBox,
    SectionBackgroundPopup,
  },
  props: {
    selectedPanel: {
      type: Object,
    },
    isActiveFieldBox: {
      type: Boolean,
    },
    datasetFields: {
      type: Object,
    },
    designMode: {
      type: Boolean,
    },
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedSection: {},
      selectedSectionIndex: null,
      calculatePopup: "",
      orderByPopup: "",
      sectionBackgroundPopupVisible: "",
      measuresfirstValue: "",
      contextMenuId: "sectionMenu",
      boxName: "generalPages.sections",
    };
  },
  computed: {
    datasetFieldsByDatasetId() {
      return this.datasetFields?.[this.selectedSection.datasetId];
    },
    selectedPanelSections() {
      return this.selectedPanel?.details?.sections ?? [];
    },
    selectedPanelSectionsWithBackground() {
      return this.selectedSection ?? [];
    },
    activeFieldBoxClass() {
      return this.isActiveFieldBox && !this.selectedPanelSections.length
        ? "vis-activeFieldBox"
        : "";
    },
    panelDataBoxEmptyClass() {
      return !this.selectedPanelSections?.length
        ? "vis-panel-data-card-empty"
        : null;
    },
    isAnyPopupVisible() {
      return (
        this.isCalculatePopupVisible ||
        this.isOrderByPopupVisible ||
        this.isSectionBackgroundPopupVisible
      );
    },
    isCalculatePopupVisible() {
      return this.calculatePopup == this.selectedSection?.field;
    },
    isOrderByPopupVisible() {
      return this.orderByPopup == this.selectedSection?.field;
    },
    isSectionBackgroundPopupVisible() {
      return this.sectionBackgroundPopupVisible == this.selectedSection?.field;
    },
    contextMenuItems() {
      return getSliceItem({
        fieldUsageType: this.selectedSection?.fieldUsageType,
        slotType: properties.SECTIONS,
      });
    },
    sectionList() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      return selectedPanelCloned.details?.sections;
    },
  },
  methods: {
    deleteSection() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      selectedPanelCloned.details?.sections?.splice(
        this.selectedSectionIndex,
        1
      );
      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },
    resetSectionList(value) {
      let field = {};
      let newField = {};
      field = value?.added?.element;
      let params = {
        newField,
        field,
        newIndex: value?.added?.newIndex,
      };
      return params;
    },
    onDraggableChanged(event) {
      const isElementMoved = this.handleElementMoved(event);
      if (!isElementMoved) this.setSectionList(event);
    },
    handleElementMoved(event) {
      if (event?.moved) {
        const { newIndex, oldIndex } = event.moved;

        const selectedPanelCloned = cloneDeep(this.selectedPanel);
        const temp1 = cloneDeep(selectedPanelCloned.details.sections[newIndex]);
        const temp2 = cloneDeep(selectedPanelCloned.details.sections[oldIndex]);

        selectedPanelCloned.details.sections[newIndex] = temp2;
        selectedPanelCloned.details.sections[oldIndex] = temp1;

        this.updateSelectedPanelByDesignMode(selectedPanelCloned);

        return true;
      }
      if (event?.removed) {
        this.selectedSectionIndex = event.removed.oldIndex;
        this.deleteSection();
        return true;
      }
      return false;
    },
    setSectionList(value) {
      let selectedPanelCloned = cloneDeep(this.selectedPanel);
      let params = this.resetSectionList(value);
      params.newField = getCommonsFieldColumnsAndSections({
        fieldData: params.field,
        boxKey: BOX_KEY_ENUM.SECTION.KEY,
        draggedAndDroppedFieldIndex: params.newIndex,
      });

      const hasColumnField = selectedPanelCloned?.details?.columns.find(
        (x) => x.fieldId === params.newField.fieldId
      );

      if (hasColumnField) {
        //section'a eklenen field'ın slotFieldId'si, columns içindeki field'ın slotFieldId'si ile aynı olması gerekiyor.
        params.newField.slotFieldId = hasColumnField.slotFieldId;
      } else {
        params.newField.slotFieldId = `_AGG${selectedPanelCloned.details.columns.length}`;
        selectedPanelCloned.details.columns.push(params.newField);
        selectedPanelCloned =
          setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);
      }

      setTimeout(
        () => {
          selectedPanelCloned?.details?.sections?.push(params.newField);
        },
        !hasColumnField ? 300 : 0
      );

      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },
    sectionContext(event, params) {
      this.selectedSection = params?.item;
      this.selectedSectionIndex = params?.index;
      document
        .getElementById(this.contextMenuId)
        .ej2_instances[0].open(event?.y, event?.x);
    },
    setSection(arg) {
      if (!arg?.item?.value) return;
      const value = arg?.item?.value;

      if (value == DatamodelContextDefaults?.BARS?.ORDER_BY_POPUP) {
        this.orderByPopup = this.selectedSection?.field;
      } else if (value === DatamodelContextDefaults?.BARS?.SUM_ASC_DESC) {
        this.calculatePopup = this.selectedSection?.field;
      } else if (value === DatamodelContextDefaults?.DELETE) {
        this.deleteSection();
      } else if (value === DatamodelContextDefaults.SECTION_BACKGROUND) {
        this.sectionBackgroundPopupVisible = this.selectedSection?.field;
      } else {
        const selectedPanelCloned = cloneDeep(this.selectedPanel);
        selectedPanelCloned.details.sections.find(
          (i) => i?.field == this.selectedSection?.field
        ).order = value;

        this.updateSelectedPanelByDesignMode(selectedPanelCloned);
      }
    },
    aggregrationHighlight(args) {
      if (args?.item?.value) {
        if (args.item.value === this.selectedSection.order) {
          args.element.innerHTML = `<strong>${args.item.text}</strong> <i class="mdi mdi-check"></i>`;
        }
      }
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    updateSelectedPanelAndViewChart(value) {
      this.$emit("updateSelectedPanelAndViewChart", {
        value,
      });
    },
    updateSelectedPanelByDesignMode(selectedPanelCloned) {
      if (this.designMode) this.updateSelectedPanel(selectedPanelCloned);
      else this.updateSelectedPanelAndViewChart(selectedPanelCloned);
    },
    closeOrderByPopup(event) {
      this.orderByPopup = event;
    },
    closeCalculatePopup(event) {
      this.calculatePopup = event;
    },
    closeSectionBackgroundPopup(event) {
      this.sectionBackgroundPopupVisible = event;
    },
  },
};
</script>
<style scoped></style>
