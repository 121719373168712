<template>
  <div class="vis-chart-card-title" :class="[chartCardTitleAlignCenter]">
    <div class="vis-chart-card-input-box">
      <input
        v-show="hasTitle && chartName"
        type="text"
        :value="chartName"
        :placeholder="$t('dashboard.enterTitle')"
        disabled
      />
      <br />
      <input
        v-show="hasSubTitle && chartDescription"
        type="text"
        :value="chartDescription"
        :placeholder="$t('dashboard.enterDesc')"
        disabled
      />
    </div>
    <div>
      <el-popover
        v-if="hasActiveFilterIcon"
        placement="top-start"
        trigger="hover"
      >
        <div
          class="vis-card-filter-info-item"
          v-html="panelDataFilterInfo"
        ></div>
        <i
          slot="reference"
          :class="CustomIcon.FilterAlt"
          class="vis-cursor-pointer vis-mr--1 vis-icon"
          aria-hidden="true"
        ></i>
      </el-popover>
      <i
        v-if="designMode && isChartAreaShow"
        @click="$emit('getViewChart')"
        :class="CustomIcon.Play"
        class="vis-cursor-pointer vis-mr--1"
        aria-hidden="true"
      ></i>
      <i
        v-if="isChartAreaShow"
        @click="saveAndPreviousDashboard()"
        :class="[disableSaveIcon, CustomIcon.SaveOutlined]"
        class="vis-cursor-pointer vis-mr--1"
        aria-hidden="true"
      ></i>
      <i
        @click="$emit('previousDashboard')"
        :class="CustomIcon.Close"
        class="vis-cursor-pointer vis-font-bold"
        aria-hidden="true"
      ></i>
    </div>
  </div>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { chartWithNotSeeFilterIcon } from "../../commons/dashboardAndPanel";
import { DefaultValue } from "../../commons/dashboardProperties";
import panelDataPropertiesStyle from "../../mixins/panelDataPropertiesStyle";
import {
  getActiveFilterList,
  getFilterItemTextValue,
} from "../../util/filter/cardTitleHelper";

export default {
  mixins: [panelDataPropertiesStyle],
  props: {
    panelData: {
      type: Object,
    },
    hasTitle: {
      type: Boolean,
    },
    hasSubTitle: {
      type: Boolean,
    },
    designMode: {
      type: Boolean,
      default: false,
    },
    isPanelPage: {
      type: Boolean,
    },
    isChartAreaShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      CustomIcon: CustomIcon,
    };
  },
  computed: {
    chartName() {
      return this.panelData?.name;
    },
    chartDescription() {
      return this.panelData?.description;
    },
    panelDataAllFilters() {
      return [
        ...(this.panelDataDetailsFilters?.length
          ? this.panelDataDetailsFilters
          : []),
        ...(this.panelDataDetailsParams?.length
          ? this.panelDataDetailsParams
          : []),
      ];
    },
    hasActiveFilterIcon() {
      return (
        getActiveFilterList(this.panelDataAllFilters).length &&
        !chartWithNotSeeFilterIcon.includes(this.panelData.type)
      );
    },
    panelDataFilterInfo() {
      return getFilterItemTextValue(this.panelDataAllFilters, this.isPanelPage);
    },
    chartCardTitleAlignCenter() {
      return !this.panelData?.properties?.isLabel
        ? "vis-text--right"
        : "vis-flex--spacebeetwen";
    },
    hasDefaultValue() {
      let panelDataPropertyDefaultValue = Object.values(
        this.panelData.properties.style
      ).includes(DefaultValue.SELECTION);

      if (panelDataPropertyDefaultValue) {
        return this.filterValue ? true : false;
      } else return true;
    },

    isActiveDropdownFilterDefaultValue() {
      return this.dropdownFilterDefaultValue == DefaultValue.NONE;
    },

    disableSaveIcon() {
      return !this.hasDefaultValue ? "vis-cursor-notAllowed" : "";
    },
  },
  methods: {
    saveAndPreviousDashboard() {
      if (this.hasDefaultValue) {
        this.$emit("saveAndPreviousDashboard");
      }
    },
  },
};
</script>
