import { Service } from "./BaseService";
import { callPost, callGet, callDelete, callDeleteFormData, callPut, callPostFormData } from "./ServiceMethodWrapper"
import { serviceMethodParent, serviceMethodSub } from "./ApiConstants"
import { Modules } from "../commons/modules";

export const ServiceContainer = (() => {
    let instance;

    const init = () => {
        const visualizeService = new Service(process.env.VUE_APP_API_ENDPOINT + 'visualize/v1/')
        const generalService = new Service(process.env.VUE_APP_API_ENDPOINT + 'general/v1/')
        const ingestService = new Service(process.env.VUE_APP_API_ENDPOINT + 'ingest/v1/')
        const localService = new Service('/');
        const chatGptService = new Service(process.env.VUE_APP_API_ENDPOINT_CHAT_GPT + 'openai/deployments/', process.env.VUE_APP_CHAT_GPT_API_KEY)
        const chatGptServiceVisualize = new Service(process.env.VUE_APP_API_ENDPOINT_CHAT_GPT_VISUALIZE)
        const transferService = new Service(process.env.VUE_APP_API_ENDPOINT + 'transfer/v1/')
        const cepApiService = new Service(process.env.VUE_APP_CEP_API_ENDPOINT)
        const selectedModule = localStorage.getItem("selectedModule");
        const connectionService = selectedModule != Modules.SCENARIO ? generalService : cepApiService;
        const visualizeAIService = new Service(process.env.VUE_APP_API_ENDPOINT.replace('api/', 'chat/'))

        return {
            [serviceMethodParent.Dashboard]: {
                [serviceMethodSub.readDashboardList]: (folderId) => callGet(visualizeService)("dashboards?folderId=", folderId),
                [serviceMethodSub.readDashboardListByDatamodelId]: (datamodelId) => callGet(visualizeService)(`dashboards/datamodel/${datamodelId}`),
                [serviceMethodSub.readDashboardById]: (dashboardId) => callGet(visualizeService)("dashboards/", dashboardId),
                [serviceMethodSub.createDashboard]: (data) => callPost(visualizeService)("dashboards", data),
                [serviceMethodSub.deleteDashboard]: (dashboardId) => callDelete(visualizeService)("dashboards/", dashboardId),
                [serviceMethodSub.updateDashboard]: (dashboardId, data) => callPut(visualizeService)("dashboards/", dashboardId, data),
                [serviceMethodSub.publishDashboard]: (dashboardId, data) => callPut(visualizeService)("metadata/dashboards/publish/", dashboardId, data),
                [serviceMethodSub.readPublishedDashboardById]: (dashboardId) => callGet(visualizeService)("metadata/dashboards/publish/", dashboardId),
                [serviceMethodSub.updateDashboardName]: (data) => callPost(visualizeService)("dashboards/name", data),
                [serviceMethodSub.copyDashboard]: (data) => callPost(visualizeService)("dashboards/copy", data),
                [serviceMethodSub.moveDashboard]: ({ itemId, targetFolderId, folderId }) => callPut(visualizeService)(`dashboards/${itemId}/move${targetFolderId.length ? '?folderId=' : ""}`, targetFolderId, folderId),
                [serviceMethodSub.deleteDashboardCalculation]: (fieldId) => callDelete(visualizeService)("dashboard/fields/", fieldId),
                [serviceMethodSub.readHighestPriorityByDashboardId]: (dashboardId) => callGet(visualizeService)(`dashboard/${dashboardId}/highest-priority`),
            },
            [serviceMethodParent.Search]: {
                [serviceMethodSub.readSearch]: (data) => callPost(visualizeService)("search", data),
                [serviceMethodSub.readMainPageSearch]: (item) => callGet(generalService)(`visualize-items?${item}`),
                [serviceMethodSub.readDashboardSearch]: (item) => callGet(visualizeService)(`dashboards/filter?${item}`),
            },
            [serviceMethodParent.DataModel]: {
                [serviceMethodSub.createByDatamodelWizard]: (data) => callPost(visualizeService)("datamodels/wizard", data), // adding new datasets to non-exist datamodel
                [serviceMethodSub.readDataModelList]: (folderId) => callGet(visualizeService)("datamodels?folderId=", folderId),
                [serviceMethodSub.createDatamodel]: (data) => callPost(visualizeService)("datamodels", data),
                [serviceMethodSub.readDatamodelById]: (datamodelId) => callGet(visualizeService)("datamodels/", datamodelId),
                [serviceMethodSub.deleteDatamodel]: (datamodelId) => callDelete(visualizeService)("datamodels/", datamodelId),
                [serviceMethodSub.updateDatamodel]: (datamodelId, data) => callPut(visualizeService)("datamodels/", datamodelId, data),
                [serviceMethodSub.updateDatamodelName]: (data) => callPost(visualizeService)("datamodels/name", data),
                [serviceMethodSub.moveDatamodel]: ({ itemId, targetFolderId, folderId }) => callPut(visualizeService)(`datamodels/${itemId}/move${targetFolderId.length ? '?folderId=' : ""}`, targetFolderId, folderId),
                [serviceMethodSub.updateDatamodelProperties]: (id, data) => callPut(visualizeService)(`datamodels/${id}/properties`, "", data),
                [serviceMethodSub.copyDatamodel]: (data) => callPost(visualizeService)("datamodels/copy", data),
                [serviceMethodSub.readDatamodelFieldsById]: (datamodelId) => callGet(visualizeService)(`datamodels/${datamodelId}/fields`),
                [serviceMethodSub.readDatamodelFilterParamsById]: (datamodelId) => callGet(visualizeService)(`datamodels/${datamodelId}/filterParams`),
                [serviceMethodSub.duplicateDatamodelField]: (data) => callPost(visualizeService)("fields/copy", data),
                [serviceMethodSub.readHighestPriorityByDatamodelId]: (datamodelId) => callGet(visualizeService)(`datamodel/${datamodelId}/highest-priority`),
                [serviceMethodSub.updateQueryTimeout]: (datamodelId, data) => callPut(visualizeService)(`datamodels/${datamodelId}/query-timeout`, "", data),
                [serviceMethodSub.updateQueryStatus]: (datamodelId, data) => callPut(visualizeService)(`datamodels/${datamodelId}/isQueryTimeoutActive?isActive=${data.isActive}`, "", ""),
            },
            [serviceMethodParent.Folder]: {
                [serviceMethodSub.createFolder]: (parentFolderId, data) => callPost(visualizeService)(`folders${parentFolderId.length ? '?parentFolderId=' + parentFolderId : ""}`, data),
                [serviceMethodSub.readFolderById]: (folderId) => callGet(visualizeService)("folders/", folderId),
                [serviceMethodSub.readFolderList]: (folderId) => callGet(visualizeService)("folders/all?parentFolderId=", folderId),
                [serviceMethodSub.moveFolder]: ({ itemId, targetFolderId, folderId }) => callPut(visualizeService)(`folders/${itemId}/move${targetFolderId.length ? '?targetFolderId=' : ""}`, targetFolderId, folderId),
                [serviceMethodSub.deleteFolder]: (folderId) => callDelete(visualizeService)("folders/", folderId),
                [serviceMethodSub.updateFolderName]: (folderId, data) => callPut(visualizeService)("folders/", folderId, data),
            },
            [serviceMethodParent.Dataset]: {
                [serviceMethodSub.addDatasetsByDatamodelWizard]: (data) => callPost(visualizeService)("datasets/wizard", data), // adding new datasets to existing datamodel
                [serviceMethodSub.generateDatasetFieldsById]: (datasetId) => callGet(visualizeService)(`datasets/${datasetId}/generate/fields`),
                [serviceMethodSub.readDatasetFieldsById]: (datasetId) => callGet(visualizeService)(`datasets/${datasetId}/fields`),
                [serviceMethodSub.readDatasetFilterParamsById]: (datasetId) => callGet(visualizeService)(`datasets/${datasetId}/filterParams`),
                [serviceMethodSub.updateField]: (fieldId, data) => callPut(visualizeService)("fields/", fieldId, data),
                [serviceMethodSub.updateDataset]: (datasetId, data) => callPut(visualizeService)("datasets/", datasetId, data),
                [serviceMethodSub.createDataset]: (data) => callPost(visualizeService)("datasets", data),
                [serviceMethodSub.copyDataset]: (data) => callPost(visualizeService)("datasets/copy", data),
                [serviceMethodSub.deleteDataset]: (datasetId) => callDelete(visualizeService)("datasets/", datasetId),
                [serviceMethodSub.updateFieldsOrder]: (data) => callPut(visualizeService)(`fields`, "", data),
                [serviceMethodSub.deleteCalculation]: (datasetId) => callDelete(visualizeService)("fields/", datasetId),

                [serviceMethodSub.saveDatasetJoin]: (data) => callPost(visualizeService)("dataset/join", data),
                [serviceMethodSub.deleteDatasetJoin]: (data) => callDeleteFormData(visualizeService)("dataset/join", data),
                [serviceMethodSub.readDatasetJoin]: (datamodelId) => callGet(visualizeService)("dataset/join/", datamodelId),

                [serviceMethodSub.readCheckDatasetUseById]: (datasetId) => callGet(visualizeService)("datasets/check/", datasetId),

                [serviceMethodSub.readDatasetIds]:(datamodelId) => callGet(visualizeService)(`datamodels/${datamodelId}/related-graph`),
                [serviceMethodSub.updateDatasetFieldsAlias]: (datasetId, data) => callPut(visualizeService)(`datasets/${datasetId}/fields/beautify`, '', data),
                [serviceMethodSub.sortAndRenameSettings]:(datasetId, data) => callPut(visualizeService)(`datasets/${datasetId}/sort-and-rename-settings`, '', data),
            },
            [serviceMethodParent.FilterParams]: {
                [serviceMethodSub.createFilterParams]: (data) => callPost(visualizeService)("filterParams", data),
                [serviceMethodSub.updateFilterParams]: (id, bodyParam) => callPut(visualizeService)("filterParams/", id, bodyParam),
                [serviceMethodSub.deleteFilterParams]: (id) => callDelete(visualizeService)("filterParams/", id),
            },
            [serviceMethodParent.TestQuery]: {
                [serviceMethodSub.readTestQuery]: (data) => callPost(visualizeService)("query", data),
                [serviceMethodSub.readTestPreview]: (data) => callPost(visualizeService)("preview", data),
            },
            [serviceMethodParent.Connection]: {
                [serviceMethodSub.readConnections]: () => callGet(connectionService)("connections"),
                [serviceMethodSub.updateConnection]: (data) => callPut(connectionService)("connections", "", data),
                [serviceMethodSub.createConnection]: (data) => callPost(connectionService)("connections", data),
                [serviceMethodSub.testConnection]: (data) => callPost(connectionService)("connections/test", data),
                [serviceMethodSub.deleteConnection]: (connectionId) => callDelete(connectionService)("connections/", connectionId),
                [serviceMethodSub.readConnectionsByDashboardId]: (dashboardId) => callGet(visualizeService)(`dashboards/${dashboardId}/connections`),

                [serviceMethodSub.findByConnectionId]: (connectionId) => callGet(visualizeService)(`connection/${connectionId}/authorization`),
                [serviceMethodSub.readPropertyQuery]: (connectionId) => callGet(visualizeService)("propertyQuery/", connectionId),
                [serviceMethodSub.findAuthoritiesByUsername]: (username) => callGet(visualizeService)(`connection/authorization/authority/`, username),
                [serviceMethodSub.saveConnectionAuthorization]: (body) => callPost(visualizeService)(`connection/authorization`, body),
                [serviceMethodSub.updateConnectionAuthorization]: (connectionId, body) => callPut(visualizeService)(`connection/authorization/${connectionId}`, "", body),
                [serviceMethodSub.deleteConnectionAuthorization]: (connectionId) => callDelete(visualizeService)(`connection/authorization/${connectionId}`, ""),
                [serviceMethodSub.readConnectionTypes]: ()  => callGet(generalService)("connections/types"),
                [serviceMethodSub.testSSE]: () => callGet(generalService)("events"),

                [serviceMethodSub.readSchemaByConnectionId]: (connectionId) => callGet(connectionService)(`connections/${connectionId}/schemas`),
                [serviceMethodSub.readFieldsBySchemaAndTable]: (queryParam) => callGet(connectionService)(`connections/`, queryParam),
            },
            [serviceMethodParent.Authorization]: {
                [serviceMethodSub.readAuthorization]: () => callGet(generalService)("authorization"),
                [serviceMethodSub.readUsers]: (filter) => callGet(generalService)("users?filter=", filter),
                [serviceMethodSub.readGroups]: () => callGet(generalService)("groups"),

                [serviceMethodSub.readDashboardAuthorizationById]: (id) => callGet(visualizeService)(`dashboard/${id}/authorization`),
                [serviceMethodSub.createDashboardAuthorization]: (body) => callPost(visualizeService)("dashboard/authorization", body),
                [serviceMethodSub.deleteDashboardAuthorizationById]: (id) => callDelete(visualizeService)("dashboard/authorization/", id),
                [serviceMethodSub.updateDashboardAuthorizationById]: (id, body) => callPut(visualizeService)("dashboard/authorization/", id, body),
                [serviceMethodSub.updateDashboardParentAuthorizationById]: ({dashboardId, useParentAuthorization}) => callPut(visualizeService)(`dashboards/${dashboardId}/use-parent-authorization?useParentAuthorization=`, useParentAuthorization),
                [serviceMethodSub.readDatamodelAuthorizationById]: (id) => callGet(visualizeService)(`datamodel/${id}/authorization`),
                [serviceMethodSub.createDatamodelAuthorization]: (body) => callPost(visualizeService)("datamodel/authorization", body),
                [serviceMethodSub.deleteDatamodelAuthorizationById]: (id) => callDelete(visualizeService)("datamodel/authorization/", id),
                [serviceMethodSub.updateDatamodelAuthorizationById]: (id, body) => callPut(visualizeService)("datamodel/authorization/", id, body),
                [serviceMethodSub.updateDatamodelParentAuthorizationById]: ({datamodelId, useParentAuthorization}) => callPut(visualizeService)(`datamodels/${datamodelId}/use-parent-authorization?useParentAuthorization=`, useParentAuthorization),
                [serviceMethodSub.readFolderAuthorizationById]: (id) => callGet(visualizeService)("folders/authorizations/all?folderId=", id),
                [serviceMethodSub.readFolderAuthorizationTypes]: () => callGet(visualizeService)("folders/authorizations/types"),
                [serviceMethodSub.createFolderAuthorization]: (body) => callPost(visualizeService)("folders/authorizations", body),
                [serviceMethodSub.updateFolderAuthorizationById]: (folderAuthId, body) => callPut(visualizeService)("folders/authorizations/",folderAuthId, body),
                [serviceMethodSub.deleteFolderAuthorizationById]: (id) => callDelete(visualizeService)("folders/authorizations/", id),
                [serviceMethodSub.createFolderOwnerAuthorizationById]: (body) => callPost(visualizeService)("folders/authorizations/owner", body),
                [serviceMethodSub.updateFolderOwnerAuthorizationById]: (folderAuthId) => callPut(visualizeService)(`folders/authorizations/${folderAuthId}/owner`, ""),
                [serviceMethodSub.updateFolderParentAuthorizationById]: ({folderId, useParentAuthorization}) => callPut(visualizeService)(`folders/${folderId}/use-parent-authorization?useParentAuthorization=`, useParentAuthorization),
                [serviceMethodSub.readDataFlowAuthorizationById]: (id) => callGet(ingestService)(`rdb/dataflows/${id}/authorizations`),
                [serviceMethodSub.createDataFlowAuthorization]: (body) => callPost(ingestService)("rdb/dataflows/authorizations", body),
                [serviceMethodSub.deleteDataFlowAuthorizationById]: (id) => callDelete(ingestService)("rdb/dataflows/authorizations/", id),
                [serviceMethodSub.updateDataFlowAuthorizationById]: (id, body) => callPut(ingestService)("rdb/dataflows/authorizations/", id, body),
                [serviceMethodSub.updateDataFlowParentAuthorizationById]: ({dataFlowId, useParentAuthorization}) => callPut(ingestService)(`rdb/dataflows/${dataFlowId}/use-parent-authorization?useParentAuthorization=`, useParentAuthorization),

            },
            [serviceMethodParent.DashboardFields]: {
                [serviceMethodSub.readDashboardFieldsById]: (dashboardId) => callGet(visualizeService)(`dashboard/${dashboardId}/fields`),
                [serviceMethodSub.createDashboardFields]: (dashboardId, data) => callPost(visualizeService)(`dashboard/${dashboardId}/fields`, data),
                [serviceMethodSub.updateDashboardFields]: (dashboardId, data) => callPut(visualizeService)(`dashboard/${dashboardId}/fields/`, data.id, data),
                [serviceMethodSub.duplicateDashboardDataModelField]: (dashboardId, data) => callPost(visualizeService)(`dashboard/${dashboardId}/fields/copy`, data),
            },
            [serviceMethodParent.ChartFields]: {
                [serviceMethodSub.readChartFieldsById]: (chartId) => callGet(visualizeService)(`charts/${chartId}/fields`)
            },
            [serviceMethodParent.ExportMetaData]: {
                [serviceMethodSub.readExportMetaDataRecords]: () => callGet(visualizeService)(`export`),
                [serviceMethodSub.createExportMetaData]: (data) => callPost(visualizeService)(`export`, data),
                [serviceMethodSub.readExportSharedUrl]: (id) => callGet(visualizeService)(`export/${id}/shared-url`),
                [serviceMethodSub.deleteExportMetaData]: (id) => callPost(visualizeService)(`export/disable/${id}`),
                [serviceMethodSub.createExportNotification]: (data) => callPost(visualizeService)(`export/notification`, data),
            },
            [serviceMethodParent.Favorites]: {
                [serviceMethodSub.updateFavorite]: (data) => callPost(visualizeService)("favorites", data),
                [serviceMethodSub.deleteFavoriteById]: (id) => callDelete(visualizeService)("favorites/objects/", id),
                [serviceMethodSub.readFavorites]: () => callGet(visualizeService)(`favorites`),
            },
            [serviceMethodParent.MapData]: {
                [serviceMethodSub.readMapData]: (countryCode) => callGet(localService)(`mapData/${countryCode}-map.json`)
            },
            [serviceMethodParent.Hashing]: {
                [serviceMethodSub.readEncode]: (data) => callPost(visualizeService)(`encode`, data),
                [serviceMethodSub.readDecode]: (data) => callPost(visualizeService)(`decode`, data),
            },
            [serviceMethodParent.ColorPalettes]: {
                [serviceMethodSub.readColorPalettes]: ()  => callGet(generalService)("configurations/color-palettes"),
                [serviceMethodSub.createColorPalettes]: (data) => callPost(generalService)("configurations/color-palettes", data),
                [serviceMethodSub.updateColorPalettes]: (data) => callPut(generalService)("configurations/color-palettes","", data),
                [serviceMethodSub.deleteColorPalettes]: (id) => callDelete(generalService)("configurations/color-palettes/", id),
            },
            [serviceMethodParent.Templates]:{
                [serviceMethodSub.readTemplates]: () => callGet(generalService)("configurations/templates"),
                [serviceMethodSub.createTemplate]: (data) => callPost(generalService)("configurations/templates", data),
                [serviceMethodSub.updateTemplate]: (data) => callPut(generalService)("configurations/templates", "", data),
                [serviceMethodSub.deleteTemplate]: (templateId) => callDelete(generalService)(`configurations/templates/${templateId}`, ""),
            },
            [serviceMethodParent.CalculatedColumn]: {
                [serviceMethodSub.createCalculatedColumn]: (data) => callPost(visualizeService)(`calculatedColumn/saveExpression`, data),
                [serviceMethodSub.validateCalculatedColumn]: (data) => callPost(visualizeService)(`calculatedColumn/validation`, data),
                [serviceMethodSub.updateCalculatedColumn]: (calculatedColumnId, data) => callPut(visualizeService)(`calculatedColumn/${calculatedColumnId}`, '', data),
            },
            [serviceMethodParent.ApiCall]: {
                [serviceMethodSub.createApiCall]: (data) => callPost(visualizeService)(`apicall`, data),
            },
            [serviceMethodParent.File]: {
                [serviceMethodSub.postFilePreview]: (data) => callPost(ingestService)(`file/preview`, data),
                [serviceMethodSub.postFileUpload]: (data) => callPostFormData(ingestService)(`file/upload`, data),
            },
            [serviceMethodParent.User]: {
                [serviceMethodSub.readUserPreferences]: () => callGet(generalService)(`user/preferences`),
                [serviceMethodSub.updateUserPreferences]: (key, body) => callPost(generalService)(`user/preferences/${key}`, body),
            },
            [serviceMethodParent.BulkDelete]: {
                [serviceMethodSub.bulkDeleteProjects]: (data) => callPost(visualizeService)(`bulk-delete/projects`, data)
            },
            [serviceMethodParent.ChatGpt]: {
                [serviceMethodSub.readChatGptInsights]: (data) => callPost(chatGptService)(`Davinci-003/completions?api-version=2022-12-01`, data),
                [serviceMethodSub.readChatGptSummary]: (queryParam, data) => callPost(chatGptServiceVisualize)(`query/${queryParam}`, data),
                [serviceMethodSub.readChatGptChatBot]: (queryParam, data) => callPost(chatGptServiceVisualize)(`chat/${queryParam}`, data),
                [serviceMethodSub.generateAI]: (data) => callPost(visualizeAIService)(`ai/generate`, data),
            },
            [serviceMethodParent.FieldGroup]: {
                [serviceMethodSub.createFieldGroup]: (data) => callPost(visualizeService)(`group`, data),
                [serviceMethodSub.updateFieldGroup]: (id, data) => callPut(visualizeService)(`group/`, id, data)
            },
            [serviceMethodParent.Environments]: {
                [serviceMethodSub.readEnvironments]: () => callGet(transferService)(`environment`),
                [serviceMethodSub.createEnvironment]: (data) => callPost(transferService)(`environment`, data),
                [serviceMethodSub.updateEnvironmentById]: (id, data) => callPut(transferService)(`environment/`, id, data),
                [serviceMethodSub.deleteEnvironmentById]: (id) => callDelete(transferService)(`environment/`, id),
            },
            [serviceMethodParent.Configuration]: {
                [serviceMethodSub.readConfigurations]: () => callGet(generalService)("configurations"),
                [serviceMethodSub.createConfigurations]: (data) => callPost(generalService)("configurations", data),
                [serviceMethodSub.updateConfigurations]: (key, data) => callPut(generalService)(`configurations/`, key, data),
            },
            [serviceMethodParent.MigrateContent]: {
                [serviceMethodSub.readDashboardWithFolders]: () => callGet(transferService)(`dashboards-with-folders`),
                [serviceMethodSub.readConnectionsByEnvironment]: (environmentId) => callGet(transferService)(`connections?environmentId=${environmentId}`),
                [serviceMethodSub.moveObject]: (data) => callPost(transferService)(`object/move`, data),
            },

            //INGEST
            [serviceMethodParent.DataFlow]: {
                [serviceMethodSub.readDataFlows]: (folderId) => callGet(ingestService)(`rdb/dataflows?folderId=`, folderId),
                [serviceMethodSub.updateDataFlowNameAndDescription]: (dataFlowId, data) => callPut(ingestService)(`rdb/dataflows/${dataFlowId}`, "" , data),
                [serviceMethodSub.readDataFlowDetailsById]: (id) => callGet(ingestService)(`rdb/dataflows/${id}/details`),
                [serviceMethodSub.deleteDataFlowById]: (dataFlowId) => callDelete(ingestService)(`rdb/dataflows/${dataFlowId}`, ""),
                [serviceMethodSub.moveDataFlow]: ({ itemId, targetFolderId }) => callPut(ingestService)(`rdb/dataflows/${itemId}/move${targetFolderId.length ? '?folderId=' : ""}`, targetFolderId, null),
                [serviceMethodSub.readJobs]: () => callGet(ingestService)(`jobs`),
                [serviceMethodSub.postRdbPreview]: (data) => callPost(ingestService)(`rdb/preview`, data),
                [serviceMethodSub.postRdbSave]: (data) => callPost(ingestService)(`rdb/save`, data),
                [serviceMethodSub.putRdbSave]: (id, data) => callPut(ingestService)(`rdb/dataflows/${id}/jobs`, '', data),
                [serviceMethodSub.postRdbRun]: (data) => callPost(ingestService)(`rdb/jobs/${data.ingestJobId}/run`),
                [serviceMethodSub.readRdbStatus]: (id) => callGet(ingestService)(`rdb/jobs/${id}/status`),
                [serviceMethodSub.readHighestPriorityByDataFlowId]: (dataFlowId) => callGet(ingestService)(`rdb/dataflows/${dataFlowId}/highest-priority`),
                [serviceMethodSub.postRdbDataPreview]: (data) => callPost(ingestService)(`file/data/preview`, data), // for FTP and SMB
                [serviceMethodSub.postFileDataLocationTest]: (data) => callPost(ingestService)(`file/data/location-test`, data), // for FTP and SMB
            },
             //INGEST END
            
            [serviceMethodParent.Scheduler]: {
                [serviceMethodSub.readSchedulersByDashboardId]: (dashboardId)  => callGet(visualizeService)("scheduler/", dashboardId),
                [serviceMethodSub.createScheduler]: (data)  => callPost(visualizeService)("scheduler", data),
                [serviceMethodSub.updateScheduler]: (data)  => callPut(visualizeService)("scheduler", "", data),
                [serviceMethodSub.deleteScheduler]: (taskId)  => callDelete(visualizeService)("scheduler/", taskId),
                [serviceMethodSub.readTransactionsHistory]: ()  => callGet(visualizeService)("transactions/history"),
            },
            [serviceMethodParent.TaskTypes]: {
                [serviceMethodSub.readTypesCron]: ()  => callGet(visualizeService)("tasks/types/cron"),
                [serviceMethodSub.readAllMonths]: ()  => callGet(visualizeService)("tasks/types/months"),
                [serviceMethodSub.readMonthlyCron]: ()  => callGet(visualizeService)("tasks/types/monthly-cron"),
                [serviceMethodSub.readAllDays]: ()  => callGet(visualizeService)("tasks/types/days"),
                [serviceMethodSub.readDailyCron]: ()  => callGet(visualizeService)("tasks/types/daily-cron"),
                [serviceMethodSub.readTimeRanges]: ()  => callGet(visualizeService)("tasks/types/time-ranges"),
            },
            [serviceMethodParent.Scenario]: {
                [serviceMethodSub.readScenarioById]: (id) => callGet(cepApiService)("scenarios/", id),
                [serviceMethodSub.readScenarios]: () => callGet(cepApiService)("scenarios"),
                [serviceMethodSub.readScenarioItems]: () => callGet(cepApiService)("scenario-item"),
                [serviceMethodSub.readEvents]: () => callGet(cepApiService)("events"),
                [serviceMethodSub.readEnrichments]: () => callGet(cepApiService)("enrichments"),
                [serviceMethodSub.readActions]: () => callGet(cepApiService)("actions"),
                [serviceMethodSub.updateScenario]: (scenarioId, data) => callPut(cepApiService)("scenarios/", scenarioId, data),
                [serviceMethodSub.createScenario]: (data) => callPost(cepApiService)("scenarios", data),
                [serviceMethodSub.createEvent]: (data) => callPost(cepApiService)("events", data),
                [serviceMethodSub.updateEvent]: (id, data) => callPut(cepApiService)("events/", id, data),
                [serviceMethodSub.readEventById]: (id) => callGet(cepApiService)("events/", id),
                [serviceMethodSub.createEnrichment]: (data) => callPost(cepApiService)("enrichments", data),
                [serviceMethodSub.updateEnrichment]: (id, data) => callPut(cepApiService)("enrichments/", id, data),
                [serviceMethodSub.readEnrichmentById]: (id) => callGet(cepApiService)("enrichments/", id),
                [serviceMethodSub.testEnrichment]: (data) => callPost(cepApiService)("enrichments/connection", data),
                [serviceMethodSub.readDatasources]: () => callGet(cepApiService)("connections"),
                [serviceMethodSub.createSimulate]: (data) => callPost(cepApiService)("simulate", data),
                [serviceMethodSub.deployScenario]: (id) => callPut(cepApiService)("scenarios/start/", id, null),
                [serviceMethodSub.undeployScenario]: (id) => callPut(cepApiService)("scenarios/stop/", id, null),
                [serviceMethodSub.deleteScenario]: (id) => callDelete(cepApiService)("scenarios/", id),
                [serviceMethodSub.deleteEnrichment]: (id) => callDelete(cepApiService)("enrichments/", id),
                [serviceMethodSub.deleteEvent]: (id) => callDelete(cepApiService)("events/", id),
            },
            [serviceMethodParent.FieldAuthorization]: {
                [serviceMethodSub.readFieldAuthorizationByDatamodelId]: (datamodelId) => callGet(visualizeService)(`field/datamodel/${datamodelId}/authorization`),
                [serviceMethodSub.readFieldAuthorizationByDatasetId]: (datasetId) => callGet(visualizeService)(`field/dataset/${datasetId}/authorization`),
                [serviceMethodSub.createFieldAuthorization]: (data) => callPost(visualizeService)("field/authorization", data),
                [serviceMethodSub.updateFieldAuthorization]: (id, data) => callPut(visualizeService)("field/authorization/", id, data),
                [serviceMethodSub.deleteFieldAuthorization]: (id) => callDelete(visualizeService)("field/authorization/", id),
            },
            [serviceMethodParent.ObjectLock]: {
                [serviceMethodSub.createObjectLock]: (data) => callPost(visualizeService)("object/lock", data),
                [serviceMethodSub.deleteObjectLock]: (data) => callDeleteFormData(visualizeService)("object/lock", data),
                [serviceMethodSub.readObjectLock]: (data) => callPost(visualizeService)(`object/lock/exist`, data),
            },
        };
    };

    return {
        getInstance: function () {
            if (!instance) {
                instance = init();
            }

            return instance;
        },

        // may required when module changes
        resetInstance: function () {
            instance = init();
        }
    };
})();