export const domain = "VISUALIZE_CONNECTIONS";

export const STATE = {
  CONNECTIONS: `${domain}/CONNECTIONS`,
  SELECTED_CONNECTION: `${domain}/SELECTED_CONNECTION`,
  CONNECTION_AUTHORIZATION: `${domain}/CONNECTION_AUTHORIZATION`,
  USER_AUTHORIZATIONS: `${domain}/USER_AUTHORIZATIONS`,
  CONNECTION_TYPES: `${domain}/CONNECTION_TYPES`,
  SCHEMAS: `${domain}/SCHEMAS`,
};

export const GETTER = {
  GET_CONNECTIONS: `${domain}/GET_CONNECTIONS`,
  GET_SELECTED_CONNECTION: `${domain}/GET_SELECTED_CONNECTION`,
  GET_CONNECTION_AUTHORIZATION: `${domain}/GET_CONNECTION_AUTHORIZATION`,
  GET_USER_AUTHORIZATIONS: `${domain}/GET_USER_AUTHORIZATIONS`,
  GET_CONNECTION_TYPES: `${domain}/GET_CONNECTION_TYPES`,
  GET_SCHEMAS: `${domain}/GET_SCHEMAS`,
};

export const ACTION = {
  FETCH_CONNECTIONS: `${domain}/FETCH_CONNECTIONS`,
  FETCH_CONNECTION_TYPES: `${domain}/FETCH_CONNECTION_TYPES`,
  DELETE_CONNECTION: `${domain}/DELETE_CONNECTION`,
  CREATE_CONNECTION: `${domain}/CREATE_CONNECTION`,
  UPDATE_CONNECTION: `${domain}/UPDATE_CONNECTION`,
  TEST_CONNECTION: `${domain}/TEST_CONNECTION`,
  FIND_BY_CONNECTION_ID: `${domain}/FIND_BY_CONNECTION_ID`,
  FIND_AUTHORITIES_BY_USERNAME: `${domain}/FIND_AUTHORITIES_BY_USERNAME`,
  SAVE_CONNECTION_AUTHORIZATION: `${domain}/SAVE_CONNECTION_AUTHORIZATION`,
  UPDATE_CONNECTION_AUTHORIZATION: `${domain}/UPDATE_CONNECTION_AUTHORIZATION`,
  DELETE_CONNECTION_AUTHORIZATION: `${domain}/DELETE_CONNECTION_AUTHORIZATION`,
  FETCH_CONNECTION_BY_DASHBOARD_ID: `${domain}/FETCH_CONNECTION_BY_DASHBOARD_ID`,
  FETCH_SCHEMA_BY_CONNECTION_ID: `${domain}/FETCH_SCHEMA_BY_CONNECTION_ID`,
  FETCH_FIELDS_BY_SCHEMA_AND_TABLE: `${domain}/FETCH_FIELDS_BY_SCHEMA_AND_TABLE`,
};

export const MUTATION = {
  SET_CONNECTIONS: `${domain}/SET_CONNECTIONS`,
  SET_SELECTED_CONNECTION: `${domain}/SET_SELECTED_CONNECTION`,
  SET_CONNECTION_AUTHORIZATION: `${domain}/SET_CONNECTION_AUTHORIZATION`,
  SET_USER_AUTHORIZATIONS: `${domain}/SET_USER_AUTHORIZATIONS`,
  SET_CONNECTION_TYPES: `${domain}/SET_CONNECTION_TYPES`,
  SET_SCHEMAS: `${domain}/SET_SCHEMAS`,
};
