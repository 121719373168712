<template>
  <div>
    <div class="vis-tools-label-box">
      <span class="vis-font-bold">
        {{ $t("datamodel.dataModelProperties") }}</span
      >
    </div>
    <el-collapse
      :value="activeCollapses"
      @change="(e) => (activeCollapses = e)"
    >
      <el-collapse-item
        class="vis-collapse-item"
        v-for="collapse in allCollapses"
        :key="collapse.key"
        :title="$t(`${collapse.label}`)"
        :name="collapse.key"
      >
        <DataModelRightPropertiesPanelDBGeneral
          v-if="collapse.key === collapseTypes.general"
          :selectedDataset="selectedDataset"
          :sqlViewVisible="sqlViewVisible"
          :selectedDataModel="selectedDataModel"
          :isDataModelLocked="isDataModelLocked"
          @setDatamodelName="(value) => $emit('setDatamodelName', value)"
          @setDatamodelPropertiesValue="(params) => $emit('setDatamodelPropertiesValue', params)"
          @setQueryTimeout="(value) => $emit('setQueryTimeout', value)"
          @setQueryStatus="(value) => $emit('setQueryStatus', value)"
        />
        <DataModelRightPropertiesPanelDBLoadOptions
          v-else-if="collapse.key === collapseTypes.load_options"
        />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import DataModelRightPropertiesPanelDBGeneral from "./DataModelRightPropertiesPanelDBGeneral";
import DataModelRightPropertiesPanelDBLoadOptions from "./DataModelRightPropertiesPanelDBLoadOptions";
import { COMPONENT_PRIVILEGES } from "../../../util/authorization";
import componentAvailabilityCheck from "../../../mixins/componentAvailabilityCheck";

export default {
  components: {
    DataModelRightPropertiesPanelDBGeneral,
    DataModelRightPropertiesPanelDBLoadOptions,
  },
  mixins: [componentAvailabilityCheck],
  props: {
    selectedDataset: {
      type: Object,
    },
    sqlViewVisible: {
      type: Boolean,
    },
    selectedDataModel: {
      type: Object,
    },
    isDataModelLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collapseTypes: {
        general: "general",
        load_options: "load_options",
      },
      activeCollapses: [],
    };
  },
  mounted() {
    this.activeCollapses = Object.keys(this.collapseTypes);
  },
  computed: {
    allCollapses() {
      return [
        {
          key: this.collapseTypes.general,
          label: "generalPages.general",
          isShow: true,
        },
        this.isLoadOptionsAvailable
          ? {
              key: this.collapseTypes.load_options,
              label: "generalPages.loadOptions",
              isShow: !this.sqlViewVisible,
            }
          : null,
      ].filter((x) => x && x.isShow);
    },
    isSchedulingAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.DATAMODEL_PAGE_SCHEDULE_SECTION
      );
    },
    isLoadOptionsAvailable() {
      return this.isComponentAvailable(
        COMPONENT_PRIVILEGES.DATAMODEL_PAGE_LOAD_OPTIONS_SECTION
      );
    },
  },
};
</script>
