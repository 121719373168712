<template>
  <div class="dashboard">
    <div class="vis-topbar vis-ma--none vis-setting-bar">
      <div class="vis-row" style="height: 100%">
        <div
          class="vis-dashboard-custom-col vis-flex--Ycenter vis-mobile-hidden vis-right-border"
          v-if="!isModeDashboardView"
        >
          <SettingsLeftTabLink
            v-if="isPageLink"
            :activeSettingTab="activeSettingTab"
            :showDataModelLink="showDataModelLink"
            :showParametersLink="showParametersLink"
            @setSettingLeftTab="setSettingLeftTab"
          />
        </div>
        <div
          v-show="isTabSectionVisible"
          class="vis-col vis-col-7 vis-col-xxxl6 vis-col-xxl5 vis-col-xl6 vis-col-l5 vis-col-m4 vis-mb--none vis-pa--none vis-mobile-hidden"
          :class="[
            { 'vis-col-9 vis-col-xl8 vis-col-l7 vis-col-m6': isModeDashboardView },
          ]"
        >
          <DashboardTab
            v-show="isActiveTab"
            :tabList="tabList"
            :selectedTab="selectedTab"
            :isModeDashboardView="isModeDashboardView"
            :tabIndex="tabIndex"
            @setTabIndex="setTabIndex"
            @deleteDashboardTab="deleteDashboardTab"
            @addDashboardTab="addDashboardTab"
            @duplicateDashboardTab="duplicateDashboardTab"
            @dashboardTabChanged="$emit('dashboardTabChanged')"
          />
        </div>
        <div
          class="vis-col vis-mb--none vis-text--right vis-flex--Xright vis-flex--Ycenter vis-mobile-hidden"
          :class="{ 'vis-col-12': !isTabSectionVisible && isModeDashboardView }"
        >
          <div v-if="isActiveIconBox" class="vis-settingbar-iconBox">
            <div
              v-for="icon in iconList"
              :key="icon.id"
              class="vis-display-inline-block"
            >
              <el-tooltip
                class="item"
                effect="primary"
                :content="icon.tooltipContent"
                placement="bottom"
                :disabled="!icon.isShowTooltip"
              >
                <button
                  v-if="!icon.useDropdown"
                  @click.stop="icon.serviceFn(icon.id)"
                  class="vis-icon-button"
                  :class="[icon.className]"
                  :disabled="icon.disabled"
                >
                  <i
                    class="vis-position-relative"
                    :class="[
                      icon.iconClassName,
                      {
                        'vis-active-setting-icon': icon.isActiveClass,
                      },
                    ]"
                    aria-hidden="true"
                  >
                    <el-badge
                      v-if="icon.isShowElBadge"
                      :value="icon.elBadgeValue"
                      class="item"
                    ></el-badge>
                  </i>
                </button>
                <div
                  slot="content"
                  class="vis-mx--05 vis-display-flex vis-flex-direction--column"
                  v-if="isDashboardLocked"
                >
                  <span class="vis-color-quartz vis-text--xsmall">{{
                    $t("generalPages.Locked By")
                  }}</span>
                  <span class="vis-color-white">{{
                    dashboardLockingInfo.lockCreatedBy
                  }}</span>
                  <span class="vis-color-quartz vis-text--xsmall vis-mt--05">{{
                    $t("generalPages.Locked Date")
                  }}</span>
                  <span class="vis-color-white">{{
                    formatISOToDateTime(dashboardLockingInfo.lockCreatedDate)
                  }}</span>
                </div>
              </el-tooltip>
              <!-- ExportIcon  START -->
              <el-dropdown
                v-if="icon.useDropdown"
                trigger="click"
                placement="bottom"
                @command="handleCommand"
                :class="isModeDashboardView ? 'vis-mr--1_5' : 'vis-ma--none'"
              >
                <i :class="icon.className" aria-hidden="true"></i>

                <el-dropdown-menu class="vis-dropdown-menu">
                  <el-dropdown-item
                    v-for="popoverItem in popoverMenuItems"
                    :key="popoverItem.id"
                    :command="popoverItem"
                  >
                  <i class="vis-cursor-pointer" :class="popoverItem.icon" aria-hidden="true"></i>
                  <span>{{ $t(popoverItem.label) }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- ExportIcon END -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardTab from "../dashboard/DashboardTab.vue";
import SettingsLeftTabLink from "./SettingsLeftTabLink.vue";
import { v4 as uuidv4 } from "uuid";
import { CustomIcon } from "../../assets/js/custom-icons";
import { settingsBarParamKeys } from "../../util/embeded/queryString";
import { checkDashboardName } from "../../commons/helper";
import { formatISOToDateTime } from "../../util/moment";

export default {
  props: {
    isCalculatedColumnsFunctionsMenuOpen: {
      type: Boolean,
      default: false,
    },
    isDatamodelPreviewVisible: {
      type: Boolean,
      default: false,
    },
    allowSelectionPaneInView: {
      type: Boolean,
      default: true,
    },
    allowFilterGridLayoutInView: {
      type: Boolean,
      default: true,
    },
    isActionsSectionVisible: {
      type: Boolean,
      default: false,
    },
    isTabSectionVisible: {
      type: Boolean,
      default: false,
    },
    isPageLink: {
      type: Boolean,
      default: false,
    },
    isModeDashboardView: {
      type: Boolean,
      default: false,
    },
    tabList: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedTab: {
      type: Array,
    },

    tabIndex: {
      type: Number,
    },
    activeSettingTab: {
      type: String,
    },
    isDashboardPropertiesToggled: {
      type: Boolean,
      default: true,
    },
    isDashboardSelectionPaneActive: {
      type: Boolean,
      default: false,
    },
    isEditAvailable: {
      type: Boolean,
      default: false,
    },
    filterSelectionValueLength: {
      type: Number,
      default: 0,
    },

    isFullScreen: {
      type: Boolean,
    },
    settingsBarQueryParams: {
      type: Object,
      default: () => {
        return {
          [settingsBarParamKeys.embeded]: true,
          [settingsBarParamKeys.resize]: true,
          [settingsBarParamKeys.close]: true,
          [settingsBarParamKeys.filter]: true,
          [settingsBarParamKeys.settingsBar]: true,
          [settingsBarParamKeys.exportMenu]: true,
        };
      },
    },
    isActiveIconBox: {
      type: Boolean,
      default: true,
    },
    calculatedColumnCreateVisible: {
      type: Boolean,
      default: false,
    },
    selectedTabDesignMode: {
      type: Boolean,
      default: false,
    },
    isDashboardSaveLoading: {
      type: Boolean,
      default: false,
    },
    isUserDatamodelAccessAuthority: {
      type: Boolean,
      default: true,
    },
    isVisualizeAiIconDisabled: {
      type: Boolean,
      default: false,
    },
    showDataModelLink: {
      type: Boolean,
      default: false,
    },
    showParametersLink: {
      type: Boolean,
      default: false,
    },
    isChartFilterChannelViewActive: {
      type: Boolean,
      default: false,
    },
    isFilterGridLayoutActive: {
      type: Boolean,
      default: false,
    },
    filterGridLayoutFilterValueLength: {
      type: Number,
      default: 0,
    },
    isDashboardLocked: {
      type: Boolean,
      default: false,
    },
    dashboardLockingInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    DashboardTab,
    SettingsLeftTabLink,
  },
  data() {
    return {
      CustomIcon: CustomIcon,
      formatISOToDateTime: formatISOToDateTime,
    };
  },
  created() {
    this.$parent.$on(
      "changeActiveClassInIconList",
      this.updateIsActiveClassIconFilter
    );
  },
  computed: {
    isDesignModeAndEditModeActive() {
      return !this.isModeDashboardView && this.selectedTabDesignMode;
    },
    iconList() {
      return [
        {
          id: uuidv4(),
          iconClassName: this.isDashboardLocked
            ? `${CustomIcon.LockClose}`
            : `${CustomIcon.LockOpen}`,
          isActiveClass: this.isDashboardLocked,
          isShowTooltip: this.isDashboardLocked,
          serviceFn: () => this.$emit("lockDashboard", !this.isDashboardLocked),
        },
        //FOR DESIGN MODE
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.Play}`,
          className: `${
            !this.isActionsSectionVisible ||
            !this.selectedTabDesignMode ||
            this.isModeDashboardView
              ? "vis-display-none"
              : ""
          }`,
          serviceFn: () => this.$emit("viewChartWithDesignModeStartButton"),
        },
        //FOR FILTER SELECTION
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.Selections}`,
          className: `${
            !this.isActionsSectionVisible ||
            !this.settingsBarQueryParams.filter ||
            (!this.allowSelectionPaneInView && this.isModeDashboardView)
              ? "vis-display-none"
              : ""
          }`,
          isActiveClass: this.isDashboardSelectionPaneActive,
          serviceFn: (id) => this.toggleDashboardSelectionPane(id),
          isShowElBadge: this.filterSelectionValueLength,
          elBadgeValue: this.filterSelectionValueLength,
        },
        //FOR FILTER PANE (FILTER GRID LAYOUT)
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.FilterGridLayout}`,
          className: `${
            this.isDatamodelPreviewVisible ||
            (!this.allowFilterGridLayoutInView && this.isModeDashboardView)
              ? "vis-display-none"
              : ""
          }`,
          isActiveClass: this.isFilterGridLayoutActive,
          serviceFn: () => this.$emit("toggleFilterGridLayout"),
          isShowElBadge: this.filterGridLayoutFilterValueLength,
          elBadgeValue: this.filterGridLayoutFilterValueLength,
        },
        //FOR CHAT BOT (VISUALIZE AI)
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.Lamp} vis-font-bold`,
          className: `${
            this.isVisualizeAiIconDisabled ||
            this.isModeDashboardView ||
            this.isDatamodelPreviewVisible
              ? "vis-display-none"
              : ""
          }`,
          serviceFn: () => this.$emit("goPanelPageWithAiAction"),
        },
        //FOR CHART FILTER CHANNEL
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.Link}`,
          className: `${
            !this.isEditAvailable ||
            this.isDatamodelPreviewVisible ||
            this.isModeDashboardView
              ? "vis-display-none"
              : ""
          }`,
          isActiveClass: this.isChartFilterChannelViewActive,
          serviceFn: () => this.$emit("toggleChartFilterChannelView"),
        },
        // FOR DASHBOARD AND CHART PROPERTIES
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.Property}`,
          className: `${
            !this.isActionsSectionVisible || this.isModeDashboardView
              ? "vis-display-none"
              : ""
          }`,
          isActiveClass: this.isDashboardPropertiesToggled,
          serviceFn: (id) => this.toggleDasboardSettings(id),
        },
        //FOR DOWNLOAD
        {
          id: uuidv4(),
          className: `${CustomIcon.Download} vis-mr--none vis-cursor-pointer ${
            !this.isModeDashboardView ? "vis-display-none" : ""
          }`,
          useDropdown: true,
        },
        //FOR FULL SCREEN
        {
          id: uuidv4(),
          iconClassName: `${
            this.isFullScreen
              ? CustomIcon.CloseFullScreen
              : CustomIcon.OpenInFull
          } `,
          className: `${
            !this.isActionsSectionVisible ||
            !this.settingsBarQueryParams.resize ||
            !this.isModeDashboardView
              ? "vis-display-none"
              : ""
          } ${
            !this.isEditAvailable && !this.settingsBarQueryParams.close
              ? "vis-ma--none"
              : ""
          }`,
          serviceFn: () => this.toggleDashboardScreenMode(),
        },
        //FOR DASHBOARD EDIT
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.PencilOutline}`,
          className: `${
            !this.settingsBarQueryParams.close && this.isModeDashboardView
              ? "vis-ma--none"
              : ""
          } ${
            !this.isEditAvailable || this.isDatamodelPreviewVisible
              ? "vis-display-none"
              : ""
          }`,
          disabled: this.isDashboardSaveLoading,
          isActiveClass: !this.isModeDashboardView,
          serviceFn: (id) => this.edit(id),
        },
        //FOR DASHBOARD SAVE
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.SaveOutlined}`,
          className: `${
            !this.isActionsSectionVisible || this.isModeDashboardView
              ? "vis-display-none"
              : ""
          }`,
          disabled: this.isDashboardSaveLoading,
          serviceFn: () => this.save(),
        },
        //FOR CLOSE DASHBOARD
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.Close}`,
          className: `vis-mr--none ${
            !this.isActionsSectionVisible || !this.settingsBarQueryParams.close
              ? "vis-display-none"
              : ""
          }`,
          serviceFn: () => this.$router.push("/"),
        },
        //FOR GO TO DATAMODEL. **IF SELECT DATAMODEL TAB**
        {
          id: uuidv4(),
          iconClassName: `${CustomIcon.DatamodelIcon}`,
          className: `vis-mr--none ${
            !this.isDatamodelPreviewVisible ||
            !this.isUserDatamodelAccessAuthority ||
            this.calculatedColumnCreateVisible
              ? "vis-display-none"
              : ""
          }`,
          serviceFn: () => this.$emit("goToViewDatamodel"),
          isShowTooltip: true,
          tooltipContent: this.$t("Open Datamodel In New Tab"),
        },
        //FOR CALCULATION SCREEN. **IF FIELD CALCULATION IN SELECT DATAMODEL TAB **
        {
          id: uuidv4(),
          iconClassName: `mdi mdi-function-variant`,
          className: `vis-mr--none ${
            !this.isDatamodelPreviewVisible ||
            !this.calculatedColumnCreateVisible
              ? "vis-display-none"
              : ""
          }`,
          isActiveClass: this.isCalculatedColumnsFunctionsMenuOpen,
          serviceFn: () => this.toggleFunctionsMenu(),
        },
      ];
    },
    isActiveTab() {
      return checkDashboardName(this.$route.name);
    },
    popoverMenuItems() {
      return [
        {
          id: uuidv4(),
          icon: CustomIcon.ExportPDF,
          label: "panelProperties.pdfExport",
          serviceFn: () => this.exportDashboardToPdf(),
          active: this.isModeDashboardView,
        },
        {
          id: uuidv4(),
          icon: CustomIcon.FileImageOutline,
          label: "panelProperties.imageExport",
          serviceFn: () => this.exportDashboardToImage(),
          active: this.isModeDashboardView,
        },
        {
          id: uuidv4(),
          icon: CustomIcon.EmailSendOutline,
          label: "schedule.Send as Email",
          serviceFn: () => this.openSchedulePopup(),
          active: this.isModeDashboardView,
        },
      ].filter((x) => x.active);
    },
  },

  methods: {
    updateIsActiveClassIconFilter() {
      this.iconList.find(
        (x) => x.type == CustomIcon.Selections
      ).isActiveClass = false;
    },

    updateIsActiveClassById(id) {
      let icon = this.iconList.find((x) => x.id == id);
      icon.isActiveClass = !icon.isActiveClass;
    },

    edit(id) {
      this.updateIsActiveClassById(id);
      this.$emit("editPanel");
    },
    toggleFunctionsMenu() {
      this.$emit("toggleFunctionsMenu");
    },
    toggleDasboardSettings(id) {
      this.updateIsActiveClassById(id);
      this.$emit("toggleDasboardSettings");
    },
    toggleDashboardSelectionPane(id) {
      this.updateIsActiveClassById(id);
      this.$emit("toggleDashboardSelectionPane");
    },
    save() {
      this.$emit("save");
    },
    setTabIndex(index) {
      this.$emit("setTabIndex", index);
    },
    deleteDashboardTab(index) {
      this.$emit("deleteDashboardTab", index);
    },
    addDashboardTab() {
      this.$emit("addDashboardTab");
    },
    duplicateDashboardTab(value) {
      this.$emit("duplicateDashboardTab", value);
    },
    setSettingLeftTab(value) {
      this.$emit("setSettingLeftTab", value);
    },
    toggleDashboardScreenMode() {
      this.$emit("toggleDashboardScreenMode");
    },
    exportDashboardToPdf() {
      this.$emit("exportDashboardToPdf");
    },
    exportDashboardToImage() {
      this.$emit("exportDashboardToImage");
    },
    openSchedulePopup() {
      this.$emit("openSchedulePopup");
    },
    handleCommand(command) {
      if (command) command.serviceFn(command.params);
    },
  },
};
</script>
<style scoped>
.vis-icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 1.5rem;
}
.vis-icon-button:disabled {
  cursor: not-allowed;
}
.vis-settingbar-iconBox .el-badge {
  position: absolute;
  top: -8px;
  right: -10px;
}
.el-dropdown-menu.el-popper.vis-dropdown-menu .el-dropdown-menu__item {
  height: 40px !important; 
}
.vis-dropdown-menu .el-dropdown-menu__item i {
  font-size: 1.25rem !important;
}
@media only screen and (max-width: 850px) {
  .vis-settingbar-iconBox .vis-icon-button {
    margin-right: 1rem;
  }
}
</style>
