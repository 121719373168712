<template>
  <div class="vis-panel-data-box">
    <label>{{ boxName }}</label>
    <div class="vis-panel-data-card" :class="panelDataBoxEmptyClass">
      <draggable
        class="list-group"
        :class="activeFieldBoxClass"
        :list="draggableList"
        :group="{ name: 'people', pull: 'clone' }"
        @change="changeDraggableList($event)"
      >
        <ul
          class="list-group-item"
          v-for="(item, index) in boxList"
          :key="item.fieldId + index"
        >
          <PanelCommonsBoxFieldItem
            :fieldItem="item"
            :fieldIndex="index"
            :contextMenuItems="contextMenuItems"
            :contextMenuId="contextMenuId"
            :selectedDataModel="selectedDataModel"
            @openContextMenu="openContextMenu"
            @setSelectType="setSelectType"
            @beforeContextItemHighlight="beforeContextItemHighlight"
            @onSortMethodSwitched="onSortMethodSwitched($event, item)"
            @onItemClicked="openColumnBasedFormattingProperties($event)"
          />
        </ul>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import PanelCommonsBoxFieldItem from "./PanelCommonsBoxFieldItem.vue";
export default {
  components: {
    draggable,
    PanelCommonsBoxFieldItem,
  },
  props: {
    boxName: {
      type: String,
    },
    boxList: {
      type: Array,
    },
    contextMenuId: {
      type: String,
    },
    draggableList: {
      type: Array,
    },
    panelDataBoxEmptyClass: {
      type: String,
    },
    activeFieldBoxClass: {
      type: String,
    },
    contextMenuItems: {
      type: Array,
    },
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    changeDraggableList(args) {
      if (args?.added && args?.added?.element?.isAuthorize === undefined) {
        args.added.element.isAuthorize = true;
      }
      //To prevent the user from drag-and-drop if they do not have permission to the field.
      if (args?.added && !args?.added?.element?.isAuthorize) return;

      if (args?.removed) {
        return;
      }

      if (args?.added || args?.moved) this.$emit("changeDraggableList", args);
    },
    openContextMenu(value, params) {
      this.$emit("openContextMenu", value, params);
    },
    setSelectType(args) {
      this.$emit("setSelectType", args);
    },
    beforeContextItemHighlight(args) {
      this.$emit("beforeContextItemHighlight", args);
    },
    onSortMethodSwitched(sortMethod, field) {
      this.$emit("onSortMethodSwitched", sortMethod, field);
    },
    openColumnBasedFormattingProperties(value) {
      this.$emit("onSelectColumnBasedPropertiesColumn", value);
    },
  },
};
</script>
