<template>
  <div
    :class="[
      { 'vis-text--center': !isChartPlaceHolder },
      { 'vis-height-100': isActiveFullHeight },
    ]"
  >
    <img
      v-if="!isChartPlaceHolder && !isPanelTypeLabel"
      :src="getImage"
      class="vis-chart-placeHolder"
      alt="Empty Chart"
    />
    <div
      v-if="isYaxisNameOnTop || isSecondYaxisNameOnTop"
      class="vis-flex--spacebeetwen"
    >
      <div class="vis-flex-1">
        <div
          class="vis-isYaxisNameOnTop"
          v-if="isYaxisNameOnTop"
          :style="yAxisNameStyle"
        >
          {{ chartOptions.yAxis.name }}
        </div>
      </div>
      <div v-if="isSecondYaxisNameOnTop" class="vis-flex--Xright vis-flex-1">
        <div :style="yAxisNameStyle">
          {{ chartOptions.secondYAxis.name }}
        </div>
      </div>
    </div>

    <div
      v-if="chartOptions"
      :class="{ 'vis-height-100': isActiveFullHeight }"
      class="vis-display-flex no-drag"
    >
      <div
        v-if="isYaxisNameOnSide"
        class="vis-flex--center vis-chart-yAxis-name-outer"
      >
        <div class="vis-chart-yAxis-name" :style="yAxisNameStyle">
          {{ getYaxisName() }}
        </div>
      </div>
      <div
        :id="panelId"
        v-bind:style="{
          height: isPanelPage
            ? `${panelHeight}px`
            : calculatedHeightByChartCardSize,
        }"
        class="vis-flex--Xcenter"
        style="flex: 1"
        @click.stop="$emit('closePopoverMenu')"
      ></div>
      <ejs-contextmenu
        class="chart-context"
        :id="contextMenuId"
        :items="contextMenuItems"
        :select="selectContextItem"
        :animationSettings="animationSettings"
        :beforeItemRender="beforeContextItemHighlight"
      ></ejs-contextmenu>
      <div
        v-if="isSecondYaxisNameOnSide"
        class="vis-flex--center vis-chart-yAxis-name-outer"
      >
        <div class="vis-chart-yAxis-name" :style="secondYAxisNameStyle">
          {{ chartOptions.secondYAxis.name }}
        </div>
      </div>
    </div>
    <div
      v-if="isXaxisNameVisible"
      class="vis-flex--center vis-chart-xAxis-name"
      :style="xAxisNameStyle"
    >
      {{ getXaxisName() }}
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { routerEnums } from "../../../commons/Enum";
import panelDataPropertiesStyle from "../../../mixins/panelDataPropertiesStyle";
import { chartTypes } from "../../../commons/dashboardAndPanel";
import { getConcatArrayWithPipe } from "../../../util/chartOptionsHelper";
import { getAreaChartOptions } from "../../../util/chart-options/areaChartOptionsProcessor";
import { getLineChartOptions } from "../../../util/chart-options/lineChartOptionsProcessor";
import { getPieChartOptions } from "../../../util/chart-options/pieChartOptionsProcessor";
import { getBarChartOptions } from "../../../util/chart-options/barChartOptionsProcessor";
import { getScatterChartOptions } from "../../../util/chart-options/scatterChartOptionsProcessor";
import { getColumnChartOptions } from "../../../util/chart-options/columnChartOptionsProcessor";
import { getTreemapOptions } from "../../../util/chart-options/treemapOptionsProcessor";
import { getHeatmapOptions } from "../../../util/chart-options/heatmapChartOptionsProcessor";
import { createChartFilter } from "../../../util/chart-filter/filterValueSetters";
import { getBubbleChartOptions } from "../../../util/chart-options/bubbleChartOptionsProcessor";
import { getCombinationChartOptions } from "../../../util/chart-options/combinationChartOptionsProcessor";
import { getGaugeChartOptions } from "../../../util/chart-options/gaugeChartOptionsProcessor";
import { getRadarChartOptions } from "../../../util/chart-options/radarChartOptionsProcessor";
import { getStackedColumnChartOptions } from "../../../util/chart-options/stackedColumnChartOptionsProcessor";
import { getStackedBarChartOptions } from "../../../util/chart-options/stackedBarChartOptionsProcessor";
import { getGeoMapOptions } from "../../../util/chart-options/geoMapOptionsProcessor";
import { getSankeyChartOptions } from "../../../util/chart-options/sankeyChartOptionsProcessor";
import {
  chartContextMenuItemKey,
  getSelectedChartItemNameBySeriesType,
} from "../../../util/chart-context-menu/chartContextMenuHelper";
import { ACTION as ACTION_CHART_FIELDS } from "../../../store/modules/Visualize/ChartFields/types";
import { mapActions } from "vuex";
import { filterOperator } from "../../../commons/filterComponents";
import { isChartHeightLimited } from "../../../util/chartViewHelper";
import cloneDeep from "clone-deep";

export default {
  mixins: [panelDataPropertiesStyle],
  props: {
    paramsFromSearch: {
      type: Object,
      default() {
        return {};
      },
    },
    panelData: {
      type: Object,
    },
    isFullPage: {
      type: String,
    },
    contextMenuItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isPanelTypeLabel: {
      type: Boolean,
      default: false,
    },
    hasFootNote: {
      type: Boolean,
      default: false,
    },
    tabPanelHasFootNote: {
      type: Boolean,
      default: true,
    },
    hasDescription: {
      type: Boolean,
      default: false,
    },
    isModeDashboardView: {
      type: Boolean,
      default: false,
    },
    selectedTabDesignMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartDivOffset: null,
      chart: null,
      chartOptions: null,
      contextMenuId: "chartMenu_" + Math.random(),
      animationSettings: { effect: "FadeIn", duration: 800 },
      chartPropParams: null,
      chartOptionsProcessorMapping: {
        [chartTypes.COLUMN_CHART]: () =>
          getColumnChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.yAxisName,
            this.xAxisName,
            this.panelData
          ),
        [chartTypes.BAR]: () =>
          getBarChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.yAxisName,
            this.xAxisName,
            this.panelData
          ),
        [chartTypes.PIE]: () =>
          getPieChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.panelData
          ),
        [chartTypes.LINE_CHART]: () =>
          getLineChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.yAxisName,
            this.xAxisName,
            this.panelData
          ),
        [chartTypes.AREA_CHART]: () =>
          getAreaChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.yAxisName,
            this.xAxisName,
            this.panelData
          ),
        [chartTypes.TREEMAP_CHART]: () =>
          getTreemapOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.panelData,
            this.isPanelPage
          ),
        [chartTypes.HEATMAP_CHART]: () =>
          getHeatmapOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.panelData
          ),
        [chartTypes.SCATTER_CHART]: () =>
          getScatterChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.panelData
          ),
        [chartTypes.BUBBLE_CHART]: () =>
          getBubbleChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.panelData
          ),
        [chartTypes.COMBINATION_CHART]: () =>
          getCombinationChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.panelData
          ),
        [chartTypes.GAUGE_CHART]: () =>
          getGaugeChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.isPanelPage
          ),
        [chartTypes.RADAR_CHART]: () =>
          getRadarChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.panelData
          ),
        [chartTypes.STACKED_COLUMN_CHART]: () =>
          getStackedColumnChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.yAxisName,
            this.xAxisName,
            this.panelData
          ),
        [chartTypes.STACKED_BAR_CHART]: () =>
          getStackedBarChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.yAxisName,
            this.xAxisName,
            this.panelData
          ),
        [chartTypes.GEO_MAP_CHART]: () =>
          getGeoMapOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.panelData
          ),
          [chartTypes.SANKEY_CHART]: () =>
          getSankeyChartOptions(
            this.CHART_COMPUTED_VALUES,
            this.paramsFromSearch,
            this.panelData
          ),
      },
    };
  },
 async mounted() {
    setTimeout(() => {
      const chartDiv = document.getElementById(this.panelId);
      this.chartDivOffset = {
        width: chartDiv?.offsetWidth ? chartDiv.offsetWidth - 30 : undefined,
        height: chartDiv?.offsetHeight ? chartDiv.offsetHeight - 30 : undefined,
      };

      this.initChart();
      this.setChartOptions();
      this.chartDraw();
      window.onresize = () => {
        this.chartDraw();
      };
    }, 300);
    
    await this.loadGeoMap();
  },
  watch: {
    chart: {
      handler(val) {
        if (val) {
          this.chartDraw();
        }
      },
    },
    hasFootNote: {
      handler() {
        this.$emit("changeCurrentResizingChartId");
      },
    },
    tabPanelHasFootNote: {
      handler() {
        this.$emit("changeCurrentResizingChartId");
      },
    },
    chartOptions: {
      handler(val) {
        if (val) {
          this.chartDraw();
        }
      },
      deep: true,
    },
    CHART_COMPUTED_VALUES: {
      async handler() {
        await this.loadGeoMap();
        this.setChartOptions();
      },
      deep: true,
    },
    paramsFromSearch: {
      handler() {
        this.setChartOptions();
      },
      deep: true,
    },
    chartDivOffset: {
      handler(val) {
        if (val) this.chartDraw();
      },
      deep: true,
    },
  },
  computed: {
    yAxisName() {
      const yAxisNameWithProperty = this.getYaxisNameWithProperty();

      return yAxisNameWithProperty
        ? yAxisNameWithProperty
        : getConcatArrayWithPipe(
            this.panelDataDetailsMetrics?.map((a) => a?.alias)
          );
    },
    xAxisName() {
      const xAxisNameWithProperty = this.getXaxisNameWithProperty();

      return xAxisNameWithProperty
        ? xAxisNameWithProperty
        : getConcatArrayWithPipe(
            this.panelDataDetailsAggs?.map((a) => a?.alias)
          );
    },

    chartType() {
      return this.panelData?.type;
    },
    panelId() {
      return this.panelData?.properties?.generatedDivid ?? "defaultDivId";
    },
    isPanelPage() {
      return this.$route.name === routerEnums.PANEL ? true : false;
    },
    panelHeight() {
      if (this.isPanelPage || this.isFullPage) return 700;
      else {
        let minusHeight = this.isXaxisNameVisible ? 120 : 90;
        return Number(this.panelData?.properties?.height) - minusHeight;
      }
    },

    calculatedHeightByChartCardSize() {
      const unitType = "px";

      const getDescriptionHeight = () => {
        if (!this.isModeDashboardView && this.hasDescription) {
          return 12;
        } else return 0;
      };

      //Chart'ın yüksekliği method'daki değerden düşükse chart karttaki tüm alanı kullansın
      if (!isChartHeightLimited(this.panelData.h)) {
        return 100 + "%";
      } else if (
        //Dashboard görüntüleme modunda ve dip not kapalı veya boş ise
        this.dashboardViewModeActiveAndFootNoteNotActive
      ) {
        return `${this.panelHeight + 35}${unitType}`;
      }
      else if (!this.tabPanelHasFootNote && !this.hasFootNote) {
        if (!this.isModeDashboardView) {
          return `${this.panelHeight + 30}${unitType}`;
        } else {
          return `${this.panelHeight - 10}${unitType}`;
        }
      }
      else if (this.tabPanelHasFootNote && !this.hasFootNote && !this.isModeDashboardView) {
          return `${this.panelHeight + 10}${unitType}`;
      }
      //DipNot alanı properties'den kapalı ise
      else if (!this.hasFootNote) {
        if (this.isYaxisNameOnTop) {
          return `${this.panelHeight - getDescriptionHeight()}${unitType}`;
        }

        return `${this.panelHeight + 20}${unitType}`;
      }
      //Chartlardaki y axis name chart'ın üst kısmında görüntüleniyorsa
      else if (this.isYaxisNameOnTop) {
        const total = 24 + getDescriptionHeight();
        return `${this.panelHeight - total}${unitType}`;
      }
      //Dashboard görüntüleme modunda değilse
      else if (!this.isModeDashboardView) {
        return `${this.panelHeight - getDescriptionHeight()}${unitType}`;
      } else return this.panelHeight + unitType;
    },
    dashboardViewModeActiveAndFootNoteNotActive() {
      return (
        this.isModeDashboardView &&
        (this.panelData.footnote == "" || !this.panelData.footnote)
      );
    },
    isActiveFullHeight() {
      return !isChartHeightLimited(this.panelData.h);
    },
    imageName() {
      return this.panelData?.type;
    },

    isChartPlaceHolder() {
      if (this.selectedTabDesignMode && !this.isModeDashboardView) {
        return Object.prototype.hasOwnProperty.call(
          this.paramsFromSearch,
          "dataSeries"
        );
      } else {
        return (
          this.panelData.details.metrics.length ||
          this.panelData.details.aggregation.length
        );
      }
    },

    getImage() {
      try {
        var images = require.context(
          "../../../assets/images/chartEmptyStates/",
          false,
          /\.svg$/
        );

        const image = images("./" + this.imageName + ".svg");

        return image;
      } catch (error) {
        console.warn("chart placeholder image not found!");
        return "";
      }
    },
    xAxisNameStyle() {
      return {
        fontSize: this.chartOptions.xAxis.fontSize + "px",
        fontWeight: this.chartOptions.xAxis.fontWeight,
        fontFamily: this.chartOptions.xAxis.fontFamily,
        color: this.chartOptions.xAxis.fontColor,
      };
    },
    yAxisNameStyle() {
      return {
        fontSize: this.chartOptions.yAxis.fontSize + "px",
        fontWeight: this.chartOptions.yAxis.fontWeight,
        fontFamily: this.chartOptions.yAxis.fontFamily,
        color: this.chartOptions.yAxis.fontColor,
      };
    },
    secondYAxisNameStyle() {
      return {
        fontSize: this.chartOptions.secondYAxis.fontSize + "px",
        fontWeight: this.chartOptions.secondYAxis.fontWeight,
        fontFamily: this.chartOptions.secondYAxis.fontFamily,
        color: this.chartOptions.secondYAxis.fontColor,
      };
    },
    isYaxisNameOnTop() {
      return (
        this.chartOptions?.yAxis?.show &&
        !this.chartOptions?.yAxis?.isNameLocationDefault
      );
    },
    isSecondYaxisNameOnTop() {
      return (
        this.chartOptions?.secondYAxis?.show &&
        !this.chartOptions?.secondYAxis?.isNameLocationDefault
      );
    },
    isSecondYaxisNameOnSide() {
      return (
        this.chartOptions.secondYAxis &&
        this.chartOptions.secondYAxis.name &&
        !this.isSecondYaxisNameOnTop &&
        this.chartOptions.secondYAxis.show
      );
    },
    isYaxisNameOnSide() {
      return (
        this.chartOptions?.yAxis?.show &&
        this.chartOptions?.yAxis?.isNameLocationDefault
      );
    },
    isXaxisNameVisible() {
      return this.chartOptions?.xAxis?.show;
    },
    isYaxisNameFloat() {
      return (
        this.chartOptions?.yAxis &&
        !this.chartOptions?.yAxis?.isAxisLocationDefault
      );
    },
    isYaxisNameOnRightSide() {
      return (
        this.chartOptions?.yAxis &&
        !this.chartOptions?.yAxis?.isAxisLocationDefault &&
        this.chartOptions?.yAxis?.isNameLocationDefault
      );
    },
    isYaxisNameOnLeftSide() {
      return (
        this.chartOptions?.yAxis?.isAxisLocationDefault &&
        this.chartOptions?.yAxis?.isNameLocationDefault
      );
    },
    isNameLocationDefault() {
      return this.chartOptions?.yAxis?.isNameLocationDefault;
    },
    drillThroughDashboardList(){
      return this.getDrillThroughProperties("DRILL_THROUGH_DASHBOARD_LIST");
    },
    allowDrillThroughDoubleClick(){
      return this.getDrillThroughProperties("DRILL_THROUGH_ON_DOUBLE_CLICK");
    }
  },
  beforeDestroy() {
    const that = this;
    that.chart && that.chart.dispose();
    that.chartDivOffset = null;
  },

  methods: {
    ...mapActions({
      readMapData: ACTION_CHART_FIELDS.FETCH_MAP_DATA,
    }),
    getYaxisName() {
      return (
        this.chartOptions?.yAxis?.name ||
        getConcatArrayWithPipe(
          this.panelDataDetailsMetrics?.map((a) => a?.alias)
        )
      );
    },
    getXaxisName() {
      return (
        this.chartOptions?.xAxis?.name ||
        getConcatArrayWithPipe(this.panelDataDetailsAggs?.map((a) => a?.alias))
      );
    },
    getXaxisNameWithProperty() {
      let keys = Object.keys(this.panelData?.properties?.style);
      let selectedKey = keys.find((f) => f.includes("X_AXIS_NAME_TEXT"));

      return this.CHART_COMPUTED_VALUES?.[selectedKey];
    },
    getYaxisNameWithProperty() {
      let keys = Object.keys(this.panelData?.properties?.style);
      let selectedKey = keys.find((f) => f.includes("Y_AXIS_NAME_TEXT"));

      return this.CHART_COMPUTED_VALUES?.[selectedKey];
    },
    getChartContextMenu() {
      return document.getElementById(this.contextMenuId).ej2_instances[0];
    },
    getDrillThroughProperties(value) {
      let keys = Object.keys(this.panelData?.properties?.style);
      let selectedKey = keys.find((f) => f.includes(value));

      return this.CHART_COMPUTED_VALUES?.[selectedKey];
    },
    setChartOptions() {
      this.chartOptions =
        this.chartOptionsProcessorMapping?.[this.chartType]?.();
    },
    initChart() {
      if (this.isPanelTypeLabel) return;

      setTimeout(async () => {
        const chartDiv = document.getElementById(this.panelId);

        this.chart = echarts.init(chartDiv, null, this.chartDivOffset);

        // Add event listeners for dataZoom
        this.chart.on('dataZoom', () => {
          this.$emit("disableDragDrop");
        });

        this.chart.on('globalout', () => {
          this.$emit("enableDragDrop");
        });
        
        this.createSelectedFilterFromChartEvent();
      }, 300);
    },

    openContext(event) {
      this.getChartContextMenu().open(event.y, event.x);
    },
    selectContextItem(args) {
      if (args.item.notClicked) return null;

      let contextMenuItemClicked = {
        [chartContextMenuItemKey.INCLUDE]: {
          serviceFn: () => this.addIncludeOrExcludeFilter(args),
        },
        [chartContextMenuItemKey.EXCLUDE]: {
          serviceFn: () => this.addIncludeOrExcludeFilter(args),
        },
        [chartContextMenuItemKey.DRILL_THROUGH]: {
          serviceFn: () => this.addDrillThroughFilter(args),
        },
        [chartContextMenuItemKey.DRILL_DOWN]: {
          serviceFn: () => this.addDrilDown(args),
        },
      };

      contextMenuItemClicked[args.item.key].serviceFn();

      this.getChartContextMenu().close();
    },

    addIncludeOrExcludeFilter(args) {
      const obj = createChartFilter(this.chartPropParams, this.panelData);

      obj.isDrillDown = true;
      obj.notRunTheViewChart = args?.item?.notRunTheViewChart;

      if (args.item.key == chartContextMenuItemKey.EXCLUDE)
        obj.operator = filterOperator.NOTEQ;

      this.$emit(args.item.emitFunction, obj);
    },

    addDrillThroughFilter(args) {
      this.chartPropParams.drillThrough = true;
      const obj = createChartFilter(this.chartPropParams, this.panelData);

      const filterObj = {
        filterValue: obj.value,
        filterId: obj.panelI,
      };

      this.$emit(
        args.item.emitFunction, //emit method name "onChartDrillThroughClick"
        filterObj,
        args.item.value //Context meuden seçilen dashboardId geliyor.
      );
    },

    addDrilDown(args) {
      this.addIncludeOrExcludeFilter(args);
      //Emit fonksiyonu ile aggregation array'inin değiştirilmesi sağlanacak.
      this.$emit("changeChartAggregation", {
        value: args.item.value,
        chartId: this.panelData.i,
      });
    },
    changeGeoMapAggAndParams(clonedPanelData, params) {
      if (clonedPanelData.type === chartTypes.GEO_MAP_CHART) {
        params.name = this.paramsFromSearch?.mapPureLocName.get(params.name);

        clonedPanelData.details.aggregation =
          clonedPanelData.details.aggregation.sort((a, b) => {
            return a.boxKey > b.boxKey ? 1 : -1;
          });
      }
    },

    handleDblClickDrillThrough() {
      this.addDrillThroughFilter({
        item: {
          emitFunction: "onChartDrillThroughClick",
          value: this.drillThroughDashboardList?.[0]?.id,
        },
      });
    },

    createSelectedFilterFromChartEvent() {
      
      let clickTimeout;

      this.chart.on(
        "contextMenu",
        (params) => {
          this.chartPropParams = params;

          this.setSelectedChartItemName(params);

          this.$emit("setContextClickedChartItemId", this.panelData.i);

          if (!this.isPanelPage) this.openContext(params.event.event);

          window.event.returnValue = false;
        },
        false
      );

      this.chart.on("click", (params) => {
        if (clickTimeout) {
          clearTimeout(clickTimeout);
          clickTimeout = null;
        } else {
          clickTimeout = setTimeout(() => {
            const clonedPanelData = cloneDeep(this.panelData);
            //For geo map chart
            this.changeGeoMapAggAndParams(clonedPanelData, params);

            const obj = createChartFilter(params, clonedPanelData);

            this.$emit("addSelectedFilterFromChart", obj);
            clickTimeout = null;
          }, 300);
        } // Çift tıklamayı ayırt etmek için eklendi!!
      });

      this.chart.on("dblclick", (params) => {
        clearTimeout(clickTimeout);
        clickTimeout = null;

        if (
          this.drillThroughDashboardList?.length === 1 &&
          this.allowDrillThroughDoubleClick
        ) {
          this.chartPropParams = params;
          this.handleDblClickDrillThrough();
        }
      });
    },

    setSelectedChartItemName(params) {
      const clickedChartItemName = getSelectedChartItemNameBySeriesType(params);

      this.getChartContextMenu().enableItems(
        [`${clickedChartItemName}`],
        false
      ); //TODO ej2 tarafına ticket açıldı. Tekrar bakıcalak.

      this.$emit("setClickedChartItemName", clickedChartItemName);
    },

    chartDraw() {
      if (this.chartOptions && this.chart) {
        const gridItem = document.getElementById(this.panelData.id || this.panelData.i);
        //for backend schedule screen shot.finished function is runs when chart drawing is completed
        this.chart.on("finished", () => {
          if (gridItem) gridItem.setAttribute("draw-finished", true);
        });
        this.chart.setOption(this.chartOptions?.options, true);
      }
    },
    async loadGeoMap() {
      if (this.chartType !== chartTypes.GEO_MAP_CHART) return;

      const selectedCountry =
        this.CHART_COMPUTED_VALUES?.GEO_MAP_CHART_SELECTED_COUNTRY;

      if (!selectedCountry) return;

      const mapData = await this.readMapData(selectedCountry);

      echarts.registerMap(selectedCountry, mapData);
    },

    beforeContextItemHighlight(args) {
      const value = args?.item?.value;

      if (value?.selectedField?.drillPaths?.length) {
        const selectedField = this.panelData?.details?.aggregation?.find(
          (x) => x.fieldId == value.activeFieldInAggregation.fieldId
        );

        if (selectedField)
          args.element.innerHTML = `<span class="vis-font-medium" style="margin-right:6px;">${args.item.text}</span> <i class="mdi mdi-check"></i>`;
      }

      if (args?.item?.title) {
        args.element.innerHTML = `<span class="vis-ellipsis-context-menu vis-font-medium">${args.item.text}</span>`;
      }
    },
  },
};
</script>
<style scoped>
#main {
  height: 400px;
  width: 600px;
}
.vis-chart-placeHolder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vis-chart-xAxis-name {
  margin-top: 5px;
  white-space: nowrap;
}
.vis-chart-yAxis-name-outer {
  padding-left: 15px;
  width: 0px;
}
.vis-chart-yAxis-name {
  transform: rotate(-90deg);
  transform-origin: bottom;
  white-space: nowrap;
}
.vis-isYaxisNameOnTop {
  white-space: nowrap;
}
::v-deep .vis-ellipsis-context-menu {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
