import { pageSizeValues } from "../commons/dashboardProperties"

export const DASHBOARD_GRID_DEFAULT_CONFIGURATIONS = {
    DASHBOARD_GRID_BACKGROUND_COLOR: "#f6f8f9",
    DASHBOARD_GRID_FILTER_PANE_POSITION: "top",
    DASHBOARD_GRID_FILTER_PANE_WIDTH: "220",
    DASHBOARD_GRID_FILTER_PANE_HEIGHT: "112",
    DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE: false,
    DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE: false,
    DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE: true,
    DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE: true,
    DASHBOARD_GRID_HAS_GRID_LINES: true,
    HAS_TABS: true,
    TABS_FONT_FAMILY: "Poppins, sans-serif",
    TABS_FONT_COLOR: "#2d2d2d",
    TABS_BACKGROUND_COLOR: "#fff",
    DASHBOARD_GRID_PAGE_SIZE: pageSizeValues.FIT_TO_SCREEN,
    DASHBOARD_GRID_WIDTH: window.innerWidth - 520,
    DASHBOARD_GRID_HEIGHT: window.innerHeight - 96, //(96)topbar ve settings bar yükseklik toplamı,
    DASHBOARD_GRID_PAPER_SIZE: "A4",
    DASHBOARD_GRID_SHOW_GUIDLINES: false
}

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}