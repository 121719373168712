<template>
  <div class="vis-row vis-mt--1">
    <div class="vis-col vis-col-6">
      <div class="vis-row">
        <div class="vis-col vis-col-6">
          <div class="vis-mb--2">
            <label>{{ $t("connection.labelName") }}</label>
            <el-input v-model="connectionForm.name" />
          </div>
          <div>
            <label>{{ $t("connection.labelType") }}</label>
            <el-select
              id="selectConnectionFormType"
              v-model="connectionForm.type"
              :placeholder="$t('connection.typePlaceholder')"
            >
              <el-option
                v-for="item in connectionTypes"
                :key="item.key"
                :label="item.label"
                :value="item.key"
                :id="item.key"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="vis-col vis-col-6">
          <label>{{ $t("connection.labelDescription") }}</label>
          <textarea
            class="vis-input"
            v-model="connectionForm.description"
          />
        </div>
      </div>

      <div class="vis-row vis-mt--2">
        <div class="vis-col vis-col-6">
          <div>
            <label>{{ $t("connection.labelHost") }}</label>
            <el-input v-model="connectionForm.host" />
          </div>
        </div>

        <div class="vis-col vis-col-6">
          <div>
            <label>{{ $t("connection.labelPort") }}</label>
            <el-input v-model="connectionForm.port" />
          </div>
        </div>

        <div v-if="!isTypeFTP && !isTypeSMB" class="vis-col vis-col-12 vis-mt--2">
          <div>
            <label>{{ $t("connection.labelDSNServiceName") }}</label>
            <el-input v-model="connectionForm.dns" />
          </div>
        </div>
      </div>

      <div class="vis-row vis-mt--2">
        <div class="vis-col vis-col-6">
          <div>
            <label>{{ $t("connection.labelUserName") }}</label>
            <el-input v-model="connectionForm.userName" />
          </div>
        </div>
        <div class="vis-col vis-col-6">
          <div>
            <label>{{ $t("connection.labelPassword") }}</label>
            <el-input
              v-model="connectionForm.password"
              :show-password="!connectionForm.connectionId"
              type="password"
            />
          </div>
        </div>
      </div>

      <div class="vis-row">
        <div class="vis-col">
          <div class="vis-row-box vis-mt--2 vis-text--right">
            <el-button
              v-loading="testConnectionLoading"
              @click="testConnection"
              size="small"
              :disabled="testConnectionLoading"
              :class="{ 'vis-cursor-notAllowed': testConnectionLoading }"
              >{{ $t("connection.testConnection") }}</el-button
            >
            <el-button
              v-loading="saveConnectionLoading"
              @click="saveConnection"
              type="primary"
              size="small"
              >{{ $t("connection.theSave") }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DB_TYPE, OTHER_CONNECTION_TYPES, SYBASE_ENUM } from "../../commons/connection.js";
import cloneDeep from "clone-deep";

export default {
  props: {
    connectionItem: {
      type: Object,
      default: () => null,
    },
    testConnectionLoading: {
      type: Boolean,
      default: false,
    },
    saveConnectionLoading: {
      type: Boolean,
      default: false,
    },
    connectionTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      connectionForm: {},
    };
  },

  mounted() {
    if (this.connectionItem && !this?.connectionItem?.isCustomURL) {
      this.connectionForm = cloneDeep(this.connectionItem);
    }
  },
  computed: {
    isTypeFTP() {
      return this.connectionForm.type && this.connectionForm.type === OTHER_CONNECTION_TYPES.FTP;
    },
    isTypeSMB() {
      return this.connectionForm.type && this.connectionForm.type === OTHER_CONNECTION_TYPES.SMB;
    },
    jdbcURL() {
      let url = "";
      if (
        this.connectionForm.type &&
        this.connectionForm.host &&
        this.connectionForm.port &&
        this.connectionForm.dns
      ) {
        url = `jdbc:${this.connectionForm.type.toLowerCase()}://${
          this.connectionForm.host
        }:${this.connectionForm.port}/${this.connectionForm.dns}`;
        if (this.connectionForm.type == DB_TYPE.MSSQL) {
          url = `jdbc:sqlserver://${this.connectionForm.host}:${this.connectionForm.port};databaseName=${this.connectionForm.dns}`;
        } else if (this.connectionForm.type == DB_TYPE.ORACLE) {
          url = `jdbc:${this.connectionForm.type.toLowerCase()}:thin:@${
            this.connectionForm.host
          }:${this.connectionForm.port}/${this.connectionForm.dns}`;
        } else if (this.connectionForm.type == DB_TYPE.SYBASE) {
          url = `jdbc:${this.connectionForm.type.toLowerCase()}:Tds:${
            this.connectionForm.host
          }:${this.connectionForm.port}?${this.connectionForm.dns}`;
        } else if (this.connectionForm.type == DB_TYPE.POSTGRESQL) {
          url = `jdbc:${this.connectionForm.type.toLowerCase()}://${
            this.connectionForm.host
          }:${this.connectionForm.port}/${this.connectionForm.dns}`;
        } else if (this.connectionForm.type == DB_TYPE.CLICKHOUSE) {
          url = `jdbc:${this.connectionForm.type.toLowerCase()}://${
            this.connectionForm.host
          }:${this.connectionForm.port}/${this.connectionForm.dns}`;
        } else if (
          this.connectionForm.type == DB_TYPE.SYBASE_ASE ||
          this.connectionForm.type == DB_TYPE.SYBASE_IQ
        ) {
          url = `jdbc:${SYBASE_ENUM.SYBASE_ASE}:${this.connectionForm.host}
          :${this.connectionForm.port}/${this.connectionForm.dns}`;
        }
      } else if (
        this.connectionForm.type &&
        this.connectionForm.host &&
        this.connectionForm.port &&
        (this.connectionForm.type == DB_TYPE.SYBASE_ASE ||
          this.connectionForm.type == DB_TYPE.SYBASE_IQ)
      ) {
        url = `jdbc:${SYBASE_ENUM.SYBASE_ASE}:${this.connectionForm.host}
          :${this.connectionForm.port}`;
      }
      return url;
    },
  },
  methods: {
    saveConnection() {
      this.connectionForm.isCustomURL = false;
      this.$emit("saveConnection", this.connectionForm, this.jdbcURL);
    },
    testConnection() {
      this.connectionForm.isCustomURL = false;
      this.$emit("testConnection", this.connectionForm, this.jdbcURL);
    },
  },
};
</script>
<style scoped>
textarea.vis-input {
  height: 112px !important;
}
</style>