import {
  fontSizesArr,
  fontWeightsArr,
  fontsArr,
  datatablePropertiesEnum,
  predefineColorsArr,
  alignItems,
  tableColumnFormats,
  fieldItemTypes
} from "../../commons/dashboardProperties";
import {
  datatableColumnsDefaultConfigurations,
  getPropertiesWithUndefinedCheck,
} from "../../mocks/datatableColumnsDefaultProperties";
import { chartPropertiesCss } from "./chartPropertiesCssEnum";

// column formatlama için olan properties mixini
export default {
  computed: {
    datatableColumnBasedFormattingMapping() {
      return {
        [datatablePropertiesEnum.CHART_DATA_TABLE_CONDITIONAL_FORMATTING_BUTTON]:
          {
            label: "table.conditionalFormatting",
            eventId: "openConditionalFormatting",
            property:
              datatablePropertiesEnum.CHART_DATA_TABLE_CONDITIONAL_FORMATTING_BUTTON,
            componentType: this.componentTypes.BUTTON,
          },
        [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY,
            this.collapse.CHART
          ),
          label: "panelProperties.Header Text Format",
          cssStyle: chartPropertiesCss.FONT_FAMILY,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_FAMILY
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY,
          componentType: this.componentTypes.SELECT_BOX,
          selectValues: fontsArr,
          flexClass: false,
          clearable: true,
        },

        [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_TEXT_WRAP]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_TEXT_WRAP,
            this.collapse.CHART
          ),
          label: "panelProperties.wrapText",
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_TEXT_WRAP
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_TEXT_WRAP
          ),
          componentType: this.componentTypes.SWITCH,
          property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_TEXT_WRAP,
          flexClass: true,
        },

        [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_TEXT_WRAP]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_ROW_TEXT_WRAP,
            this.collapse.CHART
          ),
          label: "panelProperties.wrapText",
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_ROW_TEXT_WRAP
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_TEXT_WRAP
          ),
          componentType: this.componentTypes.SWITCH,
          property: datatablePropertiesEnum.CHART_DATA_TABLE_ROW_TEXT_WRAP,
          flexClass: true,
        },
        [datatablePropertiesEnum.CHART_SELECT_ROW_ALIGN]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_SELECT_ROW_ALIGN,
            this.collapse.CHART
          ),
          label: "",
          cssStyle: chartPropertiesCss.TITLE_ALIGN_ITEM,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_SELECT_ROW_ALIGN
            ],
            datatableColumnsDefaultConfigurations.CHART_SELECT_ROW_ALIGN
          ),
          componentType: this.componentTypes.TEXT_ALIGNMENT_RADIO_BUTTON,
          selectValues: alignItems,
          property: datatablePropertiesEnum.CHART_SELECT_ROW_ALIGN,
          flexClass: false,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_BACKGROUND_COLOR]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_ROW_BACKGROUND_COLOR,
            this.collapse.CHART
          ),
          label: "panelProperties.cellFormat",
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_ROW_BACKGROUND_COLOR
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_BACKGROUND_COLOR
          ),
          property:
            datatablePropertiesEnum.CHART_DATA_TABLE_ROW_BACKGROUND_COLOR,
          size: "mini",
          predefineColors: predefineColorsArr,
          componentType: this.componentTypes.COLOR_PICKER,
          flexClass: true,
          clearable: true,
        },

        [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_NAME]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_NAME,
            this.collapse.CHART
          ),
          label: "generalPages.labelName",

          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_NAME
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_NAME
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_NAME,
          componentType: this.componentTypes.INPUT,
          flexClass: false,
          inputType: "text",
          readOnly: false,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_SCREENTIP]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_SCREENTIP,
            this.collapse.CHART
          ),
          label: "generalPages.screenTip",

          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_SCREENTIP
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_SCREENTIP
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_SCREENTIP,
          componentType: this.componentTypes.INPUT,
          flexClass: false,
          inputType: "text",
          readOnly: false,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_MAX_CHARACTER]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_ROW_MAX_CHARACTER,
            this.collapse.CHART
          ),
          label: "panelProperties.labelMaxCharacter",
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_ROW_MAX_CHARACTER
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_MAX_CHARACTER
          ),
          property:
            datatablePropertiesEnum.CHART_DATA_TABLE_ROW_MAX_CHARACTER,
          componentType: this.componentTypes.INPUT_WITH_ENTER,
          flexClass: false,
          inputType: "text",
          readOnly: false,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT,
            this.collapse.CHART
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_WEIGHT,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT,
          componentType: this.componentTypes.SELECT_BOX,
          selectValues: fontWeightsArr,
          flexClass: false,
          clearable: true,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE,
            this.collapse.CHART
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_SIZE,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_SIZE
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE,
          componentType: this.componentTypes.SELECT_BOX,
          selectValues: fontSizesArr,
          flexClass: false,
          clearable: true,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR,
            this.collapse.CHART
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_COLOR,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_COLOR
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR,
          size: "mini",
          predefineColors: predefineColorsArr,
          componentType: this.componentTypes.COLOR_PICKER,
          flexClass: true,
          clearable: true,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY,
            this.collapse.CHART
          ),
          label: "panelProperties.Cell Text Format",
          cssStyle: chartPropertiesCss.FONT_FAMILY,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_FONT_FAMILY
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY,
          componentType: this.componentTypes.SELECT_BOX,
          selectValues: fontsArr,
          flexClass: false,
          clearable: true,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT,
            this.collapse.CHART
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_WEIGHT,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_FONT_WEIGHT
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT,
          componentType: this.componentTypes.SELECT_BOX,
          selectValues: fontWeightsArr,
          flexClass: false,
          clearable: true,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE,
            this.collapse.CHART
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_SIZE,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_ROW_FONT_SIZE
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE,
          componentType: this.componentTypes.SELECT_BOX,
          selectValues: fontSizesArr,
          flexClass: false,
          clearable: true,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR,
            this.collapse.CHART
          ),
          label: "",
          cssStyle: chartPropertiesCss.FONT_COLOR,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_FONT_COLOR
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR,
          size: "mini",
          predefineColors: predefineColorsArr,
          componentType: this.componentTypes.COLOR_PICKER,
          flexClass: true,
          clearable: true,
        },
        [datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN,
            this.collapse.CHART
          ),
          label: "",
          cssStyle: chartPropertiesCss.TITLE_ALIGN_ITEM,
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN
            ],
            datatableColumnsDefaultConfigurations.CHART_SELECT_COLUMN_ALIGN
          ),
          componentType: this.componentTypes.TEXT_ALIGNMENT_RADIO_BUTTON,
          selectValues: alignItems,
          property: datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN,
          flexClass: false,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_WIDTH]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_WIDTH,
            this.collapse.CHART
          ),
          label: "panelProperties.cellWidth",
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_WIDTH
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_WIDTH
          ),
          property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_WIDTH,
          componentType: this.componentTypes.INPUT,
          flexClass: false,
          inputType: "text",
          readOnly: false,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FORMAT]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FORMAT,
            this.collapse.CHART
          ),
          label: "panelProperties.Show Value As",
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FORMAT
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FORMAT
          ),
          componentType: this.componentTypes.SELECT_BOX,
          selectValues: tableColumnFormats,
          property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FORMAT,
          flexClass: false,
        },
        [datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATION_FUNCTION]: {
          id: this.getPropertyId(
            datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATION_FUNCTION,
            this.collapse.CHART
          ),
          label: "generalPages.aggregationFunction",
          value: getPropertiesWithUndefinedCheck(
            this.selectedColumnBasedFormattingsProperties?.[
              datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATION_FUNCTION
            ],
            datatableColumnsDefaultConfigurations.CHART_DATA_TABLE_AGGREGATION_FUNCTION
          ),
          componentType: this.componentTypes.SELECT_BOX,
          selectValues: fieldItemTypes,
          property: datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATION_FUNCTION,
          flexClass: false,
        },
      };
    },
  },
};
