<template>
  <div class="properties-db-general-container">
    <div class="vis-mb--1">
      <label>{{ $t("generalPages.labelName") }}</label>
      <el-input v-model="generalForm.name"></el-input>
    </div>

    <div v-if="isGeneralFormChanged" class="vis-mb--1 update-button-container">
      <el-button
        @click="updateDatamodelName"
        size="small"
        class="update-button"
        >{{ $t("generalPages.update") }}</el-button
      >
    </div>

    <div>
      <div
        v-for="(option, index) in switchOptions"
        :key="option.key"
        class="vis-flex--spacebeetwen-Ycenter"
        :class="{ 'vis-mb--1': index !== switchOptions.length - 1 }"
      >
        <label>{{ option.label }}</label>
        <el-switch
          :value="option.value"
          :disabled="isDataModelLocked"
          @change="(value) => option.serviceFn({
            value, 
            key: option.key 
          })"
        >
        </el-switch>
      </div>
    </div>

    <input
      type="text"
      class="vis-input"
      :value="queryTimeoutValue"
      :disabled="!isQueryTimeoutActive"
      @keypress.enter="(e) => updateQueryTimeout(e.target.value)"
    />
  </div>
</template>

<script>
import { COMPONENT_TYPES } from "./../../../commons/Enum";
import {
  tableTypeOptions,
  datamodelPropertyKeys,
  getCommonDatamodelPropertyValueByKey,
} from "../../../commons/dataModelTypes";

export default {
  props: {
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
    isDataModelLocked: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedDataModel: {
      handler(val) {
        if (val) {
          this.setGeneralForm();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      componentTypes: COMPONENT_TYPES,
      tableTypeOptions: tableTypeOptions,
      generalForm: {
        name: "",
      },
      propertyKeys: datamodelPropertyKeys,
    };
  },
  async mounted() {
    this.setGeneralForm();
  },
  computed: {
    isGeneralFormChanged() {
      if (this.generalForm.name !== this.name) return true;
      return false;
    },
    name() {
      return this.selectedDataModel?.name ?? "";
    },
    queryTimeoutValue() {
      return this.selectedDataModel?.queryTimeout ?? "";
    },
    isQueryTimeoutActive() {
      return this.selectedDataModel?.queryTimeoutActive ?? false;
    },

    switchOptions() {
      return [
        {
          label: this.$t("Allow AI Actions"),
          key: datamodelPropertyKeys.ALLOW_AI_ACTIONS,
          value: this.getPropertiesValueByKey(datamodelPropertyKeys.ALLOW_AI_ACTIONS),
          serviceFn: (params)=>this.setDatamodelPropertiesValue(params),
        },
        {
          label: this.$t("generalPages.Keep Only One Dataset Expanded"),
          key: datamodelPropertyKeys.KEEP_ONE_DATASET_EXPANDED,
          value: this.getPropertiesValueByKey(datamodelPropertyKeys.KEEP_ONE_DATASET_EXPANDED),
          serviceFn: (params)=>this.setDatamodelPropertiesValue(params),
        },
        {
          label: this.$t("generalPages.Query Timeout"),
          key: datamodelPropertyKeys.IS_TIMEOUT_ACTIVE,
          value: this.isQueryTimeoutActive,
          serviceFn: (params)=> this.updateQueryStatus(params),
        },
      ];
    },
  },
  methods: {
    getPropertiesValueByKey(propertyKey) {
      return getCommonDatamodelPropertyValueByKey(
        this.selectedDataModel?.properties,
        propertyKey
      );
    },
    setDatamodelPropertiesValue(params) {
      this.$emit("setDatamodelPropertiesValue", params);
    },
    setGeneralForm() {
      this.generalForm = {
        name: this.name,
      };
    },
    updateDatamodelName() {
      const newName = this.generalForm.name;
      this.$emit("setDatamodelName", newName);
    },
    updateQueryTimeout(value) {
      this.$emit("setQueryTimeout", value);
    },
    updateQueryStatus(params) {
      this.$emit("setQueryStatus", params.value);
    }
  },
};
</script>

<style scoped>
.properties-db-general-container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 25px;
}
.update-button {
  background-color: rgb(32, 111, 255) !important;
  color: white !important;
  float: right;
}
.update-button-container {
  width: 100% !important;
  display: inline-block;
}
</style>
