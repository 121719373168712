import { DefaultValue } from "../commons/dashboardProperties"

export const SELECT_LIST_FILTER_DEFAULT_CONFIGURATIONS = {
    SELECT_LIST_FILTER_MANDATORY_SELECTION: false,
    SELECT_LIST_DIRECTION: "Vertical",
    SELECT_LIST_FILTER_ALLOW_MULTISELECT: false,
    SELECT_LIST_FILTER_SELECTION_ACTION: false,
    SELECT_LIST_FILTER_DEFAULT_SELECTION: DefaultValue.NONE,
    SELECT_LIST_VALUE_FONT_FAMILY: "Poppins, sans-serif",
    SELECT_LIST_VALUE_FONT_COLOR: "#000",
    SELECT_LIST_VALUE_FONT_WEIGHT: 600,
    SELECT_LIST_VALUE_FONT_SIZE: 12,
    SELECT_LIST_VALUE_ALIGN: "middle",
    SELECT_LIST_SHOW_VALUE_LABEL: true,
    SELECT_LIST_VALUE_LABEL_FONT_FAMILY: "Poppins, sans-serif",
    SELECT_LIST_FILTER_VALUE_LABEL_FONT_COLOR: "#000",
    SELECT_LIST_FILTER_VALUE_LABEL_BACKGROUND_COLOR: "#e3e3e3",
    SELECT_LIST_FILTER_VALUE_LABEL_FONT_WEIGHT: 500,
    SELECT_LIST_FILTER_VALUE_LABEL_FONT_SIZE: 12,
    SELECT_LIST_FILTER_UNIQUE_NAME: "",
    SELECT_LIST_FILTER_TAB_INDEX: 0,
    SELECT_LIST_FILTER_APPLY_TO_TABS: []
}

export const getPropertiesWithUndefinedCheck = (value, computedValue) => {
    return typeof value != "undefined" ? value : computedValue
}