<template>
  <div class="vis-display-flex vis-flex-direction--column">
    <AdministrationTopBar />
    <div class="vis-dataset--view administration-left-panel-and-body">
      <div class="vis-row vis-height-100">
        <div class="vis-height-100 administration-left-panel">
          <AdministrationLeftPanel
            :activeMenuItem="activeMenuItem"
            :menuItemTypes="menuItemTypes"
            @connectionClicked="
              administrationState = administrationStates.connectionView
            "
            @manageUsersClicked="
              administrationState = administrationStates.manageUsers
            "
            @environmentsClicked="
              administrationState = administrationStates.environments
            "
            @migrateContentClicked="
              administrationState =
                administrationStates.migrateContentSelectionTable
            "
            @settingsClicked="
              administrationState = administrationStates.settings
            "
            @schedulesClicked="
              administrationState = administrationStates.schedules
            "
          />
        </div>

        <div
          class="administration-right-panel vis-ma--none vis-pa--none vis-height-100"
        >
          <!-- breadcrumb for connection starts (TODO move to related file) -->
          <div
            v-if="hasConnectionBreadcrumb"
            class="breadcrumb-container vis-flex--spacebeetwen-Ycenter"
          >
            <el-breadcrumb
              separator=">"
              class="administration-top-bar-breadcrumb"
            >
              <el-breadcrumb-item>
                <span class="vis-font-medium vis-font-size-1">{{
                  $t("admin.menuItem.breadcrumbConnections")
                }}</span></el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-if="
                  administrationState === administrationStates.connectionCreate
                "
              >
                <span class="vis-font-medium vis-font-size-1">{{
                  $t("connection.breadcrumbNewConnection")
                }}</span></el-breadcrumb-item
              >
              <el-breadcrumb-item
                v-if="
                  administrationState === administrationStates.connectionEdit
                "
              >
                <span class="vis-font-medium vis-font-size-1">{{
                  $t("connection.breadcrumbEditConnection")
                }}</span></el-breadcrumb-item
              >
            </el-breadcrumb>
            <div class="vis-mr--2">
              <el-button
                id="createNewConnection"
                v-if="
                  administrationState === administrationStates.connectionView
                "
                @click="createNewConnection"
                class="vis-ml--1 vis-cursor-pointer el-button--with-icon"
                type="text"
              >
                <i aria-hidden="true" :class="customIcon.AddBox"></i
                >{{ $t("admin.menuItem.createConnection") }}</el-button
              >
              <i
                v-if="
                  administrationState === administrationStates.connectionEdit
                "
                aria-hidden="true"
                :class="customIcon.Close"
                class="vis-cursor-pointer vis-font-medium e-icons e-large"
                @click="
                  administrationState = administrationStates.connectionView
                "
              ></i>
            </div>
          </div>
          <!-- breadcrumb for connection ends -->

          <SelectDatabaseConnection
            v-if="
              administrationState === administrationStates.connectionView ||
              administrationState === administrationStates.connectionAccess
            "
            :hideSelectOptions="true"
            :currentUserRoles="currentUserRoles"
            @deleteClicked="deleteClicked"
            @editClicked="editClicked"
            @checkClicked="checkClicked"
          />
          <CreateAndEditConnection
            v-if="
              administrationState === administrationStates.connectionCreate ||
              administrationState === administrationStates.connectionEdit
            "
            class="vis-pt--2 create-and-edit-connection-wrapper"
            :connectionItem="connectionEditItem"
            :testConnectionLoading="isTestConnectionLoading"
            :saveConnectionLoading="isSaveConnectionLoading"
            :connectionTypes="connectionTypes"
            @testConnection="callTestConnection"
            @saveConnection="callSaveConnection"
          />
          <EnvironmentsList
            v-if="administrationState === administrationStates.environments"
          />

          <!-- migrate content -->
          <MigrateContentSelectionTable
            v-if="
              administrationState ===
              administrationStates.migrateContentSelectionTable
            "
            @onContinueClicked="onMigrateContentSelectionTableContinueClicked"
          />
          <MigrateContentSelectTargetEnvAndOptions
            v-if="
              administrationState ===
              administrationStates.migrateContentSelectTargetEnvAndOptions
            "
            :selectedObject="migrateContentSelectedObject"
            @onDoneClicked="migrateContentSelectTargetEnvAndOptionsDoneClicked"
          />
          <!-- migrate content -->
          <Settings
            v-if="administrationState === administrationStates.settings"
          />
          <Schedules
            v-if="administrationState === administrationStates.schedules"
          />
          <iframe
            v-if="administrationState === administrationStates.manageUsers"
            :src="keyCloackUrl"
            class="vis-keyCloackLoginİframe"
            title="Keycloack Login"
            name="keycloack"
          ></iframe>
        </div>
      </div>
    </div>
    <div
      v-if="administrationState === administrationStates.connectionAccess"
      class="vis-position-fixed vis-dashboard-custom-col administrationPropertiesContainer w300 vis-tools-box vis-ma--none vis-mobile-hidden vis-scroll-thin vis-height-100 vis-overflow-auto"
    >
      <AdministrationProperties
        :connection="connectionEditItem"
        @close="closeAdministrationProperties"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ACTION as ACTION_CONNECTIONS,
         GETTER as GETTER_CONNECTIONS } from "../store/modules/Visualize/Connections/types";
import { GETTER as GETTER_GENERAL } from "../store/modules/Visualize/General/types";
import SelectDatabaseConnection from "./../components/connection/SelectDatabaseConnection";
import AdministrationLeftPanel from "./../components/administration/AdministrationLeftPanel";
import AdministrationTopBar from "./../components/administration/AdministrationTopBar";
import CreateAndEditConnection from "./../components/connection/CreateAndEditConnection";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import AdministrationProperties from "../components/administration/AdministrationProperties.vue";
import EnvironmentsList from "../components/environments/EnvironmentsList.vue";
import MigrateContentSelectionTable from "../components/migrateContent/MigrateContentSelectionTable.vue";
import MigrateContentSelectTargetEnvAndOptions from "../components/migrateContent/MigrateContentSelectTargetEnvAndOptions.vue";
import Settings from "../components/settings/Settings.vue";
import Schedules from "../components/schedules/Schedules.vue";
import { CustomIcon } from "../assets/js/custom-icons";

export default {
  components: {
    SelectDatabaseConnection,
    AdministrationLeftPanel,
    AdministrationTopBar,
    CreateAndEditConnection,
    AdministrationProperties,
    EnvironmentsList,
    MigrateContentSelectionTable,
    MigrateContentSelectTargetEnvAndOptions,
    Settings,
    Schedules,
  },
  data() {
    return {
      administrationStates: {
        //connection
        connectionView: "connectionView",
        connectionEdit: "connectionEdit",
        connectionCreate: "connectionCreate",
        connectionAccess: "connectionAccess",
        //manageUsers
        manageUsers: "manageUsers",
        //environments
        environments: "environments",
        //migrateContent
        migrateContentSelectionTable: "migrateContentSelectionTable",
        migrateContentSelectTargetEnvAndOptions:
          "migrateContentSelectTargetEnvAndOptions",
        //settings
        settings: "settings",
        //schedules
        schedules: "schedules",
      },
      menuItemTypes: {
        system: "system",
        connection: "connection",
        manageUsers: "manageUsers",
        viewLogs: "viewLogs",
        environments: "environments",
        migrateContent: "migrateContent",
        settings: "settings",
        schedules: "schedules",
      },
      administrationState: null,
      connectionEditItem: null,
      showAdministrationPropertiesGeneral: true,
      showAdministrationPropertiesAccessPermissions: true,
      authorizationPropertiesItem: {},
      keyCloackUrl:
        process.env.VUE_APP_KEYCLOAK_ENDPOINT +
        "/realms/master/login-actions/authenticate?execution=f4dfa403-de54-4aad-b768-b1afb2db6ed1&client_id=security-admin-console&tab_id=XmLEAO40WLI",
      customIcon: CustomIcon,
      migrateContentSelectedObject: null,
    };
  },
  mounted() {
    this.administrationState = this.administrationStates.connectionView;
    this.init();
  },
  computed: {
    ...mapGetters({
      loading: GETTER_GENERAL.GET_LOADING,
      connectionTypes: GETTER_CONNECTIONS.GET_CONNECTION_TYPES,
    }),
    currentUserRoles() {
      return this.$store.getters["Security/Get/Keycloak/Roles"];
    },
    isTestConnectionLoading() {
      return this.loading[LoadingComponent.ConnectionTest];
    },
    hasConnectionBreadcrumb() {
      return this.activeMenuItem === this.menuItemTypes.connection;
    },
    isSaveConnectionLoading() {
      return this.loading[LoadingComponent.ConnectionSave];
    },
    activeMenuItem() {
      const stateToMenuItemMap = {
        [this.administrationStates.connectionView]:
          this.menuItemTypes.connection,
        [this.administrationStates.connectionEdit]:
          this.menuItemTypes.connection,
        [this.administrationStates.connectionCreate]:
          this.menuItemTypes.connection,
        [this.administrationStates.connectionAccess]:
          this.menuItemTypes.connection,
        [this.administrationStates.environments]:
          this.menuItemTypes.environments,
        [this.administrationStates.manageUsers]: this.menuItemTypes.manageUsers,
        [this.administrationStates.settings]: this.menuItemTypes.settings,
        [this.administrationStates.migrateContentSelectionTable]:
          this.menuItemTypes.migrateContent,
        [this.administrationStates.migrateContentSelectTargetEnvAndOptions]:
          this.menuItemTypes.migrateContent,
        [this.administrationStates.schedules]: this.menuItemTypes.schedules,
      };

      return stateToMenuItemMap[this.administrationState] || "";
    },
  },
  methods: {
    ...mapActions({
      testConnection: ACTION_CONNECTIONS.TEST_CONNECTION,
      updateConnection: ACTION_CONNECTIONS.UPDATE_CONNECTION,
      createConnection: ACTION_CONNECTIONS.CREATE_CONNECTION,
      fetchConnectionTypes: ACTION_CONNECTIONS.FETCH_CONNECTION_TYPES,

    }),
    migrateContentSelectTargetEnvAndOptionsDoneClicked() {
      this.administrationState =
        this.administrationStates.migrateContentSelectionTable;
      this.migrateContentSelectedObject = null;
    },
    onMigrateContentSelectionTableContinueClicked(selection) {
      this.administrationState =
        this.administrationStates.migrateContentSelectTargetEnvAndOptions;
      this.migrateContentSelectedObject = selection;
    },
    deleteClicked() {},
    editClicked(item) {
      this.connectionEditItem = item;
      this.administrationState = this.administrationStates.connectionEdit;
    },
    closeAdministrationProperties() {
      this.administrationState = this.administrationStates.connectionView;
      this.showAdministrationPropertiesGeneral = false;
      this.showAdministrationPropertiesAccessPermissions = false;
    },
    checkClicked(item) {
      this.closeAdministrationProperties();
      setTimeout(() => {
        this.connectionEditItem = item;
        this.administrationState = this.administrationStates.connectionAccess;
        this.showAdministrationPropertiesGeneral = true;
        this.showAdministrationPropertiesAccessPermissions = true;
      }, 300);
    },
    async init() {
      this.resetAuthorizationProperties();
      this.fetchConnectionTypes();
    },
    resetAuthorizationProperties() {
      this.showAdministrationPropertiesGeneral = false;
      this.showAdministrationPropertiesAccessPermissions = false;
      this.authorizationPropertiesItem = null;
    },
    createNewConnection() {
      this.connectionEditItem = null;
      this.administrationState = this.administrationStates.connectionCreate;
    },
    callTestConnection(params) {
      this.testConnection({
        loadingComponent: LoadingComponent.ConnectionTest,
        bodyParam: params,
      });
    },
    async callSaveConnection(params) {
      if (
        this.administrationState === this.administrationStates.connectionEdit
      ) {
        this.updateConnection({
          loadingComponent: LoadingComponent.ConnectionSave,
          bodyParam: {
            ...params,
            connectionId: this.connectionEditItem.connectionId,
          },
        });
      }
      if (
        this.administrationState === this.administrationStates.connectionCreate
      ) {
       const response = await this.createConnection({
          loadingComponent: LoadingComponent.ConnectionSave,
          bodyParam: params,
        });
        
        if (response?.connectionId) {
          this.administrationState = this.administrationStates.connectionView;
        }
      }
    },
  },
};
</script>

<style scoped>
.administration-left-panel-and-body {
  position: relative;
  width: 100%;
  height: calc(100% - 42px) !important;
  overflow: hidden;
}
.administration-left-panel {
  width: 220px;
}
.administration-right-panel {
  width: calc(100% - 220px);
}
.breadcrumb-container {
  background: var(--white);
  padding: 0px 20px;
  height: 40px
}
::v-deep .el-breadcrumb__inner {
  font-weight: 500 !important;
  color: #3e3e3e !important;
}
.administrationPropertiesContainer {
  top: 48px;
  right: 0;
}
.vis-keyCloackLoginİframe {
  position: relative;
  width: 100%;
  height: calc(100% - 6px);
  border: none;
}

::v-deep .select-database-connection-container-body {
  padding: 0 25px !important;
}
.create-and-edit-connection-wrapper {
  height: calc(100% - 85px);
  overflow: auto;
  background: white;
}
::v-deep .vgt-wrap {
  height: 100% !important;
}
::v-deep .vgt-inner-wrap {
  height: 100% !important;
}
::v-deep .vgt-responsive {
  height: 100% !important;
}
</style>
