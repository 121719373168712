export const serviceMethodParent = {
  Dashboard: "Dashboard",
  Search: "Search",
  DataModel: "DataModel",
  Folder: "Folder",
  TestQuery: "TestQuery",
  Connection: "Connection",
  Authorization: "Authorization",
  Dataset: "Dataset",
  FilterParams: "FilterParams",
  DashboardFields: "DashboardFields",
  ChartFields: "ChartFieldsF",
  ExportMetaData: "ExportMetaData",
  Favorites: "Favorites",
  MapData: "mapData",
  Hashing: "Hashing",
  ColorPalettes: "ColorPalettes",
  CalculatedColumn: "CalculatedColumn",
  ApiCall: "ApiCall",
  File: "File",
  User: "User",
  BulkDelete: "BulkDelete",
  ChatGpt: "ChatGpt",
  FieldGroup: "FieldGroup",
  Environments: "Environments",
  Configuration: "Configuration",
  MigrateContent: "MigrateContent",
  Scheduler: "Scheduler",
  TaskTypes: "TaskTypes",
  Templates: "Templates",
  FieldAuthorization: "FieldAuthorization",
  ObjectLock: "ObjectLock",

  //INGEST
  DataFlow: "DataFlow",
  // Scenario
  Scenario: "Scenario",
};

export const serviceMethodSub = {
  postFilePreview: "postFilePreview",
  postFileUpload: "postFileUpload",
  readDashboardList: "getDashboardList",
  readDashboardListByDatamodelId: "readDashboardListByDatamodelId",
  readDashboardById: "getDashboardById",
  createDashboard: "createDashboard",
  deleteDashboard: "deleteDashboard",
  updateDashboard: "updateDashboard",
  copyDashboard: "copyDashboard",
  publishDashboard: "publishDashboard",
  moveDashboard: "moveDashboard",
  readPublishedDashboardById: "getPublishedDashboardById",
  readSearch: "getSearch",
  readMainPageSearch: "getMainPageSearch",
  readDashboardSearch: "getDashboardSearch",
  createByDatamodelWizard: "createByDatamodelWizard",
  readDataModelList: "getDataModelList",
  createDatamodel: "createDatamodel",
  readDatamodelById: "getDatamodelById",
  copyDatamodel: "copyDatamodel",
  updateDatasetFieldsAlias: "updateDatasetFieldsAlias",
  readTestQuery: "postTestQuery",
  readTestPreview: "postTestPreview",
  readConnectionTypes: "readConnectionTypes",
  createConnection: "createConnection",
  updateConnection: "updateConnection",
  testConnection: "testConnection",
  readConnections: "getConnections",
  readConnectionsByDashboardId: "getConnectionsByDashboardId",
  deleteConnection: "deleteConnection",
  readAuthorization: "readAuthorization",
  readUsers: "readUsers",
  readGroups: "readGroups",
  readDatasetFieldsById: "getDatasetFieldsById",
  readDatasetFilterParamsById: "getDatasetFilterParamsById",
  readDatamodelFieldsById: "getDatamodelFieldsById",
  readDatamodelFilterParamsById: "getDatamodelFilterParamsById",
  readCheckDatasetUseById: "readCheckDatasetUseById",
  updateField: "putField",
  updateFieldsOrder: "putFieldsOrder",
  updateDataset: "putDataset",
  createDataset: "postDataset",
  deleteDataset: "deleteDataset",
  copyDataset: "copyDataset",
  sortAndRenameSettings: "sortAndRenameSettings",
  deleteCalculation: "deleteCalculation",
  readPropertyQuery: "getPropertyQuery",
  deleteDatamodel: "deleteDatamodel",
  updateDatamodel: "updateDatamodel",
  moveDatamodel: "moveDatamodel",
  duplicateDatamodelField: "duplicateDatamodelField",
  generateDatasetFieldsById: "generateDatasetFieldsById",
  createFilterParams: "postFilterParams",
  updateFilterParams: "putFilterParams",
  createFolder: "createFolder",
  deleteFolder: "deleteFolder",
  readFolderList: "getFolderList",
  readFolderById: "getFolderById",
  moveFolder: "moveFolder",
  readDatasetIds: "readDatasetIds",
  deleteDashboardCalculation: "deleteDashboardCalculation",
  readHighestPriorityByDashboardId: "readHighestPriorityByDashboardId",
  readHighestPriorityByDatamodelId: "readHighestPriorityByDatamodelId",
  readHighestPriorityByDataFlowId: "readHighestPriorityByDataFlowId",
  updateQueryTimeout: "updateQueryTimeout",
  updateQueryStatus: "updateQueryStatus",

  //DashboardFields
  createDashboardFields: "createDashboardFields",
  readDashboardFieldsById: "getDashboardFieldsById",
  updateDashboardFields: "updateDashboardFields",
  deleteDashboardFields: "deleteDashboardFields",
  duplicateDashboardDataModelField: "duplicateDashboardDataModelField",
  //ChartFields
  createChartFields: "createChartFields",
  readChartFieldsById: "readChartFieldsById",
  updateChartFields: "updateChartFields",
  deleteChartFields: "deleteChartFields",
  deleteFilterParams: "deleteFilterParams",
  //ExportMetaData
  readExportMetaDataRecords: "readExportMetaDataRecords",
  createExportMetaData: "createExportMetaData",
  readExportSharedUrl: "readExportSharedUrl",
  deleteExportMetaData: "deleteExportMetaData",
  createExportNotification: "createExportNotification",

  //authorization dashboard
  readDashboardAuthorizationById: "readDashboardAuthorizationById",
  createDashboardAuthorization: "createDashboardAuthorization",
  deleteDashboardAuthorizationById: "deleteDashboardAuthorizationById",
  updateDashboardAuthorizationById: "updateDashboardAuthorizationById",

  //authorization datamodel
  readDatamodelAuthorizationById: "readDatamodelAuthorizationById",
  createDatamodelAuthorization: "createDatamodelAuthorization",
  deleteDatamodelAuthorizationById: "deleteDatamodelAuthorizationById",
  updateDatamodelAuthorizationById: "updateDatamodelAuthorizationById",

  //authorization folder
  readFolderAuthorizationById: "readFolderAuthorizationById",
  readFolderAuthorizationTypes: "readFolderAuthorizationTypes",
  createFolderAuthorization: "createFolderAuthorization",
  updateFolderAuthorizationById: "updateFolderAuthorizationById",
  deleteFolderAuthorizationById: "deleteFolderAuthorizationById",
  updateFolderOwnerAuthorizationById: "updateFolderOwnerAuthorizationById",
  createFolderOwnerAuthorizationById: "createFolderOwnerAuthorizationById",
  updateDashboardParentAuthorizationById:
    "updateDashboardParentAuthorizationById",
  updateDatamodelParentAuthorizationById:
    "updateDatamodelParentAuthorizationById",
  updateFolderParentAuthorizationById: "updateFolderParentAuthorizationById",
  updateDataFlowParentAuthorizationById:
    "updateDataFlowParentAuthorizationById",

  //authorization dataflow
  readDataFlowAuthorizationById: "readDataFlowAuthorizationById",
  createDataFlowAuthorization: "createDataFlowAuthorization",
  deleteDataFlowAuthorizationById: "deleteDataFlowAuthorizationById",
  updateDataFlowAuthorizationById: "updateDataFlowAuthorizationById",

  //name and description
  updateDatamodelName: "updateDatamodelName",
  updateDatamodelProperties: "updateDatamodelProperties",
  updateDashboardName: "updateDashboardName",
  updateFolderName: "updateFolderName",

  //favorites
  updateFavorite: "updateFavorite",
  deleteFavoriteById: "deleteFavoriteById",
  readFavorites: "readFavorites",
  //map data
  readMapData: "readMapData",

  //Hashing
  readEncode: "readEncode",
  readDecode: "readDecode",

  //Color Palettes
  readColorPalettes: "readColorPalettes",
  createColorPalettes: "createColorPalettes",
  updateColorPalettes: "updateColorPalettes",
  deleteColorPalettes: "deleteColorPalettes",

  //calculated column
  createCalculatedColumn: "createCalculatedColumn",
  validateCalculatedColumn: "validateCalculatedColumn",
  updateCalculatedColumn: "updateCalculatedColumn",
  // Connection Authorization
  findByConnectionId: "findByConnectionId",
  saveConnectionAuthorization: "saveConnectionAuthorization",
  updateConnectionAuthorization: "updateConnectionAuthorization",
  deleteConnectionAuthorization: "deleteConnectionAuthorization",
  findAuthoritiesByUsername: "findAuthoritiesByUsername",

  //Api Call
  createApiCall: "createApiCall",

  //User
  readUserPreferences: "readUserPreferences",
  updateUserPreferences: "updateUserPreferences",

  //Bulk Delete
  bulkDeleteProjects: "bulkDeleteProjects",

  //DashboardFields
  saveDatasetJoin: "saveDatasetJoin",
  readDatasetJoin: "readDatasetJoin",
  deleteDatasetJoin: "deleteDatasetJoin",

  //ChatGpt
  readChatGptInsights: "readChatGptInsights",
  readChatGptSummary: "readChartGptSummary",
  readChatGptChatBot: "readChatGptChatBot",
  generateAI: "generateAI",
  //FieldGroup
  createFieldGroup: "createFieldGroup",
  updateFieldGroup: "updateFieldGroup",

  //environments
  readEnvironments: "readEnvironments",
  createEnvironment: "createEnvironment",
  deleteEnvironmentById: "deleteEnvironmentById",
  updateEnvironmentById: "updateEnvironmentById",

  //configurations
  readConfigurations: "readConfigurations",
  createConfigurations: "createConfigurations",
  updateConfigurations: "updateConfigurations",

  //migrate content
  readDashboardWithFolders: "readDashboardWithFolders",
  readConnectionsByEnvironment: "readConnectionsByEnvironment",
  moveObject: "moveObject",

  testSSE: "testSSE",

  //scheduler
  readSchedulersByDashboardId: "readSchedulersByDashboardId",
  createScheduler: "createScheduler",
  updateScheduler: "updateScheduler",
  deleteScheduler: "deleteScheduler",
  readTransactionsHistory: "readTransactionsHistory",

  //task-types
  readAllMonths: "readAllMonths",
  readAllDays: "readAllDays",
  readMonthlyCron: "readMontlyCron",
  readDailyCron: "readDailyCron",
  readTypesCron: "readTypesCron",
  readTimeRanges: "readTimeRanges",

  //Templates
  readTemplates: "readTemplates",
  createTemplate: "createTemplate",
  updateTemplate: "updateTemplate",
  deleteTemplate: "deleteTemplate",

  //Field Authorization
  readFieldAuthorizationByDatamodelId: "readFieldAuthorizationByDatamodelId",
  readFieldAuthorizationByDatasetId: "readFieldAuthorizationByDatasetId",
  createFieldAuthorization: "createFieldAuthorization",
  updateFieldAuthorization: "updateFieldAuthorization",
  deleteFieldAuthorization: "deleteFieldAuthorization",

  //INGEST
  readDataFlows: "readDataFlows",
  readDataFlowDetailsById: "readDataFlowDetailsById",
  updateDataFlowNameAndDescription: "updateDataFlowNameAndDescription",
  deleteDataFlowById: "deleteDataFlowById",
  readJobs: "readJobs",
  postRdbPreview: "postRdbPreview",
  postRdbSave: "postRdbSave",
  putRdbSave: "putRdbSave",
  postRdbRun: "postRdbRun",
  readRdbStatus: "readRdbStatus",
  moveDataFlow: "moveDataFlow",

  //Scenario
  readScenarioById: "readScenarioById",
  readScenarios: "readScenarios",
  readScenarioItems: "readScenarioItems",
  readEvents: "readEvents",
  readEnrichments: "readEnrichments",
  readActions: "readActions",
  updateScenario: "updateScenario",
  createScenario: "createScenario",
  createEvent: "createEvent",
  updateEvent: "updateEvent",
  readEventById: "readEventById",
  createEnrichment: "createEnrichment",
  updateEnrichment: "updateEnrichment",
  readEnrichmentById: "readEnrichmentById",
  testEnrichment: "testEnrichment",
  readDatasources: "readDatasources",
  createSimulate: "createSimulate",
  deployScenario: "deployScenario",
  undeployScenario: "undeployScenario",
  deleteScenario: "deleteScenario",
  deleteEvent: "deleteEvent",
  deleteEnrichment: "deleteEnrichment",

  //Object Lock
  createObjectLock: "createObjectLock",
  deleteObjectLock: "deleteObjectLock",
  readObjectLock: "readObjectLock",

  postRdbDataPreview: "postRdbDataPreview",
  postFileDataLocationTest: "postFileDataLocationTest",

  readSchemaByConnectionId: "readSchemaByConnectionId",
  readFieldsBySchemaAndTable: "readFieldsBySchemaAndTable",

  addDatasetsByDatamodelWizard: "addDatasetsByDatamodelWizard",
};
