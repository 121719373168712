
import {
    fontsArr,
    fontWeightsArr,
    fontSizesArr,
    chartTablePageSize,
    gridLinesArr,
    alignItems,
    datatablePropertiesEnum,
    autoRefreshTimes,
    tableSubTotalPositions,
    predefineColorsArr,
    tableColumnsInDrillThrough
} from "../../commons/dashboardProperties";
import { datatableDefaultConfigurations, getPropertiesWithUndefinedCheck } from "../../mocks/datatableDefaultProperties";
import { chartPropertiesCss } from "./chartPropertiesCssEnum";
import { mapActions } from "vuex";
import { ACTION as ACTION_SEARCH } from "../../store/modules/Visualize/Home/types";

export default {
    computed: {
        datatableComponentsMapping() {
            return {
                [datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.aggregateResults",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT
                    ], datatableDefaultConfigurations.datatableEnablePaging),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_AGGREGATE_RESULT,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_SELECT_PAGE_SIZE]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_SELECT_PAGE_SIZE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.rowsPerPage",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_SELECT_PAGE_SIZE
                    ], datatableDefaultConfigurations.datatablePageSize),
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: chartTablePageSize,
                    property: datatablePropertiesEnum.CHART_SELECT_PAGE_SIZE,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.columnAlign",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN
                    ], datatableDefaultConfigurations.datatableColumnAlign),
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: alignItems,
                    property: datatablePropertiesEnum.CHART_SELECT_COLUMN_ALIGN,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_HEIGHT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_HEIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.rowHeight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_HEIGHT
                    ], datatableDefaultConfigurations.datatableRowHeight),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ROW_HEIGHT,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PAGING]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PAGING,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.paging",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PAGING
                    ], datatableDefaultConfigurations.datatableEnablePaging),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PAGING,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SORTING]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SORTING,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowSorting",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SORTING
                    ], datatableDefaultConfigurations.datatableEnableSorting),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SORTING,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_FILTERING]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_FILTERING,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowFiltering",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_FILTERING
                    ], datatableDefaultConfigurations.datatableEnableFiltering),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_FILTERING,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SEARCH]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SEARCH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showSearchBar",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SEARCH
                    ], datatableDefaultConfigurations.datatableEnableSearch),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SEARCH,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXPORT_MENU]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXPORT_MENU,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.exportMenu",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXPORT_MENU
                    ], datatableDefaultConfigurations.datatableEnableExportMenu),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXPORT_MENU,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXCEL_EXPORT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXCEL_EXPORT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowExcelExport",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXCEL_EXPORT
                    ], datatableDefaultConfigurations.datatableEnableExcelExport),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_EXCEL_EXPORT,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CSV_EXPORT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CSV_EXPORT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowCSVExport",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CSV_EXPORT
                    ], datatableDefaultConfigurations.datatableEnableCSVExport),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CSV_EXPORT,
                    flexClass: true,
                },

                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PDF_EXPORT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PDF_EXPORT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowPDFExport",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PDF_EXPORT
                    ], datatableDefaultConfigurations.datatableEnablePDFExport),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_PDF_EXPORT,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_RESIZING]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_RESIZING,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowColumnResize",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_RESIZING
                    ], datatableDefaultConfigurations.datatableEnableResizing),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_RESIZING,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_REORDERING]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_REORDERING,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowColumnReorder",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_REORDERING
                    ], datatableDefaultConfigurations.datatableEnableReordering),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_REORDERING,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SELECTION]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SELECTION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.allowRowSelection",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SELECTION
                    ], datatableDefaultConfigurations.datatableEnableSelection),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SELECTION,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_TEXT_WRAP]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_TEXT_WRAP,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.wrapText",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_TEXT_WRAP
                    ], datatableDefaultConfigurations.datatableEnableTextWrap),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_TEXT_WRAP,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SHOW_INSIGHT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SHOW_INSIGHT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showInsight",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SHOW_INSIGHT
                    ], datatableDefaultConfigurations.datatableEnableShowInsight),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_SHOW_INSIGHT,
                    flexClass: true,
                    readOnly: !this.getShowInsightControl(process.env.VUE_APP_SHOW_INSIGHT),
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SQL]: {
                    id: this.getPropertyId(
                      datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SQL,
                      this.collapse.CHART
                    ),
                    label: "panelProperties.sqlStatement",
                    placeholder: "panelProperties.showSql",
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SQL
                      ],
                      datatableDefaultConfigurations.datatableEnableShowSql
                    ),
                    componentType: this.componentTypes.SHOW_BUTTON,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SQL,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CONTEXT_MENU]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CONTEXT_MENU,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showContextMenu",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CONTEXT_MENU
                    ], datatableDefaultConfigurations.datatableEnableContextMenu),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ENABLE_CONTEXT_MENU,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showTotal",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL
                    ], datatableDefaultConfigurations.datatableShowTotal),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.showSubtotal",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL
                    ], datatableDefaultConfigurations.datatableShowSubTotal),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_POSITION]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_POSITION,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.subtotalPosition",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_POSITION
                    ], datatableDefaultConfigurations.datatableSubTotalPosition),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_POSITION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: tableSubTotalPositions,
                    flexClass: false,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Header Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY
                    ], datatableDefaultConfigurations.datatableColumnFontFamily),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT
                    ], datatableDefaultConfigurations.datatableColumnFontWeight),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE
                    ], datatableDefaultConfigurations.datatableColumnFontSize),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Row Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY
                    ], datatableDefaultConfigurations.datatableRowFontFamily),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT
                    ], datatableDefaultConfigurations.datatableRowFontWeight),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE
                    ], datatableDefaultConfigurations.datatableRowFontSize),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ROW_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },


                [datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Total Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_FAMILY
                    ], datatableDefaultConfigurations.datatableTotalFontFamily),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_WEIGHT
                    ], datatableDefaultConfigurations.datatableTotalFontWeight),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_SIZE
                    ], datatableDefaultConfigurations.datatableTotalFontSize),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_COLOR
                    ], datatableDefaultConfigurations.datatableTotalFontColor),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_BACKGROUND_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.totalBackgroundColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_BACKGROUND_COLOR
                    ], datatableDefaultConfigurations.datatableTotalBackgroundColor),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_TOTAL_BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_TOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Sub Total Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_FAMILY
                    ], datatableDefaultConfigurations.datatableSubTotalFontFamily),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_WEIGHT
                    ], datatableDefaultConfigurations.datatableSubTotalFontWeight),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_SIZE]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_SIZE
                    ], datatableDefaultConfigurations.datatableSubTotalFontSize),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_COLOR
                    ], datatableDefaultConfigurations.datatableSubTotalFontColor),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_BACKGROUND_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.subtotalBackgroundColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_BACKGROUND_COLOR
                    ], datatableDefaultConfigurations.datatableSubTotalBackgroundColor),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SUBTOTAL_BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    readOnly: this.checkShowComponent(datatablePropertiesEnum.CHART_DATA_TABLE_SHOW_SUBTOTAL)
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_BACKGROUND_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.rowBackgroundColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_BACKGROUND_COLOR
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_BACKGROUND_COLOR),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_FONT_COLOR),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_BACKGROUND_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.columnBackgroundColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_COLUMN_FONT_COLOR),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_COLUMN_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_INDENT_CELL]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_INDENT_CELL,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.sectionIndentCell",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_INDENT_CELL
                    ], datatableDefaultConfigurations.datatableSectionIndentCell),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_INDENT_CELL,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_FAMILY,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Section Header Text Format",
                    cssStyle: chartPropertiesCss.FONT_FAMILY,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_FAMILY
                    ], datatableDefaultConfigurations.datatableSectionFontFamily),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_WEIGHT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_WEIGHT,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_WEIGHT_V2,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_WEIGHT
                    ], datatableDefaultConfigurations.datatableSectionFontWeight),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_WEIGHT,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontWeightsArr,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_SIZE]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_SIZE,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_SIZE,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_SIZE
                    ], datatableDefaultConfigurations.datatableSectionFontSize),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontSizesArr,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_BACKGROUND_COLOR,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.sectionBackgroundColor",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_BACKGROUND_COLOR
                    ], datatableDefaultConfigurations.datatableSectionBackgroundColor),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_COLOR]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_COLOR,
                        this.collapse.CHART
                    ),
                    label: "",
                    cssStyle: chartPropertiesCss.FONT_COLOR,
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_COLOR
                    ], datatableDefaultConfigurations.datatableSectionFontColor),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_SECTION_FONT_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_GRID_LINES]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_GRID_LINES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.gridLines",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_GRID_LINES
                    ], datatableDefaultConfigurations.datatableGridLines),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_GRID_LINES,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: gridLinesArr,
                    flexClass: false,
                },

                [datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.autoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH
                    ], datatableDefaultConfigurations.datatableAutoRefresh),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH,
                    selectValues: autoRefreshTimes,
                    componentType: this.componentTypes.SELECT_BOX,
                    flexClass: false,
                },

                [datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH_CUSTOM]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH_CUSTOM,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.autoRefresh.customAutoRefreshPeriod",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH_CUSTOM
                    ], datatableDefaultConfigurations.datatableAutoRefreshCustom),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_AUTO_REFRESH_CUSTOM,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },

                [datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE,
                        this.collapse.CHART
                    ),
                    label: this.getLabelByDynamicTitleShowValue(datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_VALUE,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.noSelectionContent",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_NO_SELECTION_CONTENT,
                    componentType: this.componentTypes.TEXTAREA,
                    flexClass: false,
                    readOnly: this.isNoContentSelectionTheReadOnly(datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE),
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.show",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_DYNAMIC_TITLE_SHOW_VALUE,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.show.dynamicTitleValue.group",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_GROUP_MULTIPLE_VALUES,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.minimumColumnWidth",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH),
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_MINIMUM_COLUMN_WIDTH,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                },
                // START DRILL THROUGH
                [datatablePropertiesEnum.CHART_DATA_TABLE_ALLOW_DRILL_THROUGH]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_ALLOW_DRILL_THROUGH,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Allow Drill Through",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_ALLOW_DRILL_THROUGH
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_ALLOW_DRILL_THROUGH),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_ALLOW_DRILL_THROUGH,
                    flexClass: true,
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_DASHBOARD_LIST]:
                {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_DASHBOARD_LIST,
                    this.collapse.CHART
                    ),
                    label: "",
                    value: getPropertiesWithUndefinedCheck(
                    this.componentStyle[
                        datatablePropertiesEnum
                        .CHART_DATA_TABLE_DRILL_THROUGH_DASHBOARD_LIST
                    ],
                    datatableDefaultConfigurations.CHART_DATA_TABLE_DRILL_THROUGH_DASHBOARD_LIST
                    ),
                    componentType: this.componentTypes.AUTO_COMPLETE_INPUT_WITH_TABLE,
                    tableColumns: tableColumnsInDrillThrough,
                    property:
                    datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_DASHBOARD_LIST,
                    flexClass: false,
                    queryParam: (value) => {
                        return `dashboardName=${value}&dataModelId=${this.datamodelId}`;
                      },
                    serviceFn: (params) => this.fetchDashboardSearch(params),
                    mapperFn: (content) => {
                        return content.map((m) => {
                          return {
                            id: m.id,
                            value: m.name,
                            nameSubtitle: m?.folderNames?.join(" / ") ?? "Home",
                          };
                        });
                      },
                      getAutoCompleteItem: (item) => {
                        return {
                          id: item.id,
                          name: item.value,
                          nameSubtitle: item?.nameSubtitle,
                        };
                      },
                },
                [datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK]: {
                    id: this.getPropertyId(
                        datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK,
                        this.collapse.CHART
                    ),
                    label: "panelProperties.Drill On Double Click",
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK
                    ], datatableDefaultConfigurations.CHART_DATA_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK),
                    componentType: this.componentTypes.SWITCH,
                    property: datatablePropertiesEnum.CHART_DATA_TABLE_DRILL_THROUGH_ON_DOUBLE_CLICK,
                    flexClass: true,
                },
                // END DRILL THROUGH
            }
        },
    },
    methods: {
        ...mapActions({
            fetchDashboardSearch: ACTION_SEARCH.FETCH_DASHBOARD_SEARCH,
          }),
        checkShowComponent(key) {
            return !this.componentStyle[key] && this.componentStyle[key] !== undefined
        },
    }
};
