<template>
  <el-popover
    placement="right-start"
    trigger="manual"
    popper-class="vis-dropdown-popper vis-custom-popper"
    :value="isAnyPopupVisible"
  >
    <FiltersPopup
      v-if="isFiltersPopupVisible"
      :filterType="filterPopupType"
      :selectedComponent="selectedFilterTabComponent"
      :filterDetailData="filterDetailData"
      :selectedPanel="selectedPanel"
      :attributeFilterSearchParam="attributeFilterSearchParam"
      @saveFilterByAttribute="saveFilterByAttribute"
      @saveFilterByDate="saveFilterByDate"
      @saveFilterByMeasure="saveFilterByMeasure"
      @setFilterTabComponent="setFilterTabComponent"
      @closeFilters="selectedFieldItem = $event"
      @changeFilterIconView="changeFilterIconView"
    />
    <div slot="reference">
      <PanelCommonsBox
        :boxList="selectedPanelFilters"
        :boxName="$t(boxName)"
        :contextMenuId="contextMenuId"
        :draggableList="searchinList.filterList"
        :panelDataBoxEmptyClass="panelDataBoxEmptyClass"
        :activeFieldBoxClass="activeFieldBoxClass"
        :contextMenuItems="contextMenuItems"
        :selectedDataModel="selectedDataModel"
        @changeDraggableList="filterSetList($event)"
        @openContextMenu="filterContext"
        @setSelectType="setFilterType"
      />
    </div>
  </el-popover>
</template>
<script>
import FiltersPopup from "../../components/panel/filters/FiltersPopup.vue";

import {
  filtersItem,
  DatamodelContextDefaults,
  getDeleteItemAction,
} from "../../commons/dataModelTypes";
import {
  filterObjectProperty,
  filterPopupTabValue,
  operator,
  dateOperator,
  objectsIncludesNull,
  objectsIncludesBetween,
  objectsIncludesIn,
} from "./filters/js/filters";
import cloneDeep from "clone-deep";
import PanelCommonsBox from "./PanelCommonsBox.vue";
import { getCommonsFieldProperty } from "../../util/panelSlotHelper";
export default {
  props: {
    selectedPanel: {
      type: Object,
    },
    searchinList: {
      type: Object,
    },
    isActiveFieldBox: {
      type: Boolean,
    },
    designMode: {
      type: Boolean,
    },
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    FiltersPopup,
    PanelCommonsBox,
  },
  data() {
    return {
      selectedFilterTabComponent: filterPopupTabValue.SELECTION,
      filterDetailData: {},
      selectedFieldItem: "",
      filterSelectedIndex: null,
      filterPopupType: "",
      filterPopupTabValue: filterPopupTabValue,
      filtersItem: filtersItem,
      contextMenuItems: [],
      contextMenuId: "filterMenu",
      boxName: "generalPages.filters",
    };
  },
  computed: {
    selectedPanelFilters() {
      return this.selectedPanel?.details?.filters;
    },
    activeFieldBoxClass() {
      return this.isActiveFieldBox && this.selectedPanelFilters?.length <= 0
        ? "vis-activeFieldBox"
        : "";
    },
    panelDataBoxEmptyClass() {
      return !this.selectedPanelFilters.length
        ? "vis-panel-data-card-empty"
        : null;
    },
    isAnyPopupVisible() {
      return this.isFiltersPopupVisible;
    },
    isFiltersPopupVisible() {
      return this.selectedFieldItem == this.filterDetailData.field;
    },
    attributeFilterSearchParam() {
      return {
        fieldList: this.selectedPanelFilters,
        dataset: this.selectedPanel?.properties?.dataSet,
      };
    },
  },
  methods: {
    filterContext(event, params) {
      this.filterDetailData = params.item;
      this.filterSelectedIndex = params.index;

      if (!params?.item?.isAuthorize) {
        this.contextMenuItems = [getDeleteItemAction()];
      } else {
        this.contextMenuItems = filtersItem();
      }

      document
        .getElementById(this.contextMenuId)
        .ej2_instances[0].open(event.y, event.x);
    },
    setFilterType(arg) {
      if (arg?.item?.value == DatamodelContextDefaults.FILTER_POPUP) {
        this.getFilterPopupData();
      } else if (arg?.item?.value == DatamodelContextDefaults.DELETE) {
        this.deleteFilters();
      }
    },
    getFilterPopupData() {
      const fieldUsageType = this.filterDetailData.fieldUsageType;

      this.filterDetailData.filterSelectedIndex = this.filterSelectedIndex;

      if (this.filterDetailData.popupTabName) {
        this.selectedFilterTabComponent = this.filterDetailData.popupTabName;
      } else {
        if (fieldUsageType == DatamodelContextDefaults.USAGE_TYPES.MEASURE)
          this.selectedFilterTabComponent = filterPopupTabValue.RULE;
        else this.selectedFilterTabComponent = filterPopupTabValue.SELECTION;
      }

      if (
        [
          DatamodelContextDefaults.USAGE_TYPES.MEASURE,
          DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE,
          DatamodelContextDefaults.USAGE_TYPES.DATE,
        ].includes(fieldUsageType)
      ) {
        this.filterPopupType = fieldUsageType;
      }

      this.selectedFieldItem = this.filterDetailData.field;
    },

    deleteFilters() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      selectedPanelCloned.details.filters.splice(this.filterSelectedIndex, 1);
      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },

    setFilterTabComponent(value) {
      this.selectedFilterTabComponent = value;
    },
    getSelectedField(selectedPanelCloned) {
      return selectedPanelCloned.details.filters.find(
        (x) => x.field == this.selectedFieldItem
      );
    },
    setSelectedField(param) {
      this.$set(
        param.selectedFieldCloned,
        filterObjectProperty.OPERATOR,
        param.operator
      );
      this.$set(
        param.selectedFieldCloned,
        filterObjectProperty.VALUE,
        param.filterValue
      );

      //Daha önceden yapılmış filtre hangi tab'da kaldıysa görebilmek için.
      this.$set(param.selectedFieldCloned, "popupTabName", param.popupTabName);
    },
    changeFilterIconView() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      const selectedFieldCloned = this.getSelectedField(selectedPanelCloned);

      let isActiveFilterIcon;

      if (selectedFieldCloned.isActiveFilterIcon) isActiveFilterIcon = false;
      else isActiveFilterIcon = true;

      this.$set(selectedFieldCloned, "isActiveFilterIcon", isActiveFilterIcon);

      this.updateSelectedPanel(selectedPanelCloned);
    },
    saveFilterByMeasure(popupTabName, param) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      const selectedFieldCloned = this.getSelectedField(selectedPanelCloned);
      selectedFieldCloned.filterId = selectedPanelCloned.i;

      let selectedFieldType =
        selectedPanelCloned.details.metrics.find(
          (x) => x.field == selectedFieldCloned.field
        )?.type ?? selectedFieldCloned.type;

      selectedFieldCloned.type = selectedFieldType;
      selectedFieldCloned.filterValues = param.measureRuleFilterData;
      selectedFieldCloned.isChecked = param.isCheckedLowestLevelRecords;

      if (popupTabName === filterPopupTabValue.RULE)
        this.setMeasureFilterRule(
          selectedFieldCloned,
          param.measureRuleFilterData,
          popupTabName
        );

      this.updateSelectedPanelByDesignMode(selectedPanelCloned);

      this.selectedFieldItem = "";
    },

    setMeasureFilterRule(selectedFieldCloned, filters, popupTabName) {
      let filterValues = [];

      filters.forEach((element) => {
        if (Object.values(objectsIncludesBetween).includes(element.operator)) {
          filterValues = [element.value1, element.value2];
        } else if (
          Object.values(objectsIncludesNull).includes(element.operator)
        ) {
          filterValues = [];
        } else {
          filterValues.push(element.value1);
        }

        this.setSelectedField({
          selectedFieldCloned,
          operator: element.operator,
          filterValue: filterValues,
          popupTabName,
        });
      });
    },

    saveFilterByDate(popupTabName, filterFormData) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      const selectedFieldCloned = this.getSelectedField(selectedPanelCloned);
      selectedFieldCloned.filterId = selectedPanelCloned.i;

      selectedFieldCloned.filterValues = filterFormData;

      if (popupTabName === filterPopupTabValue.SELECTION)
        this.setDateFilterSimple(
          selectedFieldCloned,
          filterFormData,
          popupTabName
        );
      else if (popupTabName === filterPopupTabValue.RELATIVE)
        this.setDateFilterRelative(
          selectedFieldCloned,
          filterFormData,
          popupTabName
        );

      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
      this.selectedFieldItem = "";
    },

    setDateFilterSimple(selectedFieldCloned, filterFormData, popupTabName) {
      const filterValue = [];

      if (
        filterFormData.operator == operator.EQ ||
        filterFormData.operator == dateOperator.AFTER
      ) {
        filterValue.push(filterFormData.startDate);
      } else if (filterFormData.operator == dateOperator.BEFORE) {
        filterValue.push(filterFormData.endDate);
      } else {
        filterValue.push(filterFormData.startDate);
        filterValue.push(filterFormData.endDate);
      }

      this.setSelectedField({
        selectedFieldCloned,
        operator: filterFormData.operator,
        filterValue,
        popupTabName,
      });
    },

    setDateFilterRelative(selectedFieldCloned, filterFormData, popupTabName) {
      const filterValue = [];

      filterValue.push(null); //Relative filtre tipinde value beklenmior fakat frontend tarafında kontrol sağlatmak için value null ekletiyoruz.

      this.setSelectedField({
        selectedFieldCloned,
        operator: operator.BETWEEN,
        filterValue,
        popupTabName,
      });
    },

    saveFilterByAttribute(popupTabName, filterFormData) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      const selectedFieldCloned = this.getSelectedField(selectedPanelCloned);
      selectedFieldCloned.filterId = selectedPanelCloned.i;

      if (popupTabName === filterPopupTabValue.SELECTION) {
        this.setAttrFilterSelection(
          selectedFieldCloned,
          filterFormData,
          popupTabName
        );
      }

      if (popupTabName === filterPopupTabValue.RULE) {
        this.setAttrFilterRule(
          selectedFieldCloned,
          filterFormData,
          popupTabName
        );
      }

      this.updateSelectedPanelByDesignMode(selectedPanelCloned);

      this.selectedFieldItem = "";
    },

    setAttrFilterSelection(selectedFieldCloned, filterFormData, popupTabName) {
      const filterValue = [];

      filterFormData.forEach((element) => {
        const value = element.VALUE ?? element.value;
        if (element.checked) filterValue.push(value);
      });

      if (filterValue.length) {
        this.setSelectedField({
          selectedFieldCloned,
          operator: operator.IN,
          filterValue,
          popupTabName,
        });
      } else {
        selectedFieldCloned.operator = undefined;
        selectedFieldCloned.value = undefined;
      }
    },

    setAttrFilterRule(selectedFieldCloned, filterFormData, popupTabName) {
      let filterValue = [];

      if (Object.values(objectsIncludesIn).includes(filterFormData.operator)) {
        filterValue = filterFormData.value;
      } else {
        filterValue.push(filterFormData.value);
      }

      this.setSelectedField({
        selectedFieldCloned,
        operator: filterFormData.operator,
        filterValue,
        popupTabName,
      });
    },

    resetFiltersList(value) {
      let field = {};
      let newField = {};
      field = value.added.element;
      let params = {
        newField,
        field,
      };
      return params;
    },
    filterSetList(value) {
      let params = this.resetFiltersList(value);

      params.newField = {
        ...getCommonsFieldProperty(params.field),
        type: params?.field?.defaultAggFunction || params?.field?.type,
        fieldUsageType: params?.field?.usageType || params?.field?.fieldUsageType,
        isActiveChartFilter: true, //For Front End
        isActiveFilterIcon: true, //For Front End
        isChecked: false, //For Front End
      };

      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      selectedPanelCloned.details.filters.push(params.newField);
      this.updateSelectedPanel(selectedPanelCloned);
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    updateSelectedPanelAndViewChart(value) {
      this.$emit("updateSelectedPanelAndViewChart", {
        value,
      });
    },
    updateSelectedPanelByDesignMode(selectedPanelCloned) {
      if (this.designMode) this.updateSelectedPanel(selectedPanelCloned);
      else this.updateSelectedPanelAndViewChart(selectedPanelCloned);
    },
  },
};
</script>
<style scoped></style>
