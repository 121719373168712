<template>
  <div>
    <div class="vis-mb--1 vis-ml--1">
      <span>{{ $t("schedule.labelSelectContentToExport") }}</span>
    </div>
    <div class="vis-row">
      <!-- chart name, chart type and switch -->
      <div class="vis-col vis-col-6 vis-schedule-content vis-scroll-thin">
        <!-- column headers -->
        <div class="vis-row">
          <div class="vis-col vis-col-12">
            <h4>{{ $t("schedule.Tab Name") }}</h4>
          </div>
          <div
            v-for="column in scheduleContentColumns"
            :key="column.label"
            class="vis-font-medium"
            :class="column.class"
          >
            {{ $t(`${column.label}`) }}
          </div>
        </div>
        <!-- column headers -->

        <div v-for="tab in scheduleTabs" :key="tab.id">
          <div id="schedule-content-header">
            <div class="vis-row">
              <div class="vis-col vis-col-8 vis-font-medium">
                {{ tab.name }}
              </div>
              <div class="vis-col vis-col-2 vis-text--center">
                <el-checkbox  :value="checkedContents[tab.id]" @change="(evt) => handleCheckAll(evt, tab.id, tab.charts)"></el-checkbox>
              </div>
            </div>
          </div>
          <div id="schedule-content-body" v-if="chartLengthCheck(tab)">
            <div
              class="vis-row"
              v-for="chart in getChartsNotInFilterPane(tab.charts)"
              :key="chart.id"
            >
              <div class="vis-col vis-col-5 vis-pl--2">
                <div class="vis-ellipsis">{{ chart.name || $t("generalPages.Untitled") }}</div>
              </div>
              <div class="vis-col vis-col-3">
                <span>{{ chartTypeName(chart.type) }}</span>
              </div>
              <div class="vis-col vis-col-2 vis-text--center">
                <el-checkbox-group
                  v-model="chartIds"
                  @change="(chartIds) => handleChange(chartIds, tab.id)"
                >
                  <el-checkbox :label="chart.id" :key="chart.id"></el-checkbox>
                </el-checkbox-group>
              </div>
              <div
                v-if="getChartTypePivotOrTable(chart.type)"
                class="vis-col vis-col-2 vis-text--center"
              >
                <el-checkbox
                  :value="getImageOnlyValue(chart.id)"
                  :disabled="isImageOnlyCheckBoxDisabled(chart.id)"
                  @change="
                    (value) => handleChangeImageOnlyValue(chart.id, value)
                  "
                ></el-checkbox>
              </div>
            </div>
          </div>
          <div class="vis-ml--2" v-else>
            <span>{{
              $t("schedule.This dashboard does not contain any item object")
            }}</span>
          </div>
        </div>
      </div>
      <!-- chart name, chart type and switch -->

      <!-- data items - RIGHT AREA -->
      <div class="vis-col vis-col-6 vis-schedule-content vis-scroll-thin">
        <div class="vis-row vis-mb--1" v-for="chart in selectedCharts" :key="chart.id">
          <div class="vis-col vis-col-12">
            <h4 class="vis-ellipsis">
              {{ $t("schedule.Preview") }}&nbsp;-&nbsp;{{
                chart.name || $t("generalPages.chart")
              }}
            </h4>
          </div>
          <div class="vis-col vis-col-6">
            <label class="vis-pl--1">{{ $t("schedule.labelDataItem") }}</label>
          </div>
          <div class="vis-col vis-col-6">
            <label class="">{{ $t("schedule.Usage") }}</label>
          </div>

          <div
            class="vis-col vis-col-12 vis-mb--none"
            v-for="fieldItem in getDetailsValue(chart)"
            :key="fieldItem.slotFieldId"
          >
            <div class="vis-row">
              <div class="vis-col vis-col-6 vis-mb--none">
                {{ fieldItem.field }}
              </div>
              <div class="vis-col vis-col-6 vis-mb--none">
                {{ fieldItem.fieldUsageType }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- data items -->
    </div>
  </div>
</template>
<script>
import cloneDeep from "clone-deep";
import { chartList, chartTypes, filterList, otherList, panelTypes } from "../../../commons/dashboardAndPanel";
import { messageDefaultValue } from "./schedule.js";
import { panelDataDetailsKey } from "../../../commons/filterComponents";
import { groupBy } from "lodash";
export default {
  props: {
    scheduleContentMessage: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedProject: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      checkedContents: {},
      chartIds: [],
      messageUpdated: messageDefaultValue,
      selectedCharts: [],
      ChartTypes: chartTypes,
      allChartList: [],
    };
  },
  mounted() {
    this.messageUpdated = cloneDeep(this.scheduleContentMessage);
    if (this.scheduleContentMessage?.chartData?.length) {
      this.chartIds = this.scheduleContentMessage.chartData.map(
        (m) => m.chartId
      );
      
      this.checkContentSelectedByTabId();
      this.addSelectedChartsToChartList(this.chartIds);
      this.setAllChartList();
    }
  },
  watch: {
    messageUpdated: {
      deep: true,
      handler() {
        this.$emit("onScheduleContentMessageUpdated", this.messageUpdated);
      },
    },
  },
  computed: {
    scheduleTabs() {
      return this.selectedProject.tabs;
    },
    hasTableOrPivotChart() {
      return this.allChartList?.some((x) =>
        this.getChartTypePivotOrTable(x?.type)
      );
    },
    scheduleContentColumns() {
      return [
        {
          label: "schedule.labelChartsName",
          class: "vis-pl--2 vis-col vis-col-5",
        },
        {
          label: "schedule.labelChartsType",
          class: "vis-col vis-col-3",
        },
        {
          label: "schedule.Include",
          class: "vis-text--center vis-col vis-col-2",
        },
        this.hasTableOrPivotChart
          ? {
              label: "schedule.Image",
              class: "vis-text--center vis-col vis-col-2",
            }
          : null,
      ].filter((x) => x);
    },
  },
  methods: {
    checkContentSelectedByTabId() {
      const selectedChartsByTabId = groupBy(this.scheduleContentMessage.chartData, (val) => val.tabId);

      this.scheduleTabs.forEach((tab) => {
        if (selectedChartsByTabId?.[tab.id]?.length === tab?.charts?.length) {
          this.$set(this.checkedContents, tab.id, true);
        } else {
          this.$set(this.checkedContents, tab.id, false);
        }
      });
    },
    setAllChartList() {
      this.scheduleTabs.forEach((tab) => {
        this.allChartList = [...this.allChartList, ...tab?.charts];
      });
    },
    getChartsNotInFilterPane(charts) {
      return charts?.filter((chart) => !chart.properties?.isFilterPaneChart);
    },
    getSelectedChartDataItemByChartId(chartId) {
      return this.messageUpdated.chartData.find((x) => x.chartId === chartId);
    },
    isImageOnlyCheckBoxDisabled(chartId) {
      return this.getSelectedChartDataItemByChartId(chartId) ? false : true;
    },
    handleChangeImageOnlyValue(chartId, value) {
      const selectedChartData = this.getSelectedChartDataItemByChartId(chartId);
      this.$set(selectedChartData, "isImageOnly", value);
    },
    getImageOnlyValue(chartId) {
      return (
        this.getSelectedChartDataItemByChartId(chartId)?.isImageOnly
      );
    },
    getChartTypePivotOrTable(chartType) {
      return chartType?.toLowerCase()?.includes(panelTypes.TABLE);
    },
    getDetailsValue(chart) {
      const metricsAndAggregations = [
        ...chart?.details?.metrics,
        ...chart?.details?.aggregation,
      ];

      if (!metricsAndAggregations?.length)
        return [{ field: "N/A", fieldUsageType: "N/A" }];

      if (this.getChartTypePivotOrTable(chart.type)) {
        return chart?.details?.columns;
      } else {
        return metricsAndAggregations.map((m) => {
          return { slotFieldId: m?.slotFieldId || m?.fieldId, ...m };
        });
      }
    },
    chartTypeName(chartType) {
      const allGraphicList = [...chartList, ...filterList, ...otherList()];

      return this.$t(allGraphicList?.find((x) => x.id === chartType)?.name);
    },
    chartLengthCheck(tab) {
      return this.tabChartsCheck(tab?.charts)?.length;
    },
    handleCheckAll(val, tabId, charts) {
      this.$set(this.checkedContents, tabId, val);

      const chartIds = charts.map((m) => m.id);

      this.chartIds = val ? [...this.chartIds, ...chartIds] : this.chartIds.filter(item => !chartIds.includes(item));

      this.handleChange([...new Set(this.chartIds)], tabId);
    },
    handleChange(chartIds, tabId) {
      if (!chartIds?.length) this.messageUpdated.chartData = [];
      
      const selectedTab = this.scheduleTabs?.find(x=> x.id === tabId);

      chartIds?.forEach((chartId) => {
        const selectedChart = selectedTab?.charts?.find(x=> x.id === chartId);
        
        if (!selectedChart) return;

        const filterListByDashboardFilter =
          selectedChart?.details?.filters?.filter(
            (x) => x.isActiveDashboardFilter
          ) ?? [];

          const filterParamListByDashboardFilter =
          selectedChart?.details?.params?.filter(
            (x) => x.isActiveDashboardFilter
          ) ?? [];
        
        const newChart = {
          chartId,
          chartType: selectedChart?.type,
          uiTabId: selectedChart?.properties?.uiTabId,
          //as tabId is needed in backend
          tabId: selectedChart.tabId,
          //as selectedTab?.order is needed in backend
          orderNumber: selectedTab?.order,
          [panelDataDetailsKey.FILTER]: filterListByDashboardFilter,
          [panelDataDetailsKey.PARAMS]: filterParamListByDashboardFilter,
        };
       
        const selectedChartData = this.getSelectedChartDataItemByChartId(chartId);
        
        if (!selectedChartData) {
          // Add when switch check
          this.messageUpdated?.chartData.push(newChart);
        }
      });

      const useChartData = () => {
        this.messageUpdated.chartData = this.messageUpdated.chartData.filter(
          (item) => {
            return chartIds.some((chartId) => chartId === item.chartId);
          }
        );
      };

      const makeEmptyFiltersAndParams = () => {
        this.messageUpdated.chartData.forEach((chartDataItem) => {
          chartDataItem[panelDataDetailsKey.FILTER] = chartDataItem?.filter ?? [];
          chartDataItem[panelDataDetailsKey.PARAMS] = chartDataItem?.params ?? [];
        });
      };
      
      useChartData();
      makeEmptyFiltersAndParams();
      delete this.messageUpdated?.mandatoryData;
      this.addSelectedChartsToChartList(chartIds);

      this.checkContentSelectedByTabId();
    },
    addSelectedChartsToChartList(chartIds) {
      this.selectedCharts = [];
      this.scheduleTabs.forEach((tab) =>
        tab.charts.forEach((chart) =>
          chartIds.forEach((chartId) =>
            chartId === chart.id ? this.selectedCharts.push(chart) : {}
          )
        )
      );
    },
    tabChartsCheck(charts) {
      const filteredCharts = charts.filter((chart) => {
        const chartTypeToLowerCase = chart?.type?.toLowerCase();
        return (
           chartTypeToLowerCase?.includes(panelTypes.CHART) ||
          this.getChartTypePivotOrTable(chartTypeToLowerCase)
        );
      });
      
      return filteredCharts;
    },
  },
};
</script>

<style scoped>
::v-deep .el-checkbox__label {
  display: none !important;
}

.vis-scroll-thin::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.vis-scroll-thin::-webkit-scrollbar-thumb {
  background-color: #e4e3e8 !important;
}
</style>
