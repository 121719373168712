export const domain = "VISUALIZE_DATASET";

export const STATE = {
  DATASET_FIELDS: `${domain}/DATASET_FIELDS`,
  DATASET_FILTER_PARAMS: `${domain}/DATASET_FILTER_PARAMS`,
  SELECTED_DATASET_FIELDS: `${domain}/SELECTED_DATASET_FIELDS`,
  SELECTED_DATASET_JOINS: `${domain}/SELECTED_DATASET_JOINS`,
  DATASET_IDS: `${domain}/DATASET_IDS`,
};

export const GETTER = {
  GET_DATASET_BY_ID_FIELDS: `${domain}/GET_DATASET_BY_ID_FIELDS`,
  GET_DATASET_BY_ID_FILTER_PARAMS: `${domain}/GET_DATASET_BY_ID_FILTER_PARAMS`,
  GET_SELECTED_DATASET_FIELDS: `${domain}/GET_SELECTED_DATASET_FIELDS`,
  GET_SELECTED_DATASET_JOINS: `${domain}/GET_SELECTED_DATASET_JOINS`,
  GET_DATASET_IDS: `${domain}/GET_DATASET_IDS`,
};

export const ACTION = {
  CREATE_FILTER_PARAMS: `${domain}/CREATE_FILTER_PARAMS`,
  UPDATE_FILTER_PARAMS: `${domain}/UPDATE_FILTER_PARAMS`,
  DELETE_FILTER_PARAMS: `${domain}/DELETE_FILTER_PARAMS`,
  GENERATE_DATASET_FIELDS_BY_ID: `${domain}/GENERATE_DATASET_FIELDS_BY_ID`,
  FETCH_DATASET_BY_ID_FIELDS: `${domain}/FETCH_DATASET_BY_ID_FIELDS`,
  FETCH_DATASET_BY_ID_FILTER_PARAMS: `${domain}/FETCH_DATASET_BY_ID_FILTER_PARAMS`,
  UPDATE_DATASET_BY_ID: `${domain}/UPDATE_DATASET_BY_ID`,
  CREATE_DATASET: `${domain}/CREATE_DATASET`,
  COPY_DATASET: `${domain}/COPY_DATASET`,
  UPDATE_FIELD: `${domain}/UPDATE_FIELD`,
  DELETE_DATASET: `${domain}/DELETE_DATASET`,
  UPDATE_FIELDS_ORDER: `${domain}/UPDATE_FIELDS_ORDER`,
  DELETE_CALCULATION: `${domain}/DELETE_CALCULATION`,

  FETCH_DATASET_JOIN: `${domain}/FETCH_DATASET_JOIN`,
  SAVE_DATASET_JOIN: `${domain}/SAVE_DATASET_JOIN`,
  UPDATE_DATASET_JOIN: `${domain}/UPDATE_DATASET_JOIN`,
  DELETE_DATASET_JOIN: `${domain}/DELETE_DATASET_JOIN`,

  FETCH_CHECK_DATASET_USE_BY_ID: `${domain}/FETCH_CHECK_DATASET_USE_BY_ID`,

  FETCH_DATASET_IDS: `${domain}/FETCH_DATASET_IDS`,
  UPDATE_DATASET_FIELDS_ALIAS: `${domain}/UPDATE_DATASET_FIELDS_ALIAS`,
  UPDATE_SORT_AND_RENAME_SETTINGS: `${domain}/UPDATE_SORT_AND_RENAME_SETTINGS`,
};

export const MUTATION = {
  SET_DATASET_FIELDS: `${domain}/SET_DATASET_FIELDS`,
  RESET_DATASET_FIELDS: `${domain}/RESET_DATASET_FIELDS`,
  SET_DATASET_FILTER_PARAMS: `${domain}/SET_DATASET_FILTER_PARAMS`,
  RESET_DATASET_FILTER_PARAMS: `${domain}/RESET_DATASET_FILTER_PARAMS`,
  ADD_SELECTED_FIELD: `${domain}/ADD_SELECTED_FIELD`,
  RESET_SELECTED_FIELDS: `${domain}/RESET_SELECTED_FIELDS`,
  SET_SELECTED_DATASET_JOINS: `${domain}/SET_SELECTED_DATASET_JOINS`,
  SET_DATASET_IDS: `${domain}/SET_DATASET_IDS`,
};
