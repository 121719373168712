
import {
    predefineColorsArr,
    dashboardGridPropertiesEnum,
    commonCardPropertiesEnum,
    fontsArr,
    tabsPropertiesEnum,
    pageSizePropertiesEnum,
    pageSizesArr,
    filterPanePositionArr,
    paperSizeOptions
} from "../../commons/dashboardProperties";
import { DASHBOARD_GRID_DEFAULT_CONFIGURATIONS, getPropertiesWithUndefinedCheck } from "../../mocks/dashboardGridDefaultProperties";

export default {
    computed: {
        dashboardGridMapping() {
            return {
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_BACKGROUND_COLOR,
                        this.collapse.GENERAL
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.general.backgroundColor"
                    ),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_BACKGROUND_COLOR
                    ], DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_BACKGROUND_COLOR),
                    property: dashboardGridPropertiesEnum.DASHBOARD_GRID_BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    hasBorder: true,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_DESIGN_MODE]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_DESIGN_MODE,
                        this.collapse.GENERAL
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.general.designMode"
                    ),
                    value:
                        this.componentStyle[
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_DESIGN_MODE
                        ],
                    property: dashboardGridPropertiesEnum.DASHBOARD_GRID_DESIGN_MODE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_HAS_GRID_LINES]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_HAS_GRID_LINES,
                        this.collapse.GENERAL
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.general.showGridLines"
                    ),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_HAS_GRID_LINES
                    ], DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_HAS_GRID_LINES),
                    property: dashboardGridPropertiesEnum.DASHBOARD_GRID_HAS_GRID_LINES,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE,
                        this.collapse.GENERAL
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.general.Allow Selection Pane In View Mode"
                    ),
                    value:
                    getPropertiesWithUndefinedCheck(this.componentStyle[
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE
                        ], DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE),
                    property: dashboardGridPropertiesEnum.DASHBOARD_GRID_SELECTION_PANE_VIEW_MODE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE,
                        this.collapse.GENERAL
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.general.Start With Selection Pane Visible"
                    ),
                    value:
                    getPropertiesWithUndefinedCheck(this.componentStyle[
                        dashboardGridPropertiesEnum
                            .DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE
                        ], DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE),
                    property:
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_SELECTION_PANE_VISIBLE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE,
                        this.collapse.GENERAL
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.general.Allow Filter Pane In View Mode"
                    ),
                    value:
                    getPropertiesWithUndefinedCheck(this.componentStyle[
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE
                        ], DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE),
                    property: dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_GRID_LAYOUT_VIEW_MODE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE,
                        this.collapse.GENERAL
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.general.Start With Filter Pane Visible"
                    ),
                    value:
                    getPropertiesWithUndefinedCheck(this.componentStyle[
                        dashboardGridPropertiesEnum
                            .DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE
                        ], DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE),
                    property:
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_START_WITH_FILTER_GRID_LAYOUT_VISIBLE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_POSITION]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_POSITION,
                        this.collapse.GENERAL
                    ),
                    label: this.$t("dashboardPropertiesLocale.general.filterPanePosition"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        dashboardGridPropertiesEnum
                            .DASHBOARD_GRID_FILTER_PANE_POSITION
                    ], DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_FILTER_PANE_POSITION),
                    property:
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_POSITION,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: filterPanePositionArr,
                    flexClass: false,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_WIDTH]: {
                    id: this.getPropertyId(
                      dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_WIDTH,
                      this.collapse.GENERAL
                    ),
                    label: this.$t("dashboardPropertiesLocale.general.Filter Pane Width"),
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_WIDTH
                      ],
                      DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_FILTER_PANE_WIDTH
                    ),
                    property:
                      dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_WIDTH,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                  },
                  [dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_HEIGHT]: {
                    id: this.getPropertyId(
                      dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_HEIGHT,
                      this.collapse.GENERAL
                    ),
                    label: this.$t("dashboardPropertiesLocale.general.Filter Pane Height"),
                    value: getPropertiesWithUndefinedCheck(
                      this.componentStyle[
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_HEIGHT
                      ],
                      DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_FILTER_PANE_HEIGHT
                    ),
                    property:
                      dashboardGridPropertiesEnum.DASHBOARD_GRID_FILTER_PANE_HEIGHT,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                  },
                //TEMPLATE
                [commonCardPropertiesEnum.SELECTED_TEMPLATE_ID]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.SELECTED_TEMPLATE_ID,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.defaultTemplate"
                    ),
                    value:
                        this.componentStyle[
                        commonCardPropertiesEnum.SELECTED_TEMPLATE_ID
                        ],
                    property: commonCardPropertiesEnum.SELECTED_TEMPLATE_ID,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: this.cardTemplates.map((d) => ({
                        value: d.id,
                        label: d.name,
                    })),
                    flexClass: false,
                    readOnly: false,
                },
                [commonCardPropertiesEnum.HAS_TITLE]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.HAS_TITLE,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.showTitle"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.HAS_TITLE
                        ],
                    property: commonCardPropertiesEnum.HAS_TITLE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                    readOnly: true,
                },
                [commonCardPropertiesEnum.TITLE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.TITLE_FONT_FAMILY,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.titleFontFamily"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.TITLE_FONT_FAMILY
                        ],
                    property: commonCardPropertiesEnum.TITLE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                    readOnly: true,
                },
                [commonCardPropertiesEnum.HAS_SUBTITLE]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.HAS_SUBTITLE,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.showSubTitle"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.HAS_SUBTITLE
                        ],
                    property: commonCardPropertiesEnum.HAS_SUBTITLE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                    readOnly: true,
                },
                [commonCardPropertiesEnum.DESCRIPTION_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.DESCRIPTION_FONT_FAMILY,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.subTitleFontFamily"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.DESCRIPTION_FONT_FAMILY
                        ],
                    property: commonCardPropertiesEnum.DESCRIPTION_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                    readOnly: true,
                },
                [commonCardPropertiesEnum.HAS_FOOTNOTE]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.HAS_FOOTNOTE,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.showFootNote"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.HAS_FOOTNOTE
                        ],
                    property: commonCardPropertiesEnum.HAS_FOOTNOTE,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                    readOnly: true,
                },
                [commonCardPropertiesEnum.FOOTNOTE_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.FOOTNOTE_FONT_FAMILY,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.Footnote Font Family"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.FOOTNOTE_FONT_FAMILY
                        ],
                    property: commonCardPropertiesEnum.FOOTNOTE_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                    readOnly: true,
                },
                [commonCardPropertiesEnum.HAS_BORDER]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.HAS_BORDER,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.showBorder"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.HAS_BORDER
                        ],
                    property: commonCardPropertiesEnum.HAS_BORDER,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                    readOnly: true,
                },
                [commonCardPropertiesEnum.BORDER_RADIUS]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.BORDER_RADIUS,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.borderRadius"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.BORDER_RADIUS
                        ],
                    property: commonCardPropertiesEnum.BORDER_RADIUS,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                    readOnly: true,
                },
                [commonCardPropertiesEnum.BORDER_COLOR]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.BORDER_COLOR,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.borderColor"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.BORDER_COLOR
                        ],
                    property: commonCardPropertiesEnum.BORDER_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    hasBorder:
                        this.componentStyle[commonCardPropertiesEnum.HAS_BORDER],
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    readOnly: true,
                },

                [commonCardPropertiesEnum.HAS_DROP_SHADOW]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.HAS_DROP_SHADOW,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.commonCard.dropShadow"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.HAS_DROP_SHADOW
                        ],
                    property: commonCardPropertiesEnum.HAS_DROP_SHADOW,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                    readOnly: true,
                },
                [commonCardPropertiesEnum.BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        commonCardPropertiesEnum.BACKGROUND_COLOR,
                        this.collapse.COMMON_CARD
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.general.backgroundColor"
                    ),
                    value:
                        this.selectedTemplateProperties[
                        commonCardPropertiesEnum.BACKGROUND_COLOR
                        ],
                    property: commonCardPropertiesEnum.BACKGROUND_COLOR,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    readOnly: true,
                },
                //TABS
                [tabsPropertiesEnum.HAS_TABS]: {
                    id: this.getPropertyId(
                        tabsPropertiesEnum.HAS_TABS,
                        this.collapse.TABS
                    ),
                    label: this.$t("dashboardPropertiesLocale.tabs.showTabs"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[tabsPropertiesEnum.HAS_TABS],
                        DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.HAS_TABS),
                    property: tabsPropertiesEnum.HAS_TABS,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
                [tabsPropertiesEnum.TABS_FONT_FAMILY]: {
                    id: this.getPropertyId(
                        tabsPropertiesEnum.TABS_FONT_FAMILY,
                        this.collapse.TABS
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.tabs.tabTitleFontFamily"
                    ),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[tabsPropertiesEnum.TABS_FONT_FAMILY],
                        DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.TABS_FONT_FAMILY),
                    property: tabsPropertiesEnum.TABS_FONT_FAMILY,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: fontsArr,
                    flexClass: false,
                },
                [tabsPropertiesEnum.TABS_FONT_COLOR]: {
                    id: this.getPropertyId(
                        tabsPropertiesEnum.TABS_FONT_COLOR,
                        this.collapse.TABS
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.tabs.tabTitleFontColor"
                    ),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[tabsPropertiesEnum.TABS_FONT_COLOR],
                        DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.TABS_FONT_COLOR),
                    property: tabsPropertiesEnum.TABS_FONT_COLOR,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                },
                [tabsPropertiesEnum.TABS_BACKGROUND_COLOR]: {
                    id: this.getPropertyId(
                        tabsPropertiesEnum.TABS_BACKGROUND_COLOR,
                        this.collapse.TABS
                    ),
                    label: this.$t(
                        "dashboardPropertiesLocale.tabs.tabTitleBackgroundColor"
                    ),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[
                        tabsPropertiesEnum.TABS_BACKGROUND_COLOR
                    ],
                        DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.TABS_BACKGROUND_COLOR),

                    property: tabsPropertiesEnum.TABS_BACKGROUND_COLOR,
                    componentType: this.componentTypes.COLOR_PICKER,
                    flexClass: true,
                    size: "mini",
                    predefineColors: predefineColorsArr,
                },
                //PAGE SIZE
                [pageSizePropertiesEnum.PAGE_SIZE]: {
                    id: this.getPropertyId(
                        pageSizePropertiesEnum.PAGE_SIZE,
                        this.collapse.PAGESIZE
                    ),
                    label: this.$t("dashboardPropertiesLocale.pageSize.size"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[pageSizePropertiesEnum.PAGE_SIZE],
                        DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_PAGE_SIZE),
                    property: pageSizePropertiesEnum.PAGE_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: pageSizesArr,
                    flexClass: false,
                },
                [pageSizePropertiesEnum.WIDTH]: {
                    id: this.getPropertyId(
                        pageSizePropertiesEnum.WIDTH,
                        this.collapse.PAGESIZE
                    ),
                    label: this.$t("dashboardPropertiesLocale.common.width"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[pageSizePropertiesEnum.WIDTH],
                        DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_WIDTH),
                    property: pageSizePropertiesEnum.WIDTH,
                    componentType: this.componentTypes.INPUT,
                    flexClass: false,
                    inputType: "text",
                    readOnly: true,
                },
                [pageSizePropertiesEnum.HEIGHT]: {
                    id: this.getPropertyId(
                        pageSizePropertiesEnum.HEIGHT,
                        this.collapse.PAGESIZE
                    ),
                    label: this.$t("dashboardPropertiesLocale.common.height"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[pageSizePropertiesEnum.HEIGHT],
                        DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_HEIGHT),
                    property: pageSizePropertiesEnum.HEIGHT,
                    componentType: this.componentTypes.INPUT_WITH_ENTER,
                    flexClass: false,
                    inputType: "text",
                    readOnly: false,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_PAPER_SIZE]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_PAPER_SIZE,
                        this.collapse.PDF_SETUP
                    ),
                    label: this.$t("dashboardPropertiesLocale.Paper Size"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[dashboardGridPropertiesEnum.DASHBOARD_GRID_PAPER_SIZE],
                        DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_PAPER_SIZE),
                    property: dashboardGridPropertiesEnum.DASHBOARD_GRID_PAPER_SIZE,
                    componentType: this.componentTypes.SELECT_BOX,
                    selectValues: paperSizeOptions,
                    flexClass: false,
                },
                [dashboardGridPropertiesEnum.DASHBOARD_GRID_SHOW_GUIDLINES]: {
                    id: this.getPropertyId(
                        dashboardGridPropertiesEnum.DASHBOARD_GRID_SHOW_GUIDLINES,
                        this.collapse.PDF_SETUP
                    ),
                    label: this.$t("dashboardPropertiesLocale.Show Guidlines"),
                    value: getPropertiesWithUndefinedCheck(this.componentStyle[dashboardGridPropertiesEnum.DASHBOARD_GRID_SHOW_GUIDLINES],
                        DASHBOARD_GRID_DEFAULT_CONFIGURATIONS.DASHBOARD_GRID_SHOW_GUIDLINES),
                    property: dashboardGridPropertiesEnum.DASHBOARD_GRID_SHOW_GUIDLINES,
                    componentType: this.componentTypes.SWITCH,
                    flexClass: true,
                },
            }
        },
    },
};
