<template>
  <div class="vis-panel-data-box">
    <label>{{ $t(boxName) }}</label>
    <div class="vis-panel-data-card" :class="panelDataBoxEmptyClass">
      <draggable
        class="list-group"
        :class="activeFieldBoxClass"
        :list="draggableColumns"
        group="people"
        @change="onDraggableChanged($event)"
      >
        <ul
          class="list-group-item"
          v-for="(item, index) in selectedPanelColumns"
          :key="item.slotFieldId"
        >
          <PanelCommonsBoxFieldItem
            :fieldItem="item"
            :fieldIndex="index"
            :isCommonUsage="false"
            :selectedDataModel="selectedDataModel"
            :isColumnBasedPropertiesSelected="isColumnBasedPropertiesSelected"
            :selectedColumnField="selectedColumnField"
            @openContextMenu="columnContext"
            @onSortMethodSwitched="onSortMethodSwitched($event, item)"
            @onItemClicked="openColumnBasedFormattingProperties($event)"
          />
          <el-popover
            placement="right"
            trigger="manual"
            popper-class="vis-dropdown-popper vis-custom-popper"
            :value="
              isAnyPopupVisible &&
              item.slotFieldId === selectedColumn.slotFieldId
            "
          >
            <FormatNumberPopup
              v-if="isFormatNumberPopupVisible"
              :selectedFormat="selectedFormat"
              :field="selectedPanelColumns[selectedColumnIndex].alias"
              @saveFormat="saveFormat"
              @closeFormat="closeFormatPopup"
            />
            <FormatDatePopup
              v-if="isFormatDatePopupVisible"
              :selectedFormat="selectedFormat"
              :field="selectedPanelColumns[selectedColumnIndex].alias"
              @saveFormat="saveFormat"
              @closeFormat="closeFormatPopup"
            />
            <FieldPopup
              v-if="
                isFieldPopupVisible &&
                item.slotFieldId === selectedColumn.slotFieldId
              "
              :selectedField="selectedColumn"
              :selectedItem="selectedContextMenuItem"
              @saveField="saveField"
              @close="isFieldPopupVisible = false"
            />
            <TopBottomPopup
              v-if="isTopBottomPopupVisible"
              :selectedField="selectedColumn"
              :allMeasureFieldsMapped="allMeasureFieldsMapped"
              @saveTopBottom="saveTopBottom"
              @closeTopBottomPopup="topBottomPopup = $event"
            />
            <div slot="reference">
              <ejs-contextmenu
                :id="contextMenuId"
                :items="contextMenuItems"
                :select="setColumn"
                :beforeItemRender="beforeItemRender"
              ></ejs-contextmenu>
            </div>
          </el-popover>
        </ul>
      </draggable>
    </div>
  </div>
</template>
<script>
import cloneDeep from "clone-deep";
import FormatNumberPopup from "./measures/FormatNumberPopup.vue";
import FormatDatePopup from "./measures/FormatDatePopup.vue";
import PanelCommonsBoxFieldItem from "./PanelCommonsBoxFieldItem.vue";
import FieldPopup from "../helper/FieldPopup.vue";
import {
  DatamodelContextDefaults,
  getDatamodelFieldContextMenu,
  ContextMenuViewType,
  contextMenuItemKeys,
  getDeleteItemAction,
  DatasetFieldTypes,
  checkMaxOrMinTypeIncludesByParam
} from "../../commons/dataModelTypes";
import { getHighlightFields } from "../../util/panelDataBoxes";
import draggable from "vuedraggable";
import deepEqual from "deep-equal";
import {
  getCommonsFieldColumnsAndSections,
  setSelectedPanelDetailAggregationAndMetrics,
} from "../../util/data-table/tableCommonsHelper";
import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";
import TopBottomPopup from "./bars/TopBottomPopup.vue";
import {
  getAllJoinedDatasetFields,
  getAllMeasureFields,
  getJoinedDatasetIdsByDatasetId,
} from "../../util/dataset-fields/datasetFields";

export default {
  components: {
    draggable,
    FormatNumberPopup,
    FormatDatePopup,
    PanelCommonsBoxFieldItem,
    FieldPopup,
    TopBottomPopup,
  },
  props: {
    selectedPanel: {
      type: Object,
    },
    isActiveFieldBox: {
      type: Boolean,
    },
    designMode: {
      type: Boolean,
    },
    selectedFieldsWithKeyCtrlShift: {
      type: Array,
      default: () => {
        return [];
      },
    },
    datasetFields: {
      type: Object,
      default: () => {
        return {};
      },
    },
    datasetIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
    isColumnBasedPropertiesSelected: {
      type: Boolean,
    },
    selectedColumnField: {
      type: Object
    },
  },
  watch: {
    "selectedPanel.details.columns": {
      handler() {
        if (
          !deepEqual(this.draggableColumns, this.selectedPanel.details.columns)
        ) {
          this.setDraggableColumns(this.selectedPanel.details.columns);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.setDraggableColumns(this.selectedPanel?.details?.columns);
  },
  data() {
    return {
      selectedColumn: {},
      selectedColumnIndex: null,
      FormatNumberPopup: "",
      measuresfirstValue: "",
      topBottomPopup: "",
      contextMenuId: "columnMenu",
      boxName: "generalPages.columns",
      contextMenuItems: [],
      isFieldPopupVisible: false,
      selectedContextMenuItem: null,
      draggableColumns: [],
      conditionalFormattingMenuActive: true,
    };
  },
  computed: {
    selectedFormat() {
      return (
        this.selectedPanelColumns[this.selectedColumnIndex]?.format ??
        this.selectedColumn?.format ??
        {}
      );
    },
    selectedPanelColumns() {
      return this.draggableColumns ?? [];
    },
    activeFieldBoxClass() {
      return this.isActiveFieldBox && !this.selectedPanelColumns.length
        ? "vis-activeFieldBox"
        : "";
    },
    panelDataBoxEmptyClass() {
      return !this.selectedPanelColumns.length
        ? "vis-panel-data-card-empty"
        : null;
    },
    isAnyPopupVisible() {
      return (
        this.isFormatNumberPopupVisible ||
        this.isFormatDatePopupVisible ||
        this.isFieldPopupVisible ||
        this.isTopBottomPopupVisible
      );
    },
    isFormatNumberPopupVisible() {
      return (
        this.FormatNumberPopup == this.selectedColumn?.slotFieldId &&
        this.selectedColumn?.fieldUsageType ==
          DatamodelContextDefaults.USAGE_TYPES.MEASURE &&
        !this.isFieldTypeDateAndTypeMaxOrMin
      );
    },
    isFormatDatePopupVisible() {
      return (
        this.FormatNumberPopup == this.selectedColumn?.slotFieldId &&
        (this.selectedColumn?.fieldUsageType ===
          DatamodelContextDefaults.USAGE_TYPES.DATE ||
          this.isFieldTypeDateAndTypeMaxOrMin)
      );
    },
    isFieldTypeDateAndTypeMaxOrMin() {
      return (
        this.selectedColumn.fieldType === DatasetFieldTypes.DATE &&
        checkMaxOrMinTypeIncludesByParam(this.selectedColumn.type)
      );
    },
    isTopBottomPopupVisible() {
      return (
        this.topBottomPopup === this.selectedColumn?.slotFieldId &&
        this.selectedColumn?.fieldUsageType ==
          DatamodelContextDefaults.USAGE_TYPES.ATTRIBUTE
      );
    },

    allMeasureFieldsMapped() {
      let joinedDatasetIds = getJoinedDatasetIdsByDatasetId(
        this.datasetIds,
        this.selectedColumn.datasetId
      );

      const allJoinedDatasetFields = getAllJoinedDatasetFields(
        this.datasetFields,
        joinedDatasetIds
      );

      return getAllMeasureFields(allJoinedDatasetFields);
    },
  },
  methods: {
    deleteColumn() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      this.deleteSectionsInFieldByColumnIndex(selectedPanelCloned);
      this.deleteConditionalFormattingByFieldId(
        selectedPanelCloned,
        this.getSelectedColumnFieldByColumnIndex().fieldId
      );

      selectedPanelCloned.details?.columns?.splice(this.selectedColumnIndex, 1);

      this.draggableColumns = selectedPanelCloned?.details?.columns;

      const updatedSelectedPanelCloned =
        setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);

      this.updateSelectedPanelByDesignMode(updatedSelectedPanelCloned);
    },
    //TODO ConditionalFormattingPopup.vue ile ortaklaştırılacak
    deleteConditionalFormattingByFieldId(selectedPanelCloned, fieldId) {
      selectedPanelCloned.properties.conditionalFormattings =
        selectedPanelCloned?.properties?.conditionalFormattings?.filter(
          (x) => x.fieldId != fieldId
        );
    },
    deleteSectionsInFieldByColumnIndex(selectedPanelCloned) {
      const selectedColumnField = this.getSelectedColumnFieldByColumnIndex();
      const selectedSectionField =
        this.getSelectedSectionFieldByColumnFieldId(selectedColumnField);

      if (!selectedSectionField) return;

      selectedPanelCloned.details.sections =
        selectedPanelCloned?.details?.sections.filter(
          (x) => x.fieldId !== selectedColumnField.fieldId
        );
    },
    getSelectedColumnFieldByColumnIndex() {
      return this.selectedPanel?.details?.columns?.find(
        (x, index) => index === this.selectedColumnIndex
      );
    },
    getSelectedSectionFieldByColumnFieldId(selectedColumnField) {
      return this.selectedPanel?.details?.sections?.find(
        (x) => x.fieldId === selectedColumnField.fieldId
      );
    },
    mappedField(fieldData, draggedAndDroppedFieldIndex, indexChangeMap) {
      const newField = getCommonsFieldColumnsAndSections({
        fieldData,
        boxKey: BOX_KEY_ENUM.COLUMN.KEY,
        draggedAndDroppedFieldIndex,
      });

      if (fieldData.slotFieldId && fieldData.slotFieldId !== newField.slotFieldId) {
        indexChangeMap.set(fieldData.slotFieldId, newField.slotFieldId);
      }

      return newField;
    },
    onDraggableChanged(event) {
      if (
        event?.removed ||
        event?.added?.element?.boxKey === BOX_KEY_ENUM.SECTION.KEY
      ) {
        this.setDraggableColumns(this.selectedPanel?.details?.columns);
        return;
      }
      if (event?.moved?.newIndex || event?.moved?.oldIndex) {
        this.handleElementMoved();
      } else {
        if (this.selectedFieldsWithKeyCtrlShift.length) {
          this.draggableColumns = this.draggableColumns.filter(
            (x) => x.fieldId !== event?.added?.element?.fieldId
          );
          this.addMultipleFieldInColumnList();
        } else {
          this.addFieldInColumnList(event);
        }
      }
    },
    handleElementMoved() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      selectedPanelCloned.details.columns = this.draggableColumns;

      const updatedSelectedPanelCloned =
        setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);

      this.updateSelectedPanelByDesignMode(updatedSelectedPanelCloned);
    },
    async addMultipleFieldInColumnList() {
      for (
        let index = 0;
        index < this.selectedFieldsWithKeyCtrlShift.length;
        index++
      ) {
        const element = this.selectedFieldsWithKeyCtrlShift[index];
        await this.addFieldInColumnList(element);
      }

      this.$emit("deleteSelectedFieldsWithKeyCtrlShift");
    },
    setDraggableColumns(columns) {
      this.draggableColumns = cloneDeep(columns);
    },
    async addFieldInColumnList(value) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      const draggedAndDroppedField = value?.added?.element ?? value;

      if (this.selectedFieldsWithKeyCtrlShift.length) {
        this.draggableColumns.push(draggedAndDroppedField);
      }

      let indexChangeMap = new Map();

      selectedPanelCloned.details.columns = cloneDeep(
        this.draggableColumns.map((c, index) => {
          return this.mappedField(c, index, indexChangeMap);
        })
      );

      if (indexChangeMap.size !== 0) {
        this.changeConditionalAndColumnBasedSlotFieldId(selectedPanelCloned, indexChangeMap);
      }

      this.changeSectionFieldSlotFieldIdByColumnField(selectedPanelCloned);

      this.setDraggableColumns(selectedPanelCloned.details.columns);

      const updatedSelectedPanelCloned =
        setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);

      this.updateSelectedPanelByDesignMode(updatedSelectedPanelCloned);
    },
    changeConditionalAndColumnBasedSlotFieldId(selectedPanelCloned, indexChangeMap) {
      selectedPanelCloned?.properties?.columnBasedFormattings?.forEach(columnBased => {
        let slotId = columnBased.slotFieldId.substring(columnBased.slotFieldId.lastIndexOf("_"));
            if (indexChangeMap.has(slotId)) {
              const replacedSlotFieldId = columnBased.slotFieldId.replace(slotId, indexChangeMap.get(slotId));
              columnBased.slotFieldId = replacedSlotFieldId;
            }
      });
      selectedPanelCloned?.properties?.conditionalFormattings?.forEach(element => {
        let slotId = element.slotFieldId.substring(element.slotFieldId.lastIndexOf("_"));
            if (indexChangeMap.has(slotId)) {
              const replacedSlotFieldId = element.slotFieldId.replace(slotId, indexChangeMap.get(slotId));
              element.slotFieldId = replacedSlotFieldId;
            }
          element?.conditions?.forEach(condition => {
              condition?.rules.forEach(rule => {
                let slotId = rule.dataItem.substring(rule.dataItem.lastIndexOf("_"));
                if (indexChangeMap.has(slotId)) {
                 const replacedDataItem = rule.dataItem.replace(slotId, indexChangeMap.get(slotId));
                 rule.dataItem = replacedDataItem;
                }
              });
          });
      });
    },
    changeSectionFieldSlotFieldIdByColumnField(selectedPanelCloned) {
      if (selectedPanelCloned.details?.sections?.length) {
        selectedPanelCloned.details.columns.forEach((element) => {
          let hasSectionField = selectedPanelCloned.details?.sections.find(
            (x) => x.field === element.field
          );
          if (hasSectionField) {
            setTimeout(() => {
              hasSectionField.slotFieldId = element.slotFieldId;
            }, 200);
            return;
          }
        });
      }
    },
    columnContext(event, params) {
      this.isFieldPopupVisible = false;
      this.selectedColumn = params?.item;
      this.selectedColumnIndex = params?.index;
      if (!params?.item?.isAuthorize) {
        this.contextMenuItems = [getDeleteItemAction()];
      } else {
        this.contextMenuItems = getDatamodelFieldContextMenu(
          params?.item?.isCalculated,
          params?.item?.fieldType,
          ContextMenuViewType.CHART,
          params?.item?.fieldUsageType,
          params?.item,
          {
            isShowButtonActive: false,
            isColumnBasedFormattingActive: true,
          }
        );
      }

      document
        .getElementById(this.contextMenuId)
        .ej2_instances[0].open(event?.y, event?.x);
    },
    openColumnBasedFormattingProperties(value) {
        this.$emit("onSelectColumnBasedPropertiesColumn", value);
    },
    setColumn(arg) {
      const key = arg?.item?.key;
      const value = arg?.item?.value;

      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      let selectedField = selectedPanelCloned.details.columns.find((i) =>
        this.selectedColumn?.slotFieldId
          ? i.slotFieldId === this.selectedColumn?.slotFieldId
          : i.fieldId === this.selectedColumn?.fieldId
      );

      const setAndOpenRename = () => {
        this.isFieldPopupVisible = true;
        this.selectedContextMenuItem = DatamodelContextDefaults.ALIAS;
      };
      const setAndOpenScreenTip = () => {
        this.isFieldPopupVisible = true;
        this.selectedContextMenuItem = DatamodelContextDefaults.SCREEN_TIP;
      };
      const setAndOpenDescription = () => {
        this.isFieldPopupVisible = true;
        this.selectedContextMenuItem = DatamodelContextDefaults.DESCRIPTION;
      };
      const setOrderBy = () => {
        const updatedSelectedPanelCloned =
          setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);

        this.setFieldOrder(selectedField, value, updatedSelectedPanelCloned);
      };
      const setUsageType = () => {
        selectedField.fieldUsageType = value;
        updateSelectedPanel();
      };
      const setFieldType = () => {
        const isValueMaxOrMin = checkMaxOrMinTypeIncludesByParam(value);
        const isSelectedFieldTypeMaxOrMin = checkMaxOrMinTypeIncludesByParam(selectedField.type);
        const isFieldTypeTextOrDateWithFormat = [DatasetFieldTypes.TEXT, DatasetFieldTypes.DATE].includes(selectedField?.fieldType) && selectedField?.format;
        
        if (
          (isValueMaxOrMin && !isSelectedFieldTypeMaxOrMin && isFieldTypeTextOrDateWithFormat) ||
          (!isValueMaxOrMin && isSelectedFieldTypeMaxOrMin && isFieldTypeTextOrDateWithFormat)
        ) {
          selectedField.format = null;
        }

        selectedField.type = value;
        updateSelectedPanel();
      };
      const updateSelectedPanel = () => {
        const updatedSelectedPanelCloned =
          setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);

        this.updateSelectedPanelByDesignMode(updatedSelectedPanelCloned);
      };

      const contextMenuObjects = {
        [DatamodelContextDefaults.FORMAT]: {
          serviceFn: () =>
            (this.FormatNumberPopup = this.selectedColumn.slotFieldId),
        },
        [DatamodelContextDefaults.BARS.TOP_BOTTOM_POPUP]: {
          serviceFn: () =>
            (this.topBottomPopup = this.selectedColumn.slotFieldId),
        },
        [DatamodelContextDefaults.DELETE]: {
          serviceFn: () => this.deleteColumn(),
        },
        [DatamodelContextDefaults.ALIAS]: {
          serviceFn: () => setAndOpenRename(),
        },
        [DatamodelContextDefaults.SCREEN_TIP]: {
          serviceFn: () => setAndOpenScreenTip(),
        },
        [DatamodelContextDefaults.DESCRIPTION]: {
          serviceFn: () => setAndOpenDescription(),
        },
        [DatamodelContextDefaults.COLUMN_BASED_FORMATTING]: {
          serviceFn: () => this.openColumnBasedFormattingProperties(value),
        },
        [contextMenuItemKeys.ORDER_BY]: {
          serviceFn: () => setOrderBy(),
        },
        [contextMenuItemKeys.USAGE_TYPES]: {
          serviceFn: () => setUsageType(),
        },
        [contextMenuItemKeys.AGG_FUNCTION]: {
          serviceFn: () => setFieldType(),
        },
      };

      contextMenuObjects[key]?.serviceFn();
    },

    saveField(value) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      let filteredColumns = cloneDeep(
        selectedPanelCloned.details.columns.filter((x) =>
          value.slotFieldId
            ? x.slotFieldId != value.slotFieldId
            : x.fieldId !== value.fieldId
        )
      );
      //Değişiklik yapılan field'ın index sırasına göre, value (field) objesini eklemek için.
      filteredColumns.splice(this.selectedColumnIndex, 0, value);

      selectedPanelCloned.details.columns = filteredColumns;

      const updatedSelectedPanelCloned =
        setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);

      this.updateSelectedPanelByDesignMode(updatedSelectedPanelCloned);
    },
    saveTopBottom(params) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      selectedPanelCloned.details.columns.find(
        (x) => x.slotFieldId === params.slotFieldId
      ).rank = params.rank;

      const updatedSelectedPanelCloned =
        setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);

      this.updateSelectedPanelByDesignMode(updatedSelectedPanelCloned);
    },
    saveFormat(params) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      //Column Based properties içinden Show Value As kullanıldığında true,
      //field içindeki formattan değişiklik yapıldığında ise false atarız
      params.columnBasedFeatureActive = false;

      selectedPanelCloned.details.columns[this.selectedColumnIndex].format =
        params;

      const updatedSelectedPanelCloned =
        setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);

      this.updateSelectedPanelAndViewChart(updatedSelectedPanelCloned);

      if (Object.keys(params)?.length) this.closeFormatPopup();
    },
    beforeItemRender(args) {
      getHighlightFields(args, this.selectedColumn);
    },

    closeFormatPopup(event) {
      this.FormatNumberPopup = event;
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    updateSelectedPanelAndViewChart(value) {
      this.$emit("updateSelectedPanelAndViewChart", {
        value,
      });
    },
    updateSelectedPanelByDesignMode(selectedPanelCloned) {
      if (this.designMode) this.updateSelectedPanel(selectedPanelCloned);
      else this.updateSelectedPanelAndViewChart(selectedPanelCloned);
    },

    setFieldOrder(field, orderValue, selectedPanel) {
      if (!field) return;

      field.orderType = orderValue;
      const panelParams = selectedPanel?.details;

      if (orderValue === null || orderValue === undefined) {
        panelParams.orders = panelParams?.orders?.filter(
          (orderField) => orderField.fieldId !== field.fieldId
        );
      } else {
        const foundField = panelParams?.orders?.find(
          (orderField) => orderField.fieldId === field.fieldId
        );

        if (!panelParams?.orders?.length) panelParams.orders = [];

        if (foundField) {
          if (foundField.orderType != field.orderType) {
            foundField.orderType = field.orderType;
          }
        } else {
          panelParams?.orders?.push(field);
        }
      }
      this.updateSelectedPanelByDesignMode(selectedPanel);
    },
    onSortMethodSwitched(sortMethod, field) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      const selectedField = selectedPanelCloned.details?.columns?.find(
        (i) => i.field === field.field
      );
      const updatedSelectedPanelCloned =
        setSelectedPanelDetailAggregationAndMetrics(selectedPanelCloned);
      this.setFieldOrder(selectedField, sortMethod, updatedSelectedPanelCloned);
    },
  },
};
</script>
<style scoped></style>
