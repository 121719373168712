<template>
  <div v-loading="isLoading">
    <el-row :gutter="20">
      <div :class="{ 'disabled-overlay': enterSqlStatementSwitch }"></div>
      <el-col :span="12">
        <div class="tables-views">
          <div
            class="table-views-search vis-flex--spacebeetwen-Ycenter vis-flex-direction--row"
          >
            <span class="table-views-title">{{
              $t("datamodel.Tables/Views")
            }}</span>
            <el-input
              :placeholder="$t('datamodel.Type to search')"
              :suffix-icon="CustomIcon.Search"
              v-model="search"
            >
            </el-input>
          </div>
          <el-table class="schema-header" :data="[]" size="mini">
            <template slot="empty"></template>
            <el-table-column property="name" :label="$t('generalPages.name')">
            </el-table-column>
            <el-table-column
              property="type"
              :label="$t('generalPages.labelType')"
              align="right"
            >
            </el-table-column>
          </el-table>
          <div class="schema-body">
            <div class="schema-body-wrapper vis-scroll-thin">
              <div v-for="schema in searchedSchemas" :key="schema.schemaName">
                <span
                  class="table-views-folder"
                  @click="toggleSchema(schema.schemaName)"
                >
                  <i
                    :class="{
                      [CustomIcon.FolderOpenOutline]: schema.isSchemaOpen,
                      [CustomIcon.FolderOutline]: !schema.isSchemaOpen,
                    }"
                  ></i
                  >{{ schema.schemaName }}</span
                >
                <ul v-if="schema.isSchemaOpen" class="tables-views-list">
                  <li
                    v-for="(table, index) in schema.tables"
                    :key="table.name"
                    class="li-table-container"
                    :class="getLiTableContainerClass(table.name, index, schema)"
                  >
                    <el-checkbox
                      :value="table.selected"
                      @change="
                        handleTableSelected(
                          schema.schemaName,
                          table.name,
                          $event
                        )
                      "
                    >
                    </el-checkbox>
                    <span
                      class="table-views-name-and-icon"
                      @click="
                        handleHighlightTable(
                          schema.schemaName,
                          table.name,
                          false
                        )
                      "
                      ><i
                        v-if="table.type === schemaItems.Table"
                        :class="CustomIcon.TableLarge"
                      ></i
                      ><i v-else :class="CustomIcon.TableHeadersEye"></i
                      >{{ table.name }}</span
                    >
                    <span class="table-views-type"
                      ><el-tag type="info" size="small">{{
                        table.type
                      }}</el-tag></span
                    >
                  </li>
                  <li
                    v-if="schema.tables.length >= moreCount && !schema.showMore"
                    class="li-table-container-show-more"
                    @click="schema.showMore = true"
                  >
                    <span class="show-more">More...</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="11">
        <div class="columns">
          <span class="columns-title">{{ $t("datamodel.columns") }}</span>
          <el-table
            v-if="highlightedTable"
            ref="columnsTable"
            :data="highlightedTable.columns"
            style="width: 100%"
            size="mini"
            max-height="300"
            @selection-change="
              handleSelectionChange(
                highlightedSchemaName,
                highlightedTable.name,
                $event
              )
            "
          >
            <el-table-column type="selection" width="45"> </el-table-column>
            <el-table-column
              property="columnName"
              :label="$t('generalPages.name')"
            >
            </el-table-column>
            <el-table-column
              property="columnType"
              :label="$t('generalPages.labelType')"
            >
              <template slot-scope="scope">
                <el-tag type="info" size="small">{{
                  scope.row.columnType
                }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>

    <div class="select-table-or-view-container-footer">
      <el-switch
        v-model="enterSqlStatementSwitch"
        :disabled="!doesSelectedConnectionHaveWizardFeature"
        class="enter-sql-statement-switch"
        :active-text="$t('datamodel.Enter sql statement')"
      >
      </el-switch>

      <button class="vis-btn medium back-button" @click="$emit('back')">
        {{ $t("generalPages.back") }}
      </button>
      <button class="vis-btn medium done-button" @click="done">
        {{ $t("generalPages.done") }}
      </button>
    </div>
  </div>
</template>
<script>
import { CustomIcon } from "../../../assets/js/custom-icons";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GETTER as GETTER_CONNECTIONS,
  ACTION as ACTION_CONNECTIONS,
  MUTATION as MUTATION_CONNECTIONS,
} from "../../../store/modules/Visualize/Connections/types";
import { GETTER as GETTER_GENERAL } from "../../../store/modules/Visualize/General/types";
import { LoadingComponent } from "../../../store/modules/Visualize/General/loadingComponentDefinitions";
import { Notify } from "../../../commons/helper.js";
import { notificationType } from "../../../commons/notificationTypes";
import { DB_TYPE, OTHER_CONNECTION_TYPES } from "../../../commons/connection";

export default {
  props: {
    selectedConnection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moreCount: 20,
      enterSqlStatementSwitch: false,
      CustomIcon: CustomIcon,
      search: "",
      highlightedTable: null,
      highlightedSchema: null,
      uiStructuredSchemas: [],
      selectedRowsBySchemaAndTable: {},
      schemaItems: {
        Table: "table",
        View: "view",
      },
    };
  },
  mounted() {
    this.fetchSchemaByConnectionId({
      connectionId: this.selectedConnection.connectionId,
      loadingComponent: LoadingComponent.DatasetWizard,
    });
  },
  beforeDestroy() {
    this.setSchemas([]);
  },
  watch: {
    doesSelectedConnectionHaveWizardFeature: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.enterSqlStatementSwitch = true;
        }
      },
    },
    schemas: {
      handler(val) {
        const convertSchemas = (inputSchemas) => {
          return inputSchemas.map((schema) => ({
            schemaName: schema.schemaName,
            showMore: false,
            isSchemaOpen: false,
            tables: schema.tables.map((table) => ({
              name: table.name,
              type: table.type,
              columns: [],
              selected: false,
            })),
          }));
        };

        this.uiStructuredSchemas = convertSchemas(val);
      },
    },
    highlightedTable: {
      deep: true,
      handler(val) {
        if (val) {
          setTimeout(() => {
            const selectedRows =
              this.selectedRowsBySchemaAndTable?.[this.highlightedSchemaName]?.[
                val.name
              ];

            selectedRows?.forEach((selectedRow) => {
              if (selectedRow) {
                const row = this.highlightedTable.columns.find(
                  (row) =>
                    row.columnName === selectedRow.columnName &&
                    row.columnType === selectedRow.columnType
                );

                if (row) {
                  this.$refs.columnsTable.toggleRowSelection(row, true);
                }
              }
            });
          }, 300);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      schemas: GETTER_CONNECTIONS.GET_SCHEMAS,
      loading: GETTER_GENERAL.GET_LOADING,
    }),
    doesSelectedConnectionHaveWizardFeature() {
      const type = this.selectedConnection?.type;
      const typesWithoutWizard = [
        DB_TYPE.SYBASE_ASE,
        DB_TYPE.SYBASE_IQ,
        DB_TYPE.ELASTICSEARCH,
        OTHER_CONNECTION_TYPES.FTP,
        OTHER_CONNECTION_TYPES.SMB,
      ];

      return !typesWithoutWizard.includes(type);
    },
    isLoading() {
      return this.loading[LoadingComponent.DatasetWizard];
    },
    searchedSchemas() {
      if (!this.search) {
        return this.uiStructuredSchemas;
      }

      return (
        this.uiStructuredSchemas
          .map((schema) => {
            const filteredTables = schema.tables.filter((table) =>
              table.name.toLowerCase().includes(this.search.toLowerCase())
            );

            // Return schema with filtered tables
            return {
              ...schema,
              tables: filteredTables,
              isSchemaOpen: true,
              showMore: false,
            };
          })
          // Filter out schemas with no matching tables
          .filter((schema) => schema.tables.length > 0)
      );
    },
  },
  methods: {
    ...mapActions({
      fetchFieldsBySchemaAndTable:
        ACTION_CONNECTIONS.FETCH_FIELDS_BY_SCHEMA_AND_TABLE,
      fetchSchemaByConnectionId:
        ACTION_CONNECTIONS.FETCH_SCHEMA_BY_CONNECTION_ID,
    }),
    ...mapMutations({
      setSchemas: MUTATION_CONNECTIONS.SET_SCHEMAS,
    }),
    getLiTableContainerClass(tableName, index, schema) {
      return {
        "li-table-container-active":
          this.highlightedTable && tableName === this.highlightedTable.name,
        "li-table-container-hidden":
          index >= this.moreCount && schema.showMore === false,
      };
    },
    done() {
      if (this.enterSqlStatementSwitch) {
        this.$emit("showSql");

        return;
      }

      if (Object.keys(this.selectedRowsBySchemaAndTable).length === 0) {
        Notify(
          this.$t(
            "notifyMessages.You have not chosen any columns from tables/views"
          ),
          notificationType.WARNING
        );

        return;
      }

      this.$emit("done", this.selectedRowsBySchemaAndTable);
    },
    handleSelectionChange(schemaName, tableName, value) {
      if (value.length) {
        // check the checkbox of the schema on left side
        this.uiStructuredSchemas
          .find((s) => s.schemaName === schemaName)
          .tables.find((t) => t.name === tableName).selected = true;
      }

      if (!this.selectedRowsBySchemaAndTable[schemaName]) {
        this.selectedRowsBySchemaAndTable[schemaName] = {};
      }

      if (!this.selectedRowsBySchemaAndTable[schemaName][tableName]) {
        this.selectedRowsBySchemaAndTable[schemaName][tableName] = [];
      }

      if (
        this.selectedRowsBySchemaAndTable?.[schemaName]?.[tableName]?.length &&
        value.length === 0
      ) {
        return;
      } else {
        this.selectedRowsBySchemaAndTable[schemaName][tableName] = value;
      }
    },
    async handleTableSelected(schemaName, tableName, value) {
      const foundTableInSchema = this.uiStructuredSchemas
        .find((s) => s.schemaName === schemaName)
        .tables.find((t) => t.name === tableName);

      foundTableInSchema.selected = value;

      if (value) {
        this.handleHighlightTable(schemaName, tableName, true);
      } else {
        this.highlightedTable = null;
        this.highlightedSchemaName = null;
        this.selectedRowsBySchemaAndTable[schemaName][tableName] = [];
        
        this.removeEmptyColumnArrays();
      }
    },
    // Function to remove empty arrays from top-level object keys
    removeEmptyColumnArrays() {
      Object.keys(this.selectedRowsBySchemaAndTable).forEach((key) => {
        Object.keys(this.selectedRowsBySchemaAndTable[key]).forEach((key2) => {
          if (this.selectedRowsBySchemaAndTable[key][key2].length === 0) {
            delete this.selectedRowsBySchemaAndTable[key][key2];
          }
        });
      });
    },
    toggleSchema(schemaName) {
      const schema = this.uiStructuredSchemas.find(
        (s) => s.schemaName === schemaName
      );

      schema.isSchemaOpen = !schema.isSchemaOpen;
    },
    async handleHighlightTable(schemaName, tableName, triggerSelectAll) {
      const result = await this.fetchFieldsBySchemaAndTable({
        connectionId: this.selectedConnection.connectionId,
        schema: schemaName,
        table: tableName,
        loadingComponent: LoadingComponent.DatasetWizard,
      });

      if (result) {
        const foundTableInSchema = this.uiStructuredSchemas
          .find((s) => s.schemaName === schemaName)
          .tables.find((t) => t.name === tableName);

        foundTableInSchema.columns = result;

        this.highlightedTable = foundTableInSchema;
        this.highlightedSchemaName = schemaName;

        if (triggerSelectAll) {
          setTimeout(() => {
            this.$refs.columnsTable.toggleAllSelection();
          }, 500);
        }
      }
    },
  },
};
</script>
<style scoped>
.table-views-search .el-input {
  width: 300px !important;
}
.table-views-title {
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
}
.columns-title {
  margin-bottom: 5px;
  margin-top: 6px;
  display: block;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #404040;
  opacity: 1;
}
.li-table-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  padding: 0px 4px;
}
.table-views-folder {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #333333;
  cursor: pointer;
}
.table-views-name-and-icon {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #404040;
  margin-left: 8px;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-views-type {
  margin-left: auto;
}
.schema-header ::v-deep .el-table__body-wrapper {
  display: none !important;
}
.schema-header {
  width: calc(100% - 60px) !important;
}
.schema-body {
  border-right: 1px solid #d8d8d8;
  padding-right: 15px;
}
.schema-body-wrapper {
  height: 250px;
  padding-right: 30px;
}
.li-table-container-active {
  background-color: #edf7ff;
}
.li-table-container-hidden {
  display: none;
}
.li-table-container-show-more {
  color: #3d62f8;
  cursor: pointer;
}
.select-table-or-view-container-footer {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: end;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.back-button {
  color: var(--primary-lighteen-1);
  font-weight: 600;
  border: none;
}
.disabled-overlay {
  position: fixed;
  top: 55px;
  bottom: 68px;
  left: 25px;
  right: 25px;
  background: rgb(0 0 0 / 34%);
  z-index: 1000;
  border-radius: 5px;
}
.show-more {
  margin-left: 54px;
}
.enter-sql-statement-switch {
  margin-right: auto;
}
.done-button {
  background: var(--primary-lighteen-1) 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: none;
  color: white;
}
</style>
