<template>
  <layout articleId="article" :showAside="isDataModelPropertiesToggled">
    <div slot="modals">
      <div v-if="newDataModelPopupVisible">
        <NewDataModelPopup
          :datamodelName="selectedDatamodel.name"
          :datamodelId="selectedDatamodel.id"
          :initialCurrentStep="newDataModelPopupInitialCurrentStep"
          :initialData="newDataModelPopupInitialData"
          @close="addNewDatasetPopupCurrentStep = addNewDatasetPopupSteps.NONE"
          @selectConnection="selectConnection"
          @redirectToDatamodel="redirectToDatamodel"
          @setNewDatasetsCoords="setNewDatasetsCoords"
          @handleCsvExcelUpload="handleCsvExcelUpload"
        />
      </div>

      <div v-if="changeDatasetConnectionPopupVisible">
        <ChangeDatasetConnectionPopup
          :connectionsNeedToBeChanged="connectionsNeedToBeChanged"
          :connections="connections"
          @exit="redirectToHome"
          @done="changeDatasetConnections"
        />
      </div>

      <div v-if="isDataModelTemporaryFiltersPopupVisible">
        <DataModelTemporaryFiltersPopup
          :updateLocalStorage="!sqlViewVisible"
          :parseToSql="sqlViewVisible"
          :items="dataModelTemporaryFiltersPopupItems"
          :sql="dataModelTemporaryFiltersPopupSql"
          :filterParams="filteredCustomDatasetFilterParams"
          @runClicked="handleTemporaryFiltersPopupRun"
          @close="isDataModelTemporaryFiltersPopupVisible = false"
        />
      </div>

      <div class="data-flow-linker-modal" v-if="connectionPopupVisible">
        <el-popover
          placement="top"
          width="750"
          v-model="connectionPopupVisible"
          trigger="manual"
          class="data-flow-linker-popover"
        >
          <div style="display: flex; align-items: center">
            <p class="vis-font-bold">Select join fields and joint type</p>
          </div>
          <div>
            <DataFlowLinker
              :dataModelCards="dataModelCards"
              :connectionPopupLinks="connectionPopupLinks"
              :datasetFields="datasetFields"
              @close="connectionPopupVisible = false"
              @closeAndRemoveLink="closeConnectionPopupAndRemoveLink"
              @add="callSaveDatasetJoin"
            />
          </div>
        </el-popover>
      </div>
      <ConfirmDialog
        :title="$t(confirmDialogTitle)"
        :message="$t(confirmDialogMessage)"
        :dialogVisible="dialogVisible"
        @confirm="preApproval"
        @cancel="cancelConfirmDialog"
      />
    </div>
    <div slot="header">
      <DataModelTopBar
        :datamodelName="selectedDatamodel.name"
        :showDatamodelActions="!sqlViewVisible"
        :showDatamodelAddDatasetAction="
          !sqlViewVisible && doesUserHaveEditRoles && doesUserHaveEditAuthority
        "
        :isSaveAvailable="isDatamodelEditAvailable"
        :isDataModelPropertiesToggled="isDataModelPropertiesToggled"
        :isDataModelLocked="isDataModelLocked"
        :datamodelLockingInfo="datamodelLockingInfo"
        :currentUserUsername="currentUserUsername"
        @addNewDataset="handleAddNewDataset()"
        @setDatamodelName="setDatamodelName"
        @openProjectNamePopup="openProjectNamePopup"
        @isDataModelPropertiesVisible="isDataModelPropertiesVisible"
        @lockDatamodel="lockDatamodel"
      />
    </div>

    <div slot="aside">
      <div v-if="isDataModelPropertiesToggled">
        <DataModelRightPropertiesPanelCalculatedColumn
          v-if="calculatedColumnCreateVisible"
          :connectionType="calculatedColumnConnectionType"
          @addFunctionToCalculatedColumnEditor="setTextToBeAddedToSql"
          @calculatedColumnFunctionName="setCalculatedColumnFunctionName"
        />

        <DataModelColumnBasedProperties
          v-else-if="columnBasedProperties.openCollapse"
          :authorizationPropertiesItem="columnBasedProperties.data"
          :showGeneral="
            columnBasedProperties.openCollapse ===
            columnBasedPropertiesCollapses.Properties
          "
          :showAccessPermissions="
            columnBasedProperties.openCollapse ===
            columnBasedPropertiesCollapses.Security
          "
          @getDatamodelFieldsAndFilters="getDatamodelFieldsAndFilters"
          @close="closeColumnBasedProperties"
        />

        <DataModelRightPropertiesPanel
          v-else
          :type="rightPropertiesType"
          :connectionName="selectedConnectionInName"
          :selectedDataset="createOrSelectDatamodelPayloadDatasets"
          :sqlViewVisible="sqlViewVisible"
          :connectionLength="selectedConnectionLength"
          :selectedDatasetFieldsLength="selectedDatasetFieldsLength"
          :isShowDataModelProperties="isShowDataModelProperties"
          :selectedDataModel="selectedDatamodel"
          :isDataModelLocked="isDataModelLocked"
          @setDatamodelName="setDatamodelName"
          @setDatamodelPropertiesValue="setDatamodelPropertiesValue"
          @dbGeneralPropertyChanged="handleDBGeneralPropertyChanged"
          @setQueryTimeout="setQueryTimeout"
          @setQueryStatus="setQueryStatus"
        />
      </div>
    </div>

    <div slot="nav" class="vbox h-100">
      <DataModelLeftPanel
        v-if="!sqlViewVisible"
        ref="DataModelLeftPanel"
        :selectedDataModel="selectedDatamodel"
        :fields="datasetFields"
        :attributeFilterSearchParam="attributeFilterSearchParam"
        :isPageDashboard="isPageDashboard"
        :isBeautifyLoading="isDatamodelFieldsBeautifyLoading"
        :isOneDatasetExpandedActive="isOneDatasetExpandedActive"
        @saveField="saveField"
        @openCreateCalculatedColumn="openCreateCalculatedColumn"
        @openEditCalculatedColumn="openEditCalculatedColumn"
        @openDeleteCalculatedColumn="openDeleteCalculatedColumn"
        @addFunctionToCalculatedColumnEditor="
          (t) =>
            isCalculatedColumnFormulaTabOpen
              ? setTextToBeAddedToSql(`[${t}]`)
              : null
        "
        @getSelectedField="getSelectedField"
        @getFieldListById="fetchDatamodelFieldsById(selectedDatamodel.id)"
        @duplicateFieldById="duplicateFieldById"
        @openColumnSecurity="openColumnSecurity"
        @openColumnProperties="openColumnProperties"
        @updateDatasetFieldsAlias="updateDatasetFieldsAlias"
        @updateSortAndRenameSettings="updateSortAndRenameSettings"
        @viewData="callRunSqlWithSelectedDataset"
        @showOnDiagram="callShowOnDiagram"
      />
      <DataModelSqlLeftPanel
        v-else-if="sqlViewVisible"
        :isSecurityFiltersLoading="isSecurityFiltersLoading"
        :isCustomFiltersLoading="isCustomFiltersLoading"
        :securityFilters="securityFilters"
        :customFilters="tempCustomFilterParams"
        :datasetId="selectedCardItem"
        @saveCustomFilterParams="(val) => (tempCustomFilterParams = val)"
        @updateCustomFilterParams="(val) => (tempCustomFilterParams = val)"
        @deleteCustomFilterParams="addToDeleteCustomFilterParamsArray"
        @filterClicked="setTextToBeAddedToSql"
      />
    </div>
    <div
      slot="article"
      class="vis-height-100"
      :class="isShowEmptyState ? 'data-model-background' : ''"
    >
      <div
        v-if="
          !sqlViewVisible &&
          !isModeDatamodelCreate &&
          !calculatedColumnCreateVisible
        "
        class="vis-datamodel-cards-and-preview-container"
        v-loading.fullscreen.lock="loadingInit"
      >
        <!-- CARD CONTAINER -->
        <div
          class="vis-row-box data-model-cards-items"
          :class="{ 'vis-height-100': !showPreviewSection }"
        >
          <JsplumbVue2DataFlowEditor
            ref="JsplumbVue2DataFlowEditor"
            :connections="selectedDatasetJoins"
            :nodes="dataModelCards"
            :positionsAndLevels="positionsAndLevels"
            @onDatasetClicked="
              (id) => {
                handleCardItemClick(id);
                callUpdateActiveCollapseItem(id);
              }
            "
            @onEditClicked="handleCardItemEditClicked"
            @onDeleteClicked="deleteDatasetClicked"
            @onAppendClicked="appendDatasetClicked"
            @onReplaceClicked="replaceDatasetClicked"
            @onDuplicateClicked="duplicateDatasetClicked"
            @showConnectionPopup="showConnectionPopup"
            @updatePositionsAndLevels="updatePositionsAndLevels"
            @onRemoveConnection="handleRemoveConnection"
            @onShowPreview="callRunSqlWithSelectedDataset"
            @onShowDataModelProperties="
              showTheGeneralDataModelPropertyOnTheRight
            "
          />
        </div>
        <NotificationPopup
          v-if="isNotificationPopupEnabled"
          notificationTitle="notifyTitle.error"
          textButtonLabel="notifyTitle.More"
          :notificationType="notificationType.ERROR"
          :notificationMessage="`errorCode.${errorDataResponse.code}`"
          :isShowTextButton="true"
          :isClosed="true"
          @handleClickWithTextButton="isSqlErrorPopupEnabled = true"
          @handleClickClose="isNotificationPopupEnabled = false"
        />
        <SqlErrorPopup
          v-if="isSqlErrorPopupEnabled"
          :data="errorDataResponse"
          @closePopup="isSqlErrorPopupEnabled = false"
        />
        <!-- PREVIEW CONTAINER -->
        <div v-if="showPreviewSection" class="vis-row-box preview-container">
          <DataModelPreviewTable
            :previewable="!!selectedDataset"
            :columns="dataModelPreviewTableColumns"
            :rows="previewTableRows"
            :datasetName="datasetName"
            @previewTable="onClosePreview"
          />
        </div>
      </div>
      <div
        v-else-if="sqlViewVisible"
        class="vis-row data-model-cards-and-preview-container"
      >
        <!-- SQL EDITOR CONTAINER -->
        <div
          class="vis-col vis-col-12 data-model-sql-editor-container vis-mb--none"
        >
          <DataModelSqlEditor
            v-loading="isRunSqlLoading"
            :connection="selectedConnection"
            :textToBeAddedToSql="textToBeAddedToSql"
            :datasetName="datasetName"
            :sqlSaveAction="doesUserHaveEditRoles && doesUserHaveEditAuthority"
            @resetTextToBeAddedToSql="setTextToBeAddedToSql('')"
            @runSql="runSql"
            @saveSql="saveSql"
            @close="closeDatamodelSqlEditor"
          />
        </div>
        <!-- SQL PREVIEW CONTAINER -->
        <div
          class="vis-col vis-col-12 vis-ma--none vis-pa--none data-model-sql-preview-container"
        >
          <DataModelSqlPreview
            v-loading="
              isRunSqlLoading && dataModelPreviewTableColumns.length > 1
            "
            :columns="dataModelPreviewTableColumns"
            :rows="previewTableRows"
          />
        </div>
        <NotificationPopup
          v-if="isNotificationPopupEnabled"
          notificationTitle="notifyTitle.error"
          textButtonLabel="notifyTitle.More"
          :notificationType="notificationType.ERROR"
          :notificationMessage="`errorCode.${errorDataResponse.code}`"
          :isShowTextButton="true"
          :isClosed="true"
          @handleClickWithTextButton="isSqlErrorPopupEnabled = true"
          @handleClickClose="isNotificationPopupEnabled = false"
        />
        <SqlErrorPopup
          v-if="isSqlErrorPopupEnabled"
          :data="errorDataResponse"
          @closePopup="isSqlErrorPopupEnabled = false"
        />
      </div>
      <div
        v-else-if="calculatedColumnCreateVisible"
        class="vis-row data-model-cards-and-preview-container"
      >
        <!-- SQL EDITOR CONTAINER -->
        <div
          class="vis-col vis-col-12 data-model-sql-editor-container vis-mb--none"
        >
          <DataModelCalculatedColumnEditor
            v-loading="isRunSqlLoading"
            ref="DataModelCalculatedColumnEditor"
            :connection="selectedConnection"
            :textToBeAddedToSql="textToBeAddedToSql"
            :filterItems="userExpression.filter"
            :levelItems="userExpression.level"
            :fields="datasetFields"
            :isEditMode="isCalculatedColumnEditMode"
            :name="editCalculatedColumnName"
            :fieldId="editCalculatedColumnId"
            :selectedDatasetId="calculatedColumnDatasetId"
            :connectionType="calculatedColumnConnectionType"
            @resetTextToBeAddedToSql="setTextToBeAddedToSql('')"
            @validate="handleValideCalculatedColumn"
            @save="createCalculatedColumn"
            @update="updateCalculatedColumn"
            @delete="openDeleteCalculatedColumn"
            @close="calculatedColumnCreateVisible = false"
            @setIsCalculatedColumnFormulaTabOpen="
              isCalculatedColumnFormulaTabOpen = $event
            "
            @filterTabOpened="handleCalculatedColumnFilterTabOpened"
          />
        </div>
        <DataModelPropertiesPanelCalculatedColumnUsageInfo
          v-if="calculatedColumnFunctionName && calculatedColumnCreateVisible"
          :calculatedColumnFunction="calculatedColumnFunctionName"
          @calculatedColumnFunctionName="setCalculatedColumnFunctionName"
        />
        <!-- SQL PREVIEW CONTAINER -->
        <div
          class="vis-col vis-col-12 vis-ma--none vis-pa--none data-model-sql-preview-container"
        >
          <DataModelSqlPreview
            v-if="!calculatedColumnFunctionName"
            v-loading="isRunSqlLoading"
            :columns="dataModelPreviewTableColumns"
            :rows="previewTableRows"
          />
        </div>
        <NotificationPopup
          v-if="isNotificationPopupEnabled"
          notificationTitle="notifyTitle.error"
          textButtonLabel="notifyTitle.More"
          :notificationType="notificationType.ERROR"
          :notificationMessage="`errorCode.${errorDataResponse.code}`"
          :isShowTextButton="true"
          :isClosed="true"
          @handleClickWithTextButton="isSqlErrorPopupEnabled = true"
          @handleClickClose="isNotificationPopupEnabled = false"
        />
        <SqlErrorPopup
          v-if="isSqlErrorPopupEnabled"
          :data="errorDataResponse"
          @closePopup="isSqlErrorPopupEnabled = false"
        />
      </div>
      <ProjectSavePopup
        v-if="isActiveProjectNamePopup"
        :title="$t('generalPages.saveDataModel')"
        :projectName="selectedDatamodel.name"
        :isActiveProjectNamePopup="isActiveProjectNamePopup"
        @setFolderId="setFolderId"
        @setProjectName="setDatamodelName"
        @closePopup="isActiveProjectNamePopup = $event"
      />
      <Overlay :isShow="isDataModelTemporaryFiltersPopupVisible" />
      <div v-if="isShowEmptyState && !loadingInit" class="vis-empty-state">
        <img src="../assets/images/dataModel/datamodel-emptyState.svg" alt="" />
      </div>
    </div>
  </layout>
</template>

<script>
//Layout
import Layout from "../layout/Layout.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ACTION as ACTION_DATAMODEL,
  GETTER as GETTER_DATAMODEL,
  MUTATION as MUTATION_DATAMODEL,
} from "../store/modules/Visualize/DataModel/types";
import { ACTION as ACTION_OBJECT_LOCK } from "../store/modules/Visualize/ObjectLock/types";
import { ACTION as ACTION_CALCULATED_COLUMN } from "../store/modules/Visualize/CalculatedColumn/types";
import {
  ACTION as ACTION_TEST_QUERY,
  GETTER as GETTER_TEST_QUERY,
  MUTATION as MUTATION_TEST_QUERY,
} from "../store/modules/Visualize/TestQuery/types";
import {
  GETTER as GETTER_CONNECTIONS,
  MUTATION as MUTATION_CONNECTIONS,
  ACTION as ACTION_CONNECTIONS,
} from "../store/modules/Visualize/Connections/types";
import {
  ACTION as ACTION_AUTHORIZATION,
  GETTER as GETTER_AUTHORIZATION,
} from "../store/modules/Visualize/Authorization/types";
import {
  ACTION as ACTION_DATASET,
  GETTER as GETTER_DATASET,
} from "../store/modules/Visualize/Dataset/types";
import {
  GETTER as GETTER_TEMP_STORAGE,
  MUTATION as MUTATION_TEMP_STORAGE,
  TEMP_STORAGE_KEYS,
} from "../store/modules/temp-storage/types";
import { GETTER as GETTER_GENERAL } from "../store/modules/Visualize/General/types";
import DataModelTopBar from "./../components/data/DataModelTopBar";
import DataModelLeftPanel from "./../components/data/DataModelLeftPanel";
import NewDataModelPopup from "./../components/data/NewDataModelPopup";
import ChangeDatasetConnectionPopup from "./../components/data/ChangeDatasetConnectionPopup";
import DataModelPreviewTable from "./../components/data/DataModelPreviewTable";
import DataModelRightPropertiesPanel from "./../components/data/DataModelRightPropertiesPanel";
import DataModelSqlLeftPanel from "./../components/data/sql/DataModelSqlLeftPanel";
import DataModelSqlEditor from "./../components/data/sql/DataModelSqlEditor";
import DataModelCalculatedColumnEditor from "./../components/data/sql/DataModelCalculatedColumnEditor";
import DataModelSqlPreview from "./../components/data/sql/DataModelSqlPreview";
import DataModelTemporaryFiltersPopup from "./../components/data/DataModelTemporaryFiltersPopup";
import DataModelRightPropertiesPanelCalculatedColumn from "./../components/data/dataModelProperties/DataModelRightPropertiesPanelCalculatedColumn";
import DataModelPropertiesPanelCalculatedColumnUsageInfo from "./../components/data/dataModelProperties/DataModelPropertiesPanelCalculatedColumnUsageInfo";
import DataModelColumnBasedProperties from "./../components/data/dataModelProperties/DataModelColumnBasedProperties";

import {
  importTypes,
  ContextMenuViewType,
  DatamodelContextDefaults,
  datamodelPropertyKeys,
  getCommonDatamodelPropertyValueByKey,
} from "../commons/dataModelTypes";
import { addNewDatasetPopupSteps } from "../commons/newDatasetPopup";
import { formatISOToDateTime } from "../util/moment";
import { routerEnums } from "../commons/Enum";
import cloneDeep from "clone-deep";
import { LoadingComponent } from "../store/modules/Visualize/General/loadingComponentDefinitions";
import componentAvailabilityCheck from "../mixins/componentAvailabilityCheck";
import { COMPONENT_PRIVILEGES, ROLES } from "../util/authorization";
import { formatDataModelQueryResults } from "../util/dataModelHelper";
import ProjectSavePopup from "../components/helper/ProjectSavePopup.vue";
import { filterType } from "../commons/filterComponents";
import DataFlowLinker from "../components/dataFlowEditor/components/DataFlowLinker.vue";
import Overlay from "../components/helper/Overlay.vue";
import { checkDashboardName } from "../commons/helper";
import { CustomIcon } from "../assets/js/custom-icons";
import ConfirmDialog from "../components/helper/ConfirmDialog.vue";
import JsplumbVue2DataFlowEditor from "../components/dataFlowEditor/components/JsplumbVue2DataFlowEditor.vue";
import SqlErrorPopup from "../components/data/sql/SqlErrorPopup.vue";
import NotificationPopup from "../components/helper/NotificationPopup.vue";
import eventBus from "../eventBus";
import { ServerEventToClientEventMapping } from "../store/modules/SSE/types";
import { Notification } from "element-ui";
import { notificationType } from "../commons/notificationTypes";
import _ from "lodash";
export default {
  components: {
    DataFlowLinker,
    Layout,
    DataModelTopBar,
    DataModelLeftPanel,
    NewDataModelPopup,
    ChangeDatasetConnectionPopup,
    DataModelPreviewTable,
    DataModelRightPropertiesPanel,
    DataModelSqlLeftPanel,
    DataModelSqlEditor,
    DataModelSqlPreview,
    DataModelTemporaryFiltersPopup,
    DataModelCalculatedColumnEditor,
    DataModelRightPropertiesPanelCalculatedColumn,
    ProjectSavePopup,
    DataModelPropertiesPanelCalculatedColumnUsageInfo,
    Overlay,
    ConfirmDialog,
    JsplumbVue2DataFlowEditor,
    SqlErrorPopup,
    NotificationPopup,
    DataModelColumnBasedProperties,
  },
  mixins: [componentAvailabilityCheck],
  data() {
    return {
      isCalculatedColumnFormulaTabOpen: true,
      columnBasedProperties: {
        visible: false,
        data: null,
        openCollapse: null,
      },
      columnBasedPropertiesCollapses: {
        Security: "Security",
        Properties: "Properties",
      },
      loadingInit: false,
      positionsAndLevels: {
        datasetCoords: [],
        zoomLevel: 0.5,
      },
      customIcon: CustomIcon,
      showPreviewSection: false,
      connectionPopupVisible: false,
      connectionPopupLinks: null,
      isConnectionPopupUpdate: false,
      validateCalculatedColumnParam: null,
      previewTableRows: [],
      dataModelPreviewTableColumns: [],
      calculatedColumnDatasetId: null,
      isActiveProjectNamePopup: false,
      calculatedColumnCreateVisible: false,
      isCalculatedColumnEditMode: false,
      editCalculatedColumnName: "",
      editCalculatedColumnId: "",
      fetchedDatamodelAuthorities: [],
      ldapAttributeKey: "user-attribute-ldap-mapper",
      isDataModelTemporaryFiltersPopupVisible: false,
      isDataModelTemporaryFiltersPopupVisibleInCalculatedColumn: false,
      dataModelTemporaryFiltersPopupItems: [],
      dataModelTemporaryFiltersPopupSql: "",
      deleteCustomFilterParamsArray: [],
      tempCustomFilterParams: [],
      selectedCardItem: null,
      fileList: [],
      addNewDatasetPopupSteps: addNewDatasetPopupSteps,
      addNewDatasetPopupCurrentStep: addNewDatasetPopupSteps.NONE,
      textToBeAddedToSql: "",
      userExpression: {
        filter: null,
        level: null,
      },
      calculatedColumnFunctionName: "",
      sqlEditorValue: "",
      createDatamodelPayload: {
        name: "",
        temporary: true,
        datasets: [
          {
            name: "",
            type: null,
            description: "",
            sql: "",
            max_row_count: 0,
            connectionId: "",
            properties: {
              store_data: true,
            },
            tableType: null,
          },
        ],
      },
      fieldList: [],
      dialogVisible: false,
      confirmDialogType: null,
      confirmDialogTitle: null,
      confirmDialogMessage: null,
      folderId: null,
      isDataModelPropertiesToggled: true,
      isDataModelLocked: false,
      isShowDataModelProperties: true,
      newDataModelPopupInitialCurrentStep: null,
      newDataModelPopupInitialData: null,
      ldapAttribute: ":ldap_",
      isNotificationPopupEnabled: false,
      isSqlErrorPopupEnabled: false,
      errorDataResponse: {},
      uploadMode: {
        Replace: "REPLACE",
        Append: "APPEND",
      },
      changeDatasetConnectionPopupVisible: false,
      connectionsNeedToBeChanged: [],
      notificationType: notificationType,
      fetchedDatamodelHighestPriority: null,
      isOneDatasetExpandedActive: false,
    };
  },
  watch: {
    "selectedDatamodel.properties.KEEP_ONE_DATASET_EXPANDED": {
      handler() {
        this.isOneDatasetExpandedActive = this.getDatamodelPropertyValue(
          datamodelPropertyKeys.KEEP_ONE_DATASET_EXPANDED
        );
      },
      immediate: true,
    },
    "selectedDatamodel.properties.coord": {
      handler(coord) {
        this.positionsAndLevels.datasetCoords = coord;
      },
      deep: true,
    },
    "selectedDatamodel.properties.zoomLevel": {
      handler(zoomLevel) {
        this.positionsAndLevels.zoomLevel = zoomLevel;
      },
      deep: true,
    },
    sqlViewVisible: {
      handler(val) {
        if (val) {
          this.fetchAuthorization({
            loadingComponent: LoadingComponent.SecurityFilters,
          });
          if (this.defaultSql.length) {
            setTimeout(() => {
              this.setTextToBeAddedToSql(this.defaultSql);
            }, 100);
          }
        }
      },
    },
    calculatedColumnCreateVisible: {
      handler() {
        this.previewTableRows = [];
        this.dataModelPreviewTableColumns = [];
        this.init();
      },
    },
    isDataModelLocked: {
      handler(value) {
        this.isDataModelLocked = value;
      },
    },
    testQueryResults: {
      handler() {
        this.$nextTick(() => {
          const selectedDataset = this.selectedDatasetFields;

          this.dataModelPreviewTableColumns = this.testQueryResults?.length
            ? Object.keys(this.testQueryResults[0]).map((t) => ({
                label: selectedDataset?.find((x) => x.name == t)?.alias || t,
                field: t.includes(".") ? t.replace(".", "_") : t,
                format: selectedDataset?.find((x) => x.name == t)?.format,
              }))
            : [];

          this.previewTableRows = formatDataModelQueryResults(
            this.testQueryResults,
            this.dataModelPreviewTableColumns
          );
        });
      },
      deep: true,
    },
    addNewDatasetPopupCurrentStep: {
      async handler() {
        if (
          this.addNewDatasetPopupCurrentStep ===
          this.addNewDatasetPopupSteps.NONE
        ) {
          this.init(false);
        } else {
          this.isShowDataModelProperties = false;
        }
      },
    },
    isNotificationPopupEnabled: {
      handler() {
        setTimeout(() => {
          this.isNotificationPopupEnabled = false;
        }, 5000);
      },
    },
  },
  beforeDestroy() {
    eventBus.$off(ServerEventToClientEventMapping.FILE_INGEST);
    this.setSelectedDatamodel({});
    this.resetDatasetFilterParams();
    this.resetTempStorageByKey({
      key: TEMP_STORAGE_KEYS.TEMP_RUN_SQL_FILTERS_POPUP,
      value: {},
    });
  },
  async mounted() {
    await this.init();

    eventBus.$on(ServerEventToClientEventMapping.FILE_INGEST, (msg) => {
      // call necessary methods to receive updated data such as for status of dataset call get datamodel by id
      if (msg.status === "SUCCESS") {
        Notification({
          title: this.$t("notifyTitle.success"),
          type: "success",
          data: "",
          duration: 3000,
          customClass: "center vis-notification",
          message: this.$t("generalPages.Data successfully uploaded"),
        });
      } else if (msg.status === "FAILURE") {
        Notification({
          title: this.$t("notifyTitle.error"),
          type: "error",
          data: "",
          duration: 3000,
          customClass: "center vis-notification",
          message: this.$t("generalPages.Data was not uploaded successfully"),
        });
      }

      this.init();
    });
  },
  computed: {
    ...mapGetters({
      connections: GETTER_CONNECTIONS.GET_CONNECTIONS,
      searchResults: GETTER_TEST_QUERY.GET_SEARCH_RESULTS,
      testQueryResults: GETTER_TEST_QUERY.GET_TEST_QUERY_RESULTS,
      testPreviewResults: GETTER_TEST_QUERY.GET_TEST_PREVIEW_RESULTS,
      dataModelList: GETTER_DATAMODEL.GET_DATAMODELS,
      selectedDatamodel: GETTER_DATAMODEL.GET_SELECTED_DATAMODEL,
      selectedConnection: GETTER_CONNECTIONS.GET_SELECTED_CONNECTION,
      authorization: GETTER_AUTHORIZATION.GET_AUTHORIZATION,
      datasetFields: GETTER_DATAMODEL.GET_DATAMODEL_BY_ID_FIELDS,
      selectedDatasetJoins: GETTER_DATASET.GET_SELECTED_DATASET_JOINS,
      datasetFilterParams: GETTER_DATAMODEL.GET_DATAMODEL_BY_ID_FILTER_PARAMS,
      loading: GETTER_GENERAL.GET_LOADING,
      tempStorageBykey: GETTER_TEMP_STORAGE.GET_TEMP_STORAGE_BY_KEY,
      currentUserRoles: "Security/Get/Keycloak/Roles",
    }),
    calculatedColumnConnectionType() {
      return this.calculatedColumnConnection?.connection?.type;
    },
    createOrSelectDatamodelPayloadDatasets() {
      if (
        this.addNewDatasetPopupCurrentStep ===
        this.addNewDatasetPopupSteps.SQL_CREATE
      ) {
        return null;
      } else return this.selectedDataset;
    },
    selectedConnectionInName() {
      return this.selectedConnection ? this.selectedConnection?.name : "";
    },
    isPageDashboard() {
      return checkDashboardName(this.$route.name);
    },
    datamodelLockingInfo() {
      return {
        lockCreatedDate: this.selectedDatamodel?.lockCreatedDate,
        lockCreatedBy: this.selectedDatamodel?.lockCreatedBy,
      };
    },
    selectedDatasetFields() {
      return this.datasetFields?.[this.selectedDataset?.datasetId];
    },
    isShowEmptyState() {
      return !this.dataModelCards && !this.selectedConnection;
    },
    doesUserHaveEditRoles() {
      return this.currentUserRoles?.some((r) =>
        [ROLES.ADMIN.key, ROLES.DATAMODEL_EDITOR.key].includes(r)
      );
    },
    calculatedColumnConnection() {
      return (
        this.datasets?.find(
          (d) => d.datasetId === this.calculatedColumnDatasetId
        ) ?? null
      );
    },
    attributeFilterSearchParam() {
      return {
        fieldList: this.fieldList,
        //TODO Burası backend'in dataset objesine ihtiyacı kalmadığında kaldırılacak. attributeFilterSearchParam child componentlere taşınabilir.
        dataset: {
          id: this.selectedDataset?.datasetId,
          name: this.selectedDataset?.name,
        },
      };
    },
    currentUserUsername() {
      return this.$store.getters["Security/Get/Keycloak/Username"];
    },
    isDatamodelEditAvailable() {
      return (
        this.isComponentAvailable(COMPONENT_PRIVILEGES.DATAMODEL_PAGE_EDIT) &&
        this.doesUserHaveEditAuthority
      );
    },
    doesUserHaveEditAuthority() {
      // Authority'nin null gelme durumu sadece yeni create işlemlerinde oluyor
      if (this.fetchedDatamodelHighestPriority?.authority === null) {
        return true;
      }

      return this.fetchedDatamodelHighestPriority?.userCanEdit;
    },
    filteredCustomDatasetFilterParams() {
      return this.datasetFilterParams?.filter(
        (t) => t.filterType !== filterType.SECURITY
      );
    },
    securityFilters() {
      return this.authorization
        ?.filter((f) => f.type === this.ldapAttributeKey)
        .map((a) => {
          return {
            label: a.name,
            labelPrefix: "LDAP ",
            value: `:ldap_${a.name}`,
          };
        });
    },
    isSecurityFiltersLoading() {
      return this.loading[LoadingComponent.SecurityFilters];
    },
    isCustomFiltersLoading() {
      return this.loading[LoadingComponent.CustomFilters];
    },
    isRunSqlLoading() {
      return this.loading[LoadingComponent.RunSql];
    },
    isDatamodelFieldsBeautifyLoading() {
      return this.loading[LoadingComponent.DatamodelFieldBeautify];
    },
    isModeDatamodelCreate() {
      return (
        this.$route.name !== routerEnums.DATAMODEL_VIEW ||
        this.addNewDatasetPopupCurrentStep ===
          this.addNewDatasetPopupSteps.SQL_CREATE
      );
    },
    routeId() {
      return this.$route.params.id;
    },
    dataModelCards() {
      return this.datasets?.map((dm) => ({
        connectionType: dm.connection.type,
        status: dm.status,
        type: dm.type,
        id: dm.datasetId,
        name: dm.name,
        columns: this.datasetFields?.[dm.datasetId]?.length ?? 0,
        rows: dm.rows ?? 0,
        lastUpdate: formatISOToDateTime(dm?.updated_date) ?? "N/A",
      }));
    },
    rightPropertiesType() {
      return importTypes.DB;
    },
    sqlViewVisible() {
      return (
        this.addNewDatasetPopupCurrentStep ===
          this.addNewDatasetPopupSteps.SQL_CREATE ||
        this.addNewDatasetPopupCurrentStep ===
          this.addNewDatasetPopupSteps.SQL_EDIT
      );
    },
    newDataModelPopupVisible() {
      const newDataModelPopupVisibleArray = [
        this.addNewDatasetPopupSteps.SELECT_TYPE,
        this.addNewDatasetPopupSteps.CSV_UPLOAD,
        this.addNewDatasetPopupSteps.EXCEL_UPLOAD,
        this.addNewDatasetPopupSteps.SELECT_DATABASE_CONNECTION,
      ];

      return newDataModelPopupVisibleArray.includes(
        this.addNewDatasetPopupCurrentStep
      );
    },
    selectedDataset() {
      if (
        this.addNewDatasetPopupCurrentStep ===
        this.addNewDatasetPopupSteps.SQL_CREATE
      ) {
        return undefined;
      }

      return this.datasets?.find((d) => d.datasetId === this.selectedCardItem);
    },
    defaultSql() {
      return this.selectedDataset?.sql ?? "";
    },

    datasets() {
      return this.selectedDatamodel?.datasets;
    },
    securityFiltersValues() {
      return this.authorization.map((a) => `:ldap_${a.name}`);
    },
    tempDataModel() {
      return this.tempStorageBykey(TEMP_STORAGE_KEYS.TEMP_DATAMODEL);
    },
    datasetAliasFieldNameMapping() {
      const mappingArr = [];

      Object.values(this.datasetFields)?.forEach((f) =>
        f?.forEach?.((s) => mappingArr.push({ alias: s.alias, name: s.name }))
      );

      return mappingArr;
    },
    datasetName() {
      return (
        this.selectedDataset?.name ||
        this.createDatamodelPayload?.datasets?.[0]?.name
      );
    },
    selectedConnectionLength() {
      return this.selectedConnection
        ? Object.keys(this.selectedConnection)?.length
        : 0;
    },
    selectedDatasetFieldsLength() {
      return this.selectedDatasetFields?.length ?? 0;
    },
  },
  methods: {
    ...mapActions({
      fetchConnections: ACTION_CONNECTIONS.FETCH_CONNECTIONS,
      fetchTestQuery: ACTION_TEST_QUERY.FETCH_TEST_QUERY,
      fetchTestPreview: ACTION_TEST_QUERY.FETCH_TEST_PREVIEW,
      fetchSearch: ACTION_TEST_QUERY.FETCH_SEARCH,
      updateDataModel: ACTION_DATAMODEL.UPDATE_DATAMODEL,
      fetchDataModelById: ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID,
      fetchObjectLock: ACTION_OBJECT_LOCK.FETCH_OBJECT_LOCK,
      createDataModel: ACTION_DATAMODEL.CREATE_DATAMODEL,
      fetchAuthorization: ACTION_AUTHORIZATION.FETCH_AUTHORIZATION,
      fetchDatamodelFieldsById: ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID_FIELDS,
      fetchDatamodelByIdFilterParams:
        ACTION_DATAMODEL.FETCH_DATAMODEL_BY_ID_FILTER_PARAMS,
      generateDatasetFieldsById: ACTION_DATASET.GENERATE_DATASET_FIELDS_BY_ID,
      updateField: ACTION_DATASET.UPDATE_FIELD,
      fetchDatamodelAuthorizationById:
        ACTION_AUTHORIZATION.FETCH_DATAMODEL_AUTHORIZATION_BY_ID_WITHOUT_MUTATE,

      createCalculatedField: ACTION_CALCULATED_COLUMN.CREATE_CALCULATED_FIELD,
      updateCalculatedField: ACTION_CALCULATED_COLUMN.UPDATE_CALCULATED_FIELD,
      validateCalculatedField:
        ACTION_CALCULATED_COLUMN.VALIDATE_CALCULATED_FIELD,
      updateDatasetById: ACTION_DATASET.UPDATE_DATASET_BY_ID,
      createDataset: ACTION_DATASET.CREATE_DATASET,
      createObjectLock: ACTION_OBJECT_LOCK.CREATE_OBJECT_LOCK,
      copyDataset: ACTION_DATASET.COPY_DATASET,
      updateDatamodelName: ACTION_DATAMODEL.UPDATE_DATAMODEL_NAME,
      updateQueryTimeout: ACTION_DATAMODEL.UPDATE_QUERY_TIMEOUT,
      updateQueryStatus: ACTION_DATAMODEL.UPDATE_QUERY_STATUS,
      updateDatamodelProperties: ACTION_DATAMODEL.UPDATE_DATAMODEL_PROPERTIES,
      deleteDatasetStore: ACTION_DATASET.DELETE_DATASET,
      deleteCalculated: ACTION_DATASET.DELETE_CALCULATION,
      saveDatasetJoin: ACTION_DATASET.SAVE_DATASET_JOIN,
      fetchDatasetJoin: ACTION_DATASET.FETCH_DATASET_JOIN,
      deleteObjectLock: ACTION_OBJECT_LOCK.DELETE_OBJECT_LOCK,
      deleteDatasetJoin: ACTION_DATASET.DELETE_DATASET_JOIN,
      updateDatasetJoin: ACTION_DATASET.UPDATE_DATASET_JOIN,
      fetchDatasetByIdCheckUse: ACTION_DATASET.FETCH_CHECK_DATASET_USE_BY_ID,
      duplicateDatamodelField: ACTION_DATAMODEL.DUPLICATE_DATAMODEL_FIELD,
      storeUpdateDatasetFieldsAlias: ACTION_DATASET.UPDATE_DATASET_FIELDS_ALIAS,
      getHighestPriorityByDatamodelId:
        ACTION_DATAMODEL.FETCH_HIGHEST_PRIORITY_BY_DATAMODEL_ID,
      storeUpdateSortAndRenameSettings:
        ACTION_DATASET.UPDATE_SORT_AND_RENAME_SETTINGS,
    }),
    ...mapMutations({
      resetTestQuery: MUTATION_TEST_QUERY.RESET_TEST_QUERY,
      setSelectedConnection: MUTATION_CONNECTIONS.SET_SELECTED_CONNECTION,
      setSelectedDatamodel: MUTATION_DATAMODEL.SET_SELECTED_DATAMODEL,
      setSelectedDatamodelQueryTimeout:
        MUTATION_DATAMODEL.SET_SELECTED_DATAMODEL_QUERY_TIMEOUT,
      resetDatasetFields: MUTATION_DATAMODEL.RESET_DATAMODEL_FIELDS,
      resetTempStorageByKey: MUTATION_TEMP_STORAGE.RESET_TEMP_STORAGE_BY_KEY,
      setTempStorageByKey: MUTATION_TEMP_STORAGE.SET_TEMP_STORAGE_BY_KEY,
      resetDatasetFilterParams:
        MUTATION_DATAMODEL.RESET_DATAMODEL_FILTER_PARAMS,
      setSelectedDataset: MUTATION_DATAMODEL.SET_SELECTED_DATASET,
    }),
    handleCsvExcelUpload({ action, data }) {
      this.setNewDatasetCoords(data.datasetId);

      if (action === "redirectToDatamodel") {
        this.updateDatamodelProperties({
          queryParam: data.dataModelId,
          bodyParam: {
            coord: [{
              source_css: `left: 0px; top: 0px;`,
              source_id: data.datasetId,
            }],
            zoomLevel: 1,
          },
        });

        setTimeout(() => {
          this.redirectToDatamodel(data.dataModelId);
        }, 1000);
      }
    },
    updatePositionsAndLevels(newPositionsAndLevels) {
      this.positionsAndLevels = newPositionsAndLevels;
      this.callUpdateDatamodelProperties();
    },
    callShowOnDiagram(id) {
      this.$refs.JsplumbVue2DataFlowEditor.setSelectedNode(id);
      this.handleCardItemClick(id);
    },
    getDatamodelPropertyValue(datamodelPropertyKey) {
      return getCommonDatamodelPropertyValueByKey(
        this.selectedDatamodel?.properties,
        datamodelPropertyKey
      );
    },
    handleCalculatedColumnFilterTabOpened() {
      const sql = this.selectedDataset?.sql;

      this.dataModelTemporaryFiltersPopupItems = sql
        .match(/(:[a-zA-Z0-9_çğıöşüÇĞİÖŞÜ]+)/g)
        ?.filter((parametre) => !parametre?.includes(this.ldapAttribute));
      this.dataModelTemporaryFiltersPopupSql = sql;

      if (this.dataModelTemporaryFiltersPopupItems?.length) {
        this.isDataModelTemporaryFiltersPopupVisible = true;
        this.isDataModelTemporaryFiltersPopupVisibleInCalculatedColumn = true;
      }
    },
    async updateDatasetFieldsAlias(payload) {
      await this.storeUpdateDatasetFieldsAlias({
        datasetId: payload.datasetId,
        bodyParam: {
          fieldBeautifyOperation:
            payload.bodyParam.sortType ?? payload.bodyParam.autoRenameType,
        },
        loadingComponent: LoadingComponent.DatamodelFieldBeautify,
      });
      await this.updateSortAndRenameSettings(payload);
    },
    async updateSortAndRenameSettings(payload) {
      await this.storeUpdateSortAndRenameSettings({
        datasetId: payload.datasetId,
        bodyParam: payload.bodyParam,
      });
      this.init();
    },
    closeColumnBasedProperties() {
      this.columnBasedProperties.visible = false;
      this.columnBasedProperties.data = null;
      this.columnBasedProperties.openCollapse = null;
    },
    openColumnSecurity(field) {
      this.columnBasedProperties.visible = true;
      this.columnBasedProperties.data = field;
      this.columnBasedProperties.openCollapse =
        this.columnBasedPropertiesCollapses.Security;
    },
    openColumnProperties(field) {
      this.columnBasedProperties.visible = true;
      this.columnBasedProperties.data = field;
      this.columnBasedProperties.openCollapse =
        this.columnBasedPropertiesCollapses.Properties;
    },
    callUpdateActiveCollapseItem(datasetId) {
      const datasetName = this.dataModelCards.find(
        (x) => x.id == datasetId
      )?.name;

      this.$refs.DataModelLeftPanel.updateActiveCollapseItem(datasetName);
    },
    async duplicateFieldById(param) {
      await this.duplicateDatamodelField({ bodyParam: { id: param?.fieldId } });
      await this.fetchDatamodelFieldsById(this.routeId);
    },
    handleNotificationPopup(response) {
      if ([400, 401, 500].includes(response?.status)) {
        this.errorDataResponse = cloneDeep(response.data);
        this.isNotificationPopupEnabled = true;
      }
    },
    async handleRemoveConnection(connectionIds) {
      await this.deleteDatasetJoin({
        bodyParam: connectionIds,
      });

      await this.fetchDatasetJoin(this.selectedDatamodel.id);
    },
    closeConnectionPopupAndRemoveLink() {
      this.connectionPopupVisible = false;
      this.$refs.JsplumbVue2DataFlowEditor.cancelLinking();
    },
    setFolderId(id) {
      this.folderId = id;
    },
    async callSaveDatasetJoin(params) {
      const payload = params.map((p) => {
        return {
          id: p.id,
          datamodelId: this.selectedDatamodel.id,
          leftDatasetId: p.leftDatasetId,
          leftDatasetFieldId: p.left,
          rightDatasetId: p.rightDatasetId,
          rightDatasetFieldId: p.right,
          relationType: p.type,
          operator: null,
          name: "test",
        };
      });

      await this.saveDatasetJoin({
        bodyParam: payload,
      });

      this.hideConnectionPopup();

      await this.fetchDatasetJoin(this.selectedDatamodel.id);
    },
    showConnectionPopup(e) {
      this.connectionPopupVisible = true;
      this.connectionPopupLinks = e;
      if (
        this.connectionPopupLinks[0].leftDatasetFieldId &&
        this.connectionPopupLinks[0].rightDatasetFieldId &&
        this.connectionPopupLinks[0].relationType
      )
        this.isConnectionPopupUpdate = true;
    },
    hideConnectionPopup() {
      this.connectionPopupVisible = false;
      this.connectionPopupLinks = null;
      this.isConnectionPopupUpdate = false;
    },
    handleTemporaryFiltersPopupRun(params) {
      if (this.isDataModelTemporaryFiltersPopupVisibleInCalculatedColumn) {
        setTimeout(() => {
          this.$refs.DataModelCalculatedColumnEditor.setSelectedDatasetCustomFilterParams();
        }, 500);

        this.isDataModelTemporaryFiltersPopupVisibleInCalculatedColumn = false;
        this.isDataModelTemporaryFiltersPopupVisible = false;

        return;
      }

      if (this.calculatedColumnCreateVisible) {
        this.validateCalculatedColumnWithFilterParams(params);
      } else {
        this.fetchSql(params);
      }
    },
    redirectToDatamodel(id) {
      this.$router.push({
        name: routerEnums.DATAMODEL_VIEW,
        params: {
          id,
        },
      });
    },
    redirectToHome() {
      this.$router.push({
        name: routerEnums.HOME,
      });
    },
    async changeDatasetConnections(items) {
      const lengthOfSelectedDatamodelDatasets =
        this.selectedDatamodel?.datasets?.length;

      // send request one by one for the each dataset that are effected by connection change
      for (let i = 0; i < lengthOfSelectedDatamodelDatasets; i++) {
        const dataset = this.selectedDatamodel.datasets[i];
        const foundFromConnection = items.find(
          (item) => item.from === dataset.connectionId
        );

        if (foundFromConnection?.to) {
          const toConnectionObj = this.connections.find(
            (connection) => connection.connectionId === foundFromConnection.to
          );

          const customAndSecurityFilterParams = this.datasetFilterParams
            ?.filter((t) => !t.datasetId || t.datasetId === dataset.datasetId)
            ?.map((t) => {
              // eslint-disable-next-line no-unused-vars
              const { temporaryFilterParamId, ...others } = t;
              if (t?.filterType === filterType.SECURITY) {
                return {
                  filterType: t?.filterType,
                  name: t?.name,
                };
              } else {
                return others;
              }
            });

          await this.updateDatasetById({
            queryParam: dataset.datasetId,
            bodyParam: {
              ...dataset,
              connection: toConnectionObj,
              connectionId: foundFromConnection.to,
              filterParams: customAndSecurityFilterParams,
            },
          });
        }
      }

      this.changeDatasetConnectionPopupVisible = false;

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },

    async deleteDatasetClicked(datasetId) {
      this.handleCardItemClick(datasetId);

      const result = await this.fetchDatasetByIdCheckUse(datasetId);
      this.confirmDialogType = DatamodelContextDefaults.DATASET;

      if (result) {
        this.confirmDialogTitle = `dialog.deleteDatasetTitle`;
        this.confirmDialogMessage = `dialog.deleteDatasetMessage`;
      } else {
        this.confirmDialogTitle = `dialog.deleteDatasetCheckUseTitle`;
        this.confirmDialogMessage = `dialog.deleteDatasetCheckUseMessage`;
      }

      this.dialogVisible = true;
      this.changeDataFlowNodeStyleZindex("0");
    },
    async lockDatamodel(isLocked) {
      if (isLocked) {
        this.confirmDialogType = DatamodelContextDefaults.OBJECT_LOCK;
        this.confirmDialogTitle = `dialog.Lock Data Model?`;
        this.confirmDialogMessage = `dialog.This data model will be locked and other users will not be able to make changes Do you confirm?`;
      } else {
        this.confirmDialogType = DatamodelContextDefaults.OBJECT_UNLOCK;
        this.confirmDialogTitle = `dialog.Unlock Data Model?`;
        this.confirmDialogMessage = `dialog.This data model will be unlocked and other users will be able to update it Do you confirm?`;
      }

      this.dialogVisible = true;
    },
    async checkingDatamodelLock() {
      this.isDataModelLocked = await this.fetchObjectLock({
        loadingComponent: LoadingComponent.ObjectLock,
        bodyParam: {
          objectId: this.selectedDatamodel?.id,
          objectType: ContextMenuViewType.DATAMODEL,
        },
      });
    },
    confirmLockingOfDatamodel() {
      this.createObjectLock({
        loadingComponent: LoadingComponent.ObjectLock,
        bodyParam: {
          objectId: this.selectedDatamodel?.id,
          objectType: ContextMenuViewType.DATAMODEL,
        },
      });
      this.dialogVisible = false;
      this.init();
    },
    confirmUnLockingOfDatamodel() {
      this.deleteObjectLock({
        loadingComponent: LoadingComponent.ObjectLock,
        bodyParam: {
          objectId: this.selectedDatamodel?.id,
          objectType: ContextMenuViewType.DATAMODEL,
        },
      });
      this.dialogVisible = false;
      this.init();
    },
    appendDatasetClicked(id) {
      this.startUploadingDatasetWithUploadMode(id, this.uploadMode.Append);
    },
    replaceDatasetClicked(id) {
      this.startUploadingDatasetWithUploadMode(id, this.uploadMode.Replace);
    },
    async duplicateDatasetClicked(id) {
      this.handleCardItemClick(id);

      const currentDataset = this.selectedDatamodel?.datasets?.find(
        (d) => d.datasetId === this.selectedCardItem
      );

      const newDataset = await this.copyDataset({
        bodyParam: {
          id: currentDataset?.datasetId,
        },
      });

      if (newDataset?.status === 400) {
        return;
      }

      if (newDataset) {
        this.setNewDatasetCoords(newDataset.datasetId);
        this.init();
      }
    },
    startUploadingDatasetWithUploadMode(id, uploadMode) {
      this.addNewDatasetPopupCurrentStep =
        this.addNewDatasetPopupSteps.SELECT_TYPE;
      this.newDataModelPopupInitialCurrentStep =
        this.addNewDatasetPopupSteps.CSV_UPLOAD;
      this.newDataModelPopupInitialData =
        this.selectedDatamodel?.datasets?.find((d) => d.datasetId === id);
      this.newDataModelPopupInitialData.uploadMode = uploadMode;
    },
    async deleteDataset() {
      await this.deleteDatasetStore({
        queryParam: this.selectedCardItem,
      });
      this.dialogVisible = false;
      this.init();
      this.changeDataFlowNodeStyleZindex("1");
    },
    async getDatamodelHighestPriority() {
      this.fetchedDatamodelHighestPriority =
        await this.getHighestPriorityByDatamodelId({
          datamodelId: this.selectedDatamodel?.id,
        });
    },
    preApproval() {
      if (this.confirmDialogType === DatamodelContextDefaults.DATASET) {
        this.deleteDataset();
      } else if (
        this.confirmDialogType === DatamodelContextDefaults.OBJECT_LOCK
      ) {
        this.confirmLockingOfDatamodel();
      } else if (
        this.confirmDialogType === DatamodelContextDefaults.OBJECT_UNLOCK
      ) {
        this.confirmUnLockingOfDatamodel();
      }
    },
    cancelConfirmDialog(event) {
      this.dialogVisible = event;
      this.changeDataFlowNodeStyleZindex("1");
    },
    changeDataFlowNodeStyleZindex(value) {
      document.querySelectorAll(".data-flow-node").forEach((element) => {
        element.style.zIndex = value;
      });
    },
    checkConnectionAuthoritiesByCurrentUser() {
      const connectionIds = this.connections.map(
        (connection) => connection.connectionId
      );

      this.connectionsNeedToBeChanged = [];
      this.selectedDatamodel.datasets.forEach((dataset) => {
        if (!connectionIds.includes(dataset.connectionId)) {
          const idsThatArePushed = this.connectionsNeedToBeChanged.map(
            (c) => c.connectionId
          );

          if (!idsThatArePushed.includes(dataset.connectionId)) {
            this.connectionsNeedToBeChanged.push({
              connection: dataset.connection,
              connectionId: dataset.connectionId,
            });
          }
        }
      });

      if (this.connectionsNeedToBeChanged.length) {
        this.changeDatasetConnectionPopupVisible = true;
      }
    },
    async init(clickFirstItem = true) {
      this.loadingInit = true;
      this.getDatamodelAuthorities();
      this.resetTestQuery();
      this.setSelectedConnection(null);
      this.setSelectedDatamodel({});
      this.resetDatasetFilterParams();
      this.resetTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_RUN_SQL_FILTERS_POPUP,
        value: {},
      });
      this.resetDatasetFields();
      await this.fetchConnections();

      if (!this.isModeDatamodelCreate) {
        await this.fetchDataModelById(this.routeId);
        await this.getDatamodelFieldsAndFilters();
        await this.fetchDatasetJoin(this.selectedDatamodel.id);

        this.checkConnectionAuthoritiesByCurrentUser();
      }
      if (this.dataModelCards?.length && clickFirstItem) {
        this.handleCardItemClick(
          this.selectedDataset
            ? this.selectedDataset?.datasetId
            : this.dataModelCards[0].id
        );
      }
      this.getDatamodelHighestPriority();
      const clonedDatamodel = cloneDeep(this.createDatamodelPayload);
      this.setTempStorageByKey({
        key: TEMP_STORAGE_KEYS.TEMP_DATAMODEL,
        value: clonedDatamodel,
      });
      this.checkingDatamodelLock();
      this.loadingInit = false;
    },
    handleValideCalculatedColumn(param) {
      const sql = this.selectedDataset?.sql;
      this.validateCalculatedColumnParam = param;
      this.dataModelTemporaryFiltersPopupItems = sql
        .match(/(:[a-zA-Z0-9_çğıöşüÇĞİÖŞÜ]+)/g)
        ?.filter((parametre) => !parametre?.includes(this.ldapAttribute));

      this.dataModelTemporaryFiltersPopupSql = sql;

      if (!this.dataModelTemporaryFiltersPopupItems?.length)
        this.validateCalculatedColumnWithoutFilterParams();
      else {
        this.isDataModelTemporaryFiltersPopupVisible = true;
      }
    },
    async validateCalculatedColumnWithoutFilterParams() {
      const result = await this.validateCalculatedField({
        bodyParam: {
          ...this.validateCalculatedColumnParam,
          datamodelId: this.selectedDatamodel.id,
        },
        loadingComponent: LoadingComponent.RunSql,
      });
      this.handleNotificationPopup(result);
      this.previewTableRows = result.aggregations;
      if (this.previewTableRows?.length) {
        this.calculatedColumnFunctionName = null;
      }
      this.dataModelPreviewTableColumns = Object.keys(
        result.aggregations?.[0]
      )?.map((a) => ({
        field: a,
        label: a,
      }));
    },
    async validateCalculatedColumnWithFilterParams(params) {
      const result = await this.validateCalculatedField({
        bodyParam: {
          ...this.validateCalculatedColumnParam,
          datamodelId: this.selectedDatamodel.id,
          filterParams: this.checkFilterParams(params),
        },
        loadingComponent: LoadingComponent.RunSql,
      });

      this.handleNotificationPopup(result);
      this.previewTableRows = result.aggregations;
      if (this.previewTableRows.length) {
        this.calculatedColumnFunctionName = null;
      }
      this.dataModelPreviewTableColumns = Object.keys(
        result.aggregations?.[0]
      )?.map((a) => ({
        field: a,
        label: a,
      }));
    },
    async createCalculatedColumn(param) {
      const response = await this.createCalculatedField({
        bodyParam: {
          ...param,
          datamodelId: this.selectedDatamodel.id,
        },
        loadingComponent: LoadingComponent.RunSql,
      });
      this.handleNotificationPopup(response);
      if (!response.status) this.calculatedColumnCreateVisible = false;
    },
    async updateCalculatedColumn(param) {
      const response = await this.updateCalculatedField({
        queryParam: this.editCalculatedColumnId,
        bodyParam: { ...param, datamodelId: this.selectedDatamodel.id },
      });
      this.handleNotificationPopup(response);
      if (!response.status) this.calculatedColumnCreateVisible = false;
    },
    getSelectedField(param) {
      this.fieldList.push(param);
    },
    openCreateCalculatedColumn(field) {
      const datasetName = this.datasets.find(
        (x) => x.datasetId == field?.datasetId
      )?.name;

      this.calculatedColumnCreateVisible = false;

      if (this.dataModelCards) {
        const matchedCard = this.dataModelCards.find(
          (card) => card.id === field.datasetId
        );

        if (matchedCard) {
          this.handleCardItemClick(matchedCard.id);
        }
      }

      setTimeout(() => {
        this.closeColumnBasedProperties();
        this.isCalculatedColumnEditMode = false;
        this.editCalculatedColumnName = "";
        this.editCalculatedColumnId = "";
        this.calculatedColumnCreateVisible = true;
        this.setTextToBeAddedToSql(`[${field?.alias}]`);
        this.userExpression = {
          filter: [],
          level: [],
        };
        this.calculatedColumnDatasetId = field?.datasetId;
      }, 300);

      setTimeout(() => {
        this.$refs.DataModelLeftPanel.resetActiveCollapseItem();
        this.$refs.DataModelLeftPanel.updateActiveCollapseItem(datasetName);
      }, 1000);
    },
    async openEditCalculatedColumn(field) {
      const datasetName = this.datasets.find(
        (x) => x.datasetId == field?.datasetId
      )?.name;

      this.calculatedColumnCreateVisible = false;

      if (this.dataModelCards) {
        const matchedCard = this.dataModelCards.find(
          (card) => card.id === field.datasetId
        );

        if (matchedCard) {
          this.handleCardItemClick(matchedCard.id);
        }
      }

      setTimeout(() => {
        this.closeColumnBasedProperties();
        this.isCalculatedColumnEditMode = true;
        this.calculatedColumnCreateVisible = true;
        this.editCalculatedColumnName = field?.name;
        this.editCalculatedColumnId = field?.fieldId;
        this.textToBeAddedToSql = field?.userExpression?.formula;
        this.userExpression = {
          filter: field?.userExpression?.filter ?? [],
          level: field?.userExpression?.level ?? [],
        };
        this.calculatedColumnDatasetId = field?.datasetId;
      }, 300);

      setTimeout(() => {
        this.$refs.DataModelLeftPanel.resetActiveCollapseItem();
        this.$refs.DataModelLeftPanel.updateActiveCollapseItem(datasetName);
      }, 1000);
    },
    async openDeleteCalculatedColumn(field) {
      await this.deleteCalculated({ queryParam: field.fieldId });
      this.init();
    },
    async getDatamodelAuthorities() {
      this.fetchedDatamodelAuthorities =
        await this.fetchDatamodelAuthorizationById(this.routeId);
    },
    saveField({ bodyParam }) {
      this.updateField({
        data: bodyParam,
        dataModelId: this.selectedDatamodel.id,
      });
    },
    addToDeleteCustomFilterParamsArray(id) {
      if (id) this.deleteCustomFilterParamsArray.push(id);
    },
    handleDBGeneralPropertyChanged(params) {
      if (this.selectedDataset) {
        const clonedSelectedDataset = cloneDeep(this.selectedDataset);
        clonedSelectedDataset[params.key] = params.value;
        this.setSelectedDataset(clonedSelectedDataset);
      }
      this.createDatamodelPayload.datasets[0][params.key] = params.value;
    },
    setTextToBeAddedToSql(val) {
      this.textToBeAddedToSql = val;
    },
    setCalculatedColumnFunctionName(value) {
      this.calculatedColumnFunctionName = value;
    },
    async handleCreateDatamodel(sql) {
      this.createDatamodelPayload.name = "Temp_" + Date.now();
      this.createDatamodelPayload.datasets[0].sql = sql;
      this.createDatamodelPayload.datasets[0].connectionId =
        this.selectedConnection?.connectionId;
      this.createDatamodelPayload.datasets[0].type = importTypes.DB;

      const securityFilterParams = this.createSecurityFilterParams(sql);
      const customFilterParams = cloneDeep(this.tempCustomFilterParams)?.map(
        (t) => {
          // eslint-disable-next-line no-unused-vars
          const { temporaryFilterParamId, ...others } = t;

          return others;
        }
      );

      this.createDatamodelPayload.datasets[0].filterParams = [
        ...securityFilterParams,
        ...customFilterParams,
      ];

      const newDatamodel = await this.createDataModel(
        this.createDatamodelPayload
      );

      if (newDatamodel?.id) {
        // update coords to 0px to 0px
        await this.updateDatamodelProperties({
          queryParam: newDatamodel.id,
          bodyParam: {
            coord: [
              {
                source_css: `left: 0px; top: 0px;`,
                source_id: newDatamodel.datasets[0].datasetId,
              },
            ],
            zoomLevel: 1,
          },
        });

        this.$router.push({
          name: routerEnums.DATAMODEL_VIEW,
          params: {
            id: newDatamodel.id,
          },
        });
      }
    },
    async handleCreateDataset(sql) {
      const securityFilterParams = this.createSecurityFilterParams(sql);
      const customFilterParams = cloneDeep(this.tempCustomFilterParams)
        ?.filter((f) => !f.paramId)
        ?.map((t) => {
          // eslint-disable-next-line no-unused-vars
          const { temporaryFilterParamId, ...others } = t;

          return others;
        });

      const newDataset = await this.createDataset({
        bodyParam: {
          name: this.createDatamodelPayload.datasets[0].name,
          connectionId: this.selectedConnection?.connectionId,
          max_row_count: this.createDatamodelPayload.datasets[0].max_row_count,
          type: importTypes.DB,
          sql,
          filterParams: [...securityFilterParams, ...customFilterParams],
          dataModelId: this.selectedDatamodel.id,
          tableType: this.createDatamodelPayload.datasets[0].tableType,
        },
      });

      if (newDataset?.status === 400) {
        return;
      }

      if (newDataset) {
        this.setNewDatasetCoords(newDataset);
      }
    },

    calculateMinLeftMinTopCoords() {
      let minLeftCoord = 1000000;
      let minTopCoord = 1000000;
      const datasetIds = this.dataModelCards?.map((dc) => dc.id);

      this.positionsAndLevels.datasetCoords =
        this.positionsAndLevels.datasetCoords?.filter((coord) =>
          datasetIds.includes(coord.source_id)
        );

      this.positionsAndLevels?.datasetCoords?.forEach((coord) => {
        const sourceCssLeft = coord?.source_css
          ? Number(
              coord.source_css.split(";")[0].split(": ")[1].replace("px", "")
            )
          : 0;
        const sourceCssTop = coord?.source_css
          ? Number(
              coord.source_css.split(";")[1].split(": ")[1].replace("px", "")
            )
          : 0;

        if (minLeftCoord > sourceCssLeft) {
          minLeftCoord = sourceCssLeft;
        }

        if (minTopCoord > sourceCssTop) {
          minTopCoord = sourceCssTop;
        }
      });

      return { minLeftCoord, minTopCoord };
    },

    setNewDatasetsCoords(newDatasets) {
      const { minLeftCoord, minTopCoord } = this.calculateMinLeftMinTopCoords();

      const newCoords = [];

      newDatasets.forEach((newDataset, index) => {
        newCoords.push({
          source_css: `left: ${minLeftCoord}px; top: ${
            minTopCoord - 150 * (index + 1)
          }px;`,
          source_id: newDataset.datasetId,
        });
      });

      this.positionsAndLevels.datasetCoords = [
        ...this.positionsAndLevels.datasetCoords,
        ...newCoords,
      ];
      this.callUpdateDatamodelProperties();
    },

    setNewDatasetCoords(datasetId) {
      const { minLeftCoord, minTopCoord } = this.calculateMinLeftMinTopCoords();

      setTimeout(() => {
        if (minLeftCoord === 1000000 && minTopCoord === 1000000) {
          this.positionsAndLevels.datasetCoords.push({
            source_css: `left: 0px; top: 0px;`,
            source_id: datasetId,
          });
        } else {
          this.positionsAndLevels.datasetCoords.push({
            source_css: `left: ${minLeftCoord}px; top: ${minTopCoord - 150}px;`,
            source_id: datasetId,
          });
        }

        this.callUpdateDatamodelProperties();
      }, 500);
    },
    async handleUpdateDataset(sql) {
      const currentDataset = this.selectedDatamodel?.datasets?.find(
        (d) => d.datasetId === this.selectedCardItem
      );
      const securityFilterParams = this.createSecurityFilterParams(sql);
      const customFilterParams = this.tempCustomFilterParams
        ?.filter(
          (t) => !t.datasetId || t.datasetId === currentDataset.datasetId
        )
        ?.map((t) => {
          // eslint-disable-next-line no-unused-vars
          const { temporaryFilterParamId, ...others } = t;

          return others;
        });

      const result = await this.updateDatasetById({
        queryParam: currentDataset.datasetId,
        bodyParam: {
          ...currentDataset,
          datamodelId: this.selectedDatamodel.id, //backend'den dataset listesinde datamodelId gelmediği için şimdlik bu şekilde gönderiyoruz.
          name: this.createDatamodelPayload.datasets[0].name,
          tableType: this.createDatamodelPayload.datasets[0].tableType,
          sql,
          filterParams: [...securityFilterParams, ...customFilterParams],
        },
      });
      this.handleNotificationPopup(result);
      if (result?.status !== 400) {
        this.addNewDatasetPopupCurrentStep = this.addNewDatasetPopupSteps.NONE;
      }
    },
    async saveSql(sql) {
      if (!this.datasetName) return;

      if (this.$route.name !== routerEnums.DATAMODEL_VIEW) {
        this.handleCreateDatamodel(sql);
      } else {
        if (
          this.addNewDatasetPopupCurrentStep ===
          this.addNewDatasetPopupSteps.SQL_CREATE
        ) {
          this.handleCreateDataset(sql);
        } else if (
          this.addNewDatasetPopupCurrentStep ===
          this.addNewDatasetPopupSteps.SQL_EDIT
        ) {
          this.handleUpdateDataset(sql);
        }
      }
    },
    closeDatamodelSqlEditor() {
      this.addNewDatasetPopupCurrentStep = this.addNewDatasetPopupSteps.NONE;
    },
    createSecurityFilterParams(sql) {
      const securityFilterParams = [];

      for (let i = 0; i < this.securityFilters.length; i++) {
        if (sql.includes(this.securityFilters[i].value)) {
          securityFilterParams.push({
            filterType: filterType.SECURITY,
            name: this.securityFilters?.[i]?.value?.replace(":", ""),
          });
        }
      }

      return securityFilterParams;
    },
    isDataModelPropertiesVisible() {
      this.isDataModelPropertiesToggled = !this.isDataModelPropertiesToggled;
    },
    async openProjectNamePopup(param) {
      if (this.selectedDatamodel.temporary)
        this.isActiveProjectNamePopup = param;
      else this.setDatamodelName(this.selectedDatamodel.name);
    },
    async setQueryTimeout(value) {
      await this.updateQueryTimeout({
        datamodelId: this.selectedDatamodel.id,
        bodyParam: {
          queryTimeout: value,
        },
      });

      this.setSelectedDatamodelQueryTimeout(value);
    },
    async setQueryStatus(value) {
      await this.updateQueryStatus({
        datamodelId: this.selectedDatamodel.id,
        bodyParam: {
          isActive: value,
        },
      });

      await this.fetchDataModelById(this.selectedDatamodel.id);
    },
    async setDatamodelName(name) {
      const payload = {
        name: name,
        id: this.selectedDatamodel.id,
      };

      const result = await this.updateDatamodelName({
        bodyParam: payload,
      });

      result.folderId = this.folderId;
      result.properties = {
        coord: this.positionsAndLevels.datasetCoords,
        zoomLevel: this.positionsAndLevels.zoomLevel,
      };

      await this.updateDataModel({
        queryParam: this.selectedDatamodel.id,
        bodyParam: result,
      });

      this.isActiveProjectNamePopup = false;
    },
    setDatamodelPropertiesValue(params) {
      const clonedSelectedDatamodel = cloneDeep(this.selectedDatamodel);

      if (clonedSelectedDatamodel?.properties === null) {
        clonedSelectedDatamodel.properties = {};
      }

      this.$set(clonedSelectedDatamodel.properties, params.key, params.value);

      this.setSelectedDatamodel(clonedSelectedDatamodel);

      this.updateDatamodelProperties({
        queryParam: this.selectedDatamodel.id,
        bodyParam: {
          ...this.selectedDatamodel?.properties,
          [params.key]: params.value,
        },
        withNotify: true,
      });
    },

    handleAddNewDataset() {
      this.addNewDatasetPopupCurrentStep =
        this.addNewDatasetPopupSteps.SELECT_TYPE;
      this.newDataModelPopupInitialCurrentStep = null;
      this.newDataModelPopupInitialData = null;
    },
    async fetchSql(params, noParams = false) {
      if (this.sqlViewVisible) {
        const response = await this.fetchTestQuery({
          loadingComponent: LoadingComponent.RunSql,
          bodyParam: {
            sqlQuery: params,
            connection: {
              connectionId: this.selectedConnection?.connectionId,
              name: this.selectedConnection?.name,
            },
          },
        });
        this.handleNotificationPopup(response);
      } else {
        if (noParams) {
          const response = await this.fetchTestPreview({
            loadingComponent: LoadingComponent.RunSql,
            bodyParam: {
              datasetId: this.selectedDataset.datasetId,
            },
          });

          this.handleNotificationPopup(response);

          return;
        }

        const processedParams = this.checkFilterParams(params);

        await this.fetchTestPreview({
          loadingComponent: LoadingComponent.RunSql,
          bodyParam: {
            datasetId: this.selectedDataset.datasetId,
            filterParams: processedParams,
          },
        });
      }
    },
    onClosePreview() {
      this.showPreviewSection = false;
    },
    callRunSqlWithSelectedDataset(id) {
      this.setSelectedCardItem(id);
      this.showPreviewSection = true;
      if (this.showPreviewSection) this.runSql(this.selectedDataset?.sql);
    },
    runSql(sql) {
      this.dataModelTemporaryFiltersPopupItems = sql
        .match(/(:[a-zA-Z0-9_çğıöşüÇĞİÖŞÜ]+)/g)
        ?.filter((parametre) => !parametre?.includes(this.ldapAttribute));
      this.dataModelTemporaryFiltersPopupSql = sql;
      if (!this.dataModelTemporaryFiltersPopupItems?.length)
        this.fetchSql(sql, true);
      else {
        this.isDataModelTemporaryFiltersPopupVisible = true;
      }
    },
    async getDatamodelFieldsAndFilters() {
      await this.fetchDatamodelFieldsById(this.selectedDatamodel.id);
      await this.fetchDatamodelByIdFilterParams({
        loadingComponent: LoadingComponent.CustomFilters,
        id: this.selectedDatamodel.id,
      });
    },
    async handleCardItemClick(id) {
      if (this.showPreviewSection && this.selectedDataset?.datasetId !== id) {
        this.onClosePreview();
      }
      this.setSelectedCardItem(id);
      this.setSelectedConnection(this.selectedDataset.connection);
      this.tempCustomFilterParams = this.filteredCustomDatasetFilterParams;
    },
    callUpdateDatamodelProperties: _.debounce(function () {
      if (this.selectedDatamodel.id) {
        const clonedSelectedDatamodel = cloneDeep(this.selectedDatamodel);

        if (!clonedSelectedDatamodel.properties)
          clonedSelectedDatamodel.properties = {};

        this.$set(
          clonedSelectedDatamodel.properties,
          "coord",
          this.positionsAndLevels.datasetCoords
        );
        this.$set(
          clonedSelectedDatamodel.properties,
          "zoomLevel",
          this.positionsAndLevels.zoomLevel
        );

        this.setSelectedDatamodel(clonedSelectedDatamodel);

        this.updateDatamodelProperties({
          queryParam: this.selectedDatamodel.id,
          bodyParam: {
            ...this.selectedDatamodel?.properties,
            coord: this.positionsAndLevels.datasetCoords,
            zoomLevel: this.positionsAndLevels.zoomLevel,
          },
        });
      }

      this.addNewDatasetPopupCurrentStep = addNewDatasetPopupSteps.NONE;
    }, 400),
    async handleCardItemEditClicked(id) {
      this.isDataModelPropertiesToggled = true;
      this.setSelectedCardItem(id);
      this.setSelectedConnection(this.selectedDataset.connection);
      this.tempCustomFilterParams = this.filteredCustomDatasetFilterParams;
      this.addNewDatasetPopupCurrentStep =
        this.addNewDatasetPopupSteps.SQL_EDIT;
      Object.keys(this.selectedDataset).forEach((f) => {
        this.handleDBGeneralPropertyChanged({
          key: f,
          value: this.selectedDataset[f],
        });
      });
    },
    showTheGeneralDataModelPropertyOnTheRight(prop) {
      this.closeColumnBasedProperties();
      this.isShowDataModelProperties = prop;
    },
    setSelectedCardItem(id) {
      this.selectedCardItem = id;
    },
    isCardItemSelected(id) {
      return this.selectedCardItem === id;
    },
    selectConnection(item) {
      this.selectedCardItem = null;
      this.setSelectedConnection(item);
      this.addNewDatasetPopupCurrentStep =
        this.addNewDatasetPopupSteps.SQL_CREATE;
    },
    checkFilterParams(params) {
      let processedParams = params.map((p) => {
        const currentFilterParam = this.filteredCustomDatasetFilterParams.find(
          (t) => {
            if (
              p.filterParamId.includes("_start") ||
              p.filterParamId.includes("_end")
            ) {
              const paramName = p.filterParamId.replace(/_start$|_end$/, "");
              return `:${t.name}` === paramName;
            } else {
              return `:${t.name}` === p.filterParamId;
            }
          }
        );

        return {
          filterParamId: currentFilterParam?.paramId,
          value: currentFilterParam?.multi_select
            ? p.value.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)
            : [p.value],
        };
      });
      return (processedParams = processedParams.reduce((acc, entry) => {
        const existingEntry = acc.find(
          (item) => item.filterParamId === entry.filterParamId
        );
        if (existingEntry) {
          existingEntry.value.push(...entry.value);
        } else {
          acc.push({ ...entry });
        }

        return acc;
      }, []));
    },
  },
};
</script>

<style scoped>
.data-model-cards-and-preview-container {
  display: flex;
  flex-direction: column;
}

.data-model-cards-items {
  flex: 1;
  height: 50%;
}

.data-model-cards-container {
  flex: 1;
}

.data-model-sql-editor-container {
  padding-left: 0;
  padding-right: 0;
  flex: 1;
}

layout {
  width: 100%;
  height: 100%;
  margin: 0;
}

::v-deep .data-flow-linker-popover .el-popover {
  padding: 20px;
}

.data-flow-linker-modal {
  position: absolute;
  top: 32%;
  left: calc(50% - 375px);
}
.preview-container {
  background-color: #f4f7f8 !important;
  flex: 1;
  border-top: 2px solid #d8d8d8;
  height: 50%;
}
.vis-datamodel-cards-and-preview-container {
  display: flex;
  flex-direction: column;
  height: inherit;
  overflow: hidden;
}
.data-model-background {
  background: url("../assets/images/dashboard/Dashboard-Grid.svg") !important;
}
.preview-container ::v-deep .vgt-wrap {
  height: calc(100% - 40px);
}
</style>
