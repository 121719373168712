<template>
  <div>
    <div class="vis-new-data-model-popup">
      <div
        @click="$emit('close')"
        class="vis-new-data-model-popup-header vis-row-box vis-flex--spacebeetwen-Ycenter"
      >
        <h3>{{ $t(addNewDatasetPopupStepHeaders[currentStep]) }}</h3>
        <i class="mdi mdi-close vis-cursor-pointer" aria-hidden="true"></i>
      </div>

      <div class="vis-new-data-model-popup-body">
        <SelectDataSourceType
          v-if="currentStep === addNewDatasetPopupSteps.SELECT_TYPE"
          @datasourceTypeSelected="setSelectedDatasourceType"
        />
        <UploadCsvExcelFile
          v-if="
            currentStep === addNewDatasetPopupSteps.CSV_UPLOAD ||
            currentStep === addNewDatasetPopupSteps.EXCEL_UPLOAD
          "
          :datamodelName="datamodelName"
          :datamodelId="datamodelId"
          :initialData="initialData"
          @handleSuccess="(params) => $emit('handleCsvExcelUpload', params)"
          @closeDatamodelPopup="$emit('close')"
        />
        <div
          v-else-if="
            currentStep === addNewDatasetPopupSteps.SELECT_DATABASE_CONNECTION
          "
        >
          <SelectDatabaseConnection
            :hide-actions="true"
            :hide-create-date="true"
            :hide-update-date="true"
            :filterConnectionTypeBy="availableConnectionTypes"
            @selectConnection="selectConnection"
          />

          <div class="select-database-connection-container-footer">
            <button
              class="vis-btn medium vis-mt--2 next-button"
              @click="handleConnectionSelectedAndApproved"
            >
              {{ $t("generalPages.next") }}
            </button>
          </div>
        </div>

        <div
          v-else-if="
            currentStep === addNewDatasetPopupSteps.SELECT_TABLE_OR_VIEW
          "
          class="select-table-or-view-container"
        >
          <SelectTableOrView
            :selectedConnection="selectedConnection"
            @back="handleSelectTableOrViewBack"
            @done="handleSelectTableOrViewDone"
            @showSql="handleShowSql"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addNewDatasetPopupSteps,
  addNewDatasetPopupStepHeaders,
} from "../../commons/newDatasetPopup";
import SelectDataSourceType from "./newDataModelPopup/SelectDataSourceType";
import UploadCsvExcelFile from "./newDataModelPopup/UploadCsvExcelFile";
import SelectDatabaseConnection from "./../connection/SelectDatabaseConnection";
import SelectTableOrView from "./newDataModelPopup/SelectTableOrView";
import { importTypes } from "../../commons/dataModelTypes";
import { ACTION as ACTION_DATAMODEL } from "../../store/modules/Visualize/DataModel/types";
import { mapActions } from "vuex";
import { LoadingComponent } from "../../store/modules/Visualize/General/loadingComponentDefinitions";
import { v4 as uuidv4 } from "uuid";
import { DB_TYPE } from '../../commons/connection';

export default {
  props: {
    datamodelName: {
      type: String,
      default: null,
    },
    datamodelId: {
      type: String,
      default: null,
    },
    initialCurrentStep: {
      type: String,
      default: null,
    },
    initialData: {
      type: Object,
    },
  },
  components: {
    SelectDataSourceType,
    SelectDatabaseConnection,
    UploadCsvExcelFile,
    SelectTableOrView,
  },
  data() {
    return {
      importTypes: importTypes,
      currentStep: addNewDatasetPopupSteps.SELECT_TYPE,
      addNewDatasetPopupSteps: addNewDatasetPopupSteps,
      addNewDatasetPopupStepHeaders: addNewDatasetPopupStepHeaders,
      selectedConnection: null,
    };
  },
  mounted() {
    this.currentStep = this.initialCurrentStep
      ? this.initialCurrentStep
      : this.addNewDatasetPopupSteps.SELECT_TYPE;
  },
  computed: {
    availableConnectionTypes() {
      return Object.values(DB_TYPE); // will exclude FTP and SMB
    },
  },
  methods: {
    ...mapActions({
      createByDatamodelWizard: ACTION_DATAMODEL.CREATE_BY_DATAMODEL_WIZARD,
      addDatasetsByDatamodelWizard: ACTION_DATAMODEL.ADD_DATASETS_BY_DATAMODEL_WIZARD,
      updateDatamodelProperties: ACTION_DATAMODEL.UPDATE_DATAMODEL_PROPERTIES,
    }),
    setSelectedDatasourceType(type) {
      if (type === this.importTypes.DB)
        this.currentStep =
          this.addNewDatasetPopupSteps.SELECT_DATABASE_CONNECTION;
      else if (type === this.importTypes.CSV)
        this.currentStep = this.addNewDatasetPopupSteps.CSV_UPLOAD;
      else if (type === this.importTypes.EXCEL)
        this.currentStep = this.addNewDatasetPopupSteps.EXCEL_UPLOAD;
    },
    selectConnection(item) {
      this.selectedConnection = item;
    },
    handleConnectionSelectedAndApproved() {
      if (this.selectedConnection) {
        this.currentStep = this.addNewDatasetPopupSteps.SELECT_TABLE_OR_VIEW;
      }
    },
    handleSelectTableOrViewBack() {
      this.currentStep =
        this.addNewDatasetPopupSteps.SELECT_DATABASE_CONNECTION;
    },
    handleShowSql() {
      this.$emit("selectConnection", this.selectedConnection);
      this.currentStep = this.addNewDatasetPopupSteps.SQL_CREATE;
    },
    async handleSelectTableOrViewDone(selectedRowsBySchemaAndTable) {
      const datasets = Object.entries(selectedRowsBySchemaAndTable).flatMap(
        ([schema, tables]) =>
          Object.entries(tables).map(([table, columns]) => ({
            schema: schema,
            table: table,
            columns: columns.map((column) => column.columnName),
            type: importTypes.DB,
            description: "",
            max_row_count: 0,
            connectionId: this.selectedConnection.connectionId,
            properties: {
              store_data: true,
            },
            tableType: null,
          }))
      );

      if (this.datamodelId) {
        // update existing datamodel
        // add new datasets by wizard

        const newDatasetsAdded = await this.addDatasetsByDatamodelWizard({
          bodyParam: datasets.map((ds) => ({
            ...ds,
            datamodelId: this.datamodelId,
          })),
          loadingComponent: LoadingComponent.DatasetWizard,
        });

        if (newDatasetsAdded?.length > 0) {
          this.$emit("setNewDatasetsCoords", newDatasetsAdded);
        }
      } else {
        // create new datamodel
        // add new datasets by wizard

        const createdDatamodel = await this.createByDatamodelWizard({
          bodyParam: {
            name: "temp_" + uuidv4(),
            temporary: true,
            datasets,
          },
          loadingComponent: LoadingComponent.DatasetWizard,
        });

        if (createdDatamodel.id) {
          await this.updateDatamodelProperties({
            queryParam: createdDatamodel.id,
            bodyParam: {
              coord: createdDatamodel.datasets.map((dataset, index) => ({
                source_id: dataset.datasetId,
                source_css: `left: 0px; top: -${index * 150}px;`,
              })),
              zoomLevel: 1,
            },
          });
        }

        this.$emit("redirectToDatamodel", createdDatamodel.id);
      }
    },
  },
};
</script>

<style scoped>
.vis-new-data-model-popup {
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  min-height: 400px;
  background-color: #ffffff;
  border-radius: 7px;
  border: 1px solid #d8d8d8;
}

.vis-new-data-model-popup-header {
  border-bottom: 1px solid #dadcdd;
  padding: 6px 24px;
}

.vis-new-data-model-popup-header-description {
  margin: 15px 0 10px 25px;
}

.vis-new-data-model-popup-header {
  border-bottom: 1px solid #dadcdd;
  padding: 6px 24px;
}

.vis-new-data-model-popup-body {
  height: calc(100% - 50px);
}

::v-deep .select-database-connection-container-body {
  padding: 0 25px;
}

::v-deep .vgt-responsive {
  max-height: 360px !important;
  overflow-y: scroll;
}
.next-button {
  background: var(--primary-lighteen-1) 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: none;
  color: white;
  float: right;
  opacity: 1;
  margin-right: 50px;
  margin-bottom: 25px;
}
.select-table-or-view-container {
  padding: 15px 35px;
}
</style>
