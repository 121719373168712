var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isBeautifyLoading),expression:"isBeautifyLoading"}]},[(Object.keys(_vm.selectedDataModel).length)?_c('InputSearch',{attrs:{"customComponentClass":"vis-dataset-search-input"},on:{"keyupSearchData":function($event){_vm.searchField = $event}}}):_vm._e(),_c('el-collapse',{attrs:{"accordion":_vm.isOneDatasetExpandedActive},model:{value:(_vm.activeCollapseItem),callback:function ($$v) {_vm.activeCollapseItem=$$v},expression:"activeCollapseItem"}},_vm._l((_vm.selectedDataModel.datasets),function(dataSet){return _c('el-collapse-item',{key:dataSet.datasetId,staticClass:"vis-collapse-item vis-dataset-tree",class:{
        'vis-white-space-nowrap': _vm.checkDatasetNameLength(dataSet.name),
        'vis-display-none': _vm.checkDatasetEmpty(dataSet.datasetId),
      },attrs:{"title":_vm.shortCollapseItemTitle(dataSet.name),"name":dataSet.name}},[_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(dataSet.name))]),_c('div',{staticClass:"vis-datasetfield-radiogroup"},[_c('ejs-contextmenu',{attrs:{"id":`beautifyMenu-${dataSet.datasetId}`,"items":_vm.getAllFieldPropertyContextMenuItems(),"select":(arg) => _vm.handleContextMenuItemSelect(arg, dataSet.datasetId),"beforeItemRender":_vm.beforeContextItemHighlight}})],1),(!_vm.isPageDashboard)?_c('i',{staticClass:"vis-collapse-item-three-dot",class:_vm.customIcon.VerticalThreeDot,attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.openBeautifyMenu($event, dataSet.datasetId)}}}):_vm._e()]),_c('Typography',{staticClass:"vis-collapse-item-more-text",attrs:{"variant":"h5","text":"...","showText":_vm.checkDatasetNameLength(dataSet.name),"tooltipEnabled":_vm.checkDatasetNameLength(dataSet.name)},scopedSlots:_vm._u([{key:"tooltipContent",fn:function(){return [_c('span',[_vm._v(_vm._s(dataSet.name))])]},proxy:true}],null,true)}),_c('div',{staticClass:"vis-tools-content vis-mb--1"},[_c('draggable',{staticClass:"list-group",attrs:{"list":_vm.getDatasetFieldsByDatasetId(dataSet.datasetId),"group":{ name: 'datamodelFields', pull: 'clone', put: true },"disabled":_vm.selectedFieldDatasetId != dataSet.datasetId &&
            _vm.selectedFieldDatasetId,"sort":_vm.enabledDraggableSort && !_vm.openPopupFieldId},on:{"sort":function($event){return _vm.onDatamodelFieldMoved(dataSet.datasetId)}}},_vm._l((_vm.getDatasetFieldsByDatasetId(
              dataSet.datasetId
            )),function(field,index){return _c('ul',{key:_vm.getIdOrFieldId(field),staticClass:"list-group-item vis-flex--spacebeetwen-Ycenter"},[_c('div',{staticClass:"vis-w--full"},[(_vm.checkIsHidden(field))?_c('li',{staticClass:"vis-flex--spacebeetwen-Ycenter"},[_c('div',{staticClass:"vis-flex--Ycenter vis-dataModel-list-icons vis-cursor-pointer",class:{
                    'vis-disabled-fields':
                      _vm.selectedFieldDatasetId != dataSet.datasetId &&
                      _vm.drillPathPopupMenu,
                  },on:{"click":function($event){return _vm.$emit('addFunctionToCalculatedColumnEditor', field.alias)}}},[_c('i',{class:[
                      _vm.datasetIcon(field),
                      { 'vis-color-purple': _vm.isCalculatedColumn(field) },
                      { 'vis-color-gray': field.isHidden },
                      { 'vis-color-warning': field.sourceFieldId },
                    ],attrs:{"aria-hidden":"true"}}),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"primary","content":field.screenTip,"placement":"right-start","disabled":!field.screenTip}},[_c('el-tooltip',{staticClass:"item",attrs:{"placement":"right-start","popper-class":"vis-bg-neon-blue","open-delay":2000}},[_c('div',{staticClass:"vis-mx--05 vis-display-flex vis-flex-direction--column",attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"vis-color-quartz vis-text--xsmall"},[_vm._v(_vm._s(_vm.$t("generalPages.alias")))]),_c('span',{staticClass:"vis-color-white"},[_vm._v(_vm._s(field.alias))]),_c('span',{staticClass:"vis-color-quartz vis-text--xsmall vis-mt--05"},[_vm._v(_vm._s(_vm.$t("generalPages.name")))]),_c('span',{staticClass:"vis-color-white"},[_vm._v(_vm._s(`${dataSet.name}.${field.name}`))])]),_c('span',{staticClass:"vis-field-item-span",class:{ 'vis-disable-opacity': field.isHidden }},[_vm._v(_vm._s(field.alias))])])],1)],1),_c('div',{staticClass:"vis-dataset-fields-right-icon-box vis-flex--Ycenter"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('Security Applied')}},[(!_vm.isPageDashboard && !field.accessField)?_c('i',{class:_vm.customIcon.AdminPanelSettings,attrs:{"aria-hidden":"true"}}):_vm._e()]),(!_vm.drillPathPopupMenu)?_c('i',{staticClass:"vis-dataset-dots",class:[
                      { 'vis-disable-opacity': field.isHidden },
                      _vm.customIcon.VerticalThreeDot,
                    ],attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.openContextMenu($event, { item: field, index: index })}}}):_vm._e()],1)]):_vm._e()]),(
                _vm.activeContextMenuId ===
                _vm.contextMenuIdPrefix + _vm.getIdOrFieldId(field)
              )?_c('el-popover',{attrs:{"placement":"right","trigger":"manual","popper-class":"vis-dropdown-popper","value":_vm.openPopupFieldId === _vm.getIdOrFieldId(field)}},[_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('ejs-contextmenu',{attrs:{"id":_vm.contextMenuIdPrefix + _vm.getIdOrFieldId(field),"items":_vm.contextMenuItems,"select":_vm.handleContextMenuItemSelect,"beforeItemRender":_vm.beforeContextItemHighlight}})],1),(
                  _vm.fieldPopupId === _vm.getIdOrFieldId(field) &&
                  _vm.selectedFieldDatasetId === field.datasetId
                )?_c('FieldPopup',{attrs:{"selectedField":_vm.selectedContextMenuItemValue,"selectedItem":_vm.selectedContextMenuItem},on:{"saveField":_vm.saveField,"close":() => _vm.onPopupClose(_vm.popupTypes.fieldPopupId)}}):_vm._e(),(
                  _vm.formatNumberPopupId === _vm.getIdOrFieldId(field) &&
                  _vm.selectedFieldDatasetId === field.datasetId
                )?_c('FormatNumberPopup',{attrs:{"selectedFormat":_vm.selectedFormat,"field":_vm.selectedContextMenuItemValue.alias},on:{"saveFormat":_vm.saveFormat,"closeFormat":() => _vm.onPopupClose(_vm.popupTypes.formatNumberPopupId)}}):_vm._e(),(
                  _vm.formatDatePopupId === _vm.getIdOrFieldId(field) &&
                  _vm.selectedFieldDatasetId === field.datasetId
                )?_c('FormatDatePopup',{attrs:{"selectedFormat":_vm.selectedFormat,"field":_vm.selectedContextMenuItemValue.alias},on:{"saveFormat":_vm.saveFormat,"closeFormat":() => _vm.onPopupClose(_vm.popupTypes.formatDatePopupId)}}):_vm._e(),(
                  _vm.filterPopupId === _vm.getIdOrFieldId(field) &&
                  _vm.selectedFieldDatasetId === field.datasetId
                )?_c('FiltersPopup',{attrs:{"filterType":field.usageType,"selectedComponent":_vm.selectedFilterTabComponent,"filterDetailData":_vm.filterDetailData,"attributeFilterSearchParam":_vm.attributeFilterSearchParam},on:{"saveFilterByAttribute":_vm.saveFilter,"saveFilterByDate":_vm.saveFilter,"saveFilterByMeasure":_vm.saveFilter,"setFilterTabComponent":_vm.setFilterTabComponent,"closeFilters":function($event){return _vm.onPopupClose('filterPopupId')}}}):_vm._e(),(_vm.drillPathPopupMenu === _vm.getIdOrFieldId(field))?_c('DrillPathPopup',{attrs:{"selectedField":_vm.selectedContextMenuItemValue,"fields":_vm.fields},on:{"saveField":_vm.saveField,"closeDrillPathPopupMenu":() => _vm.onPopupClose(_vm.popupTypes.drillPathPopupMenu)}}):_vm._e(),(_vm.groupPopupMenu === _vm.getIdOrFieldId(field))?_c('GroupPopup',{attrs:{"selectedField":_vm.selectedContextMenuItemValue},on:{"closeGroupPopupMenu":() => _vm.onPopupClose(_vm.popupTypes.groupPopupMenu),"getFieldListById":function($event){return _vm.$emit('getFieldListById')}}}):_vm._e()],1):_vm._e()],1)}),0)],1)],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }