var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vis-dashboard-custom-col vis-right-border vis-height-100 vis-scroll-thin"},[_c('div',{staticClass:"vis-tools-box vis-page vis-bg-color-white"},[_c('div',{staticClass:"vis-row vis-tools-tab"},[_c('Typography',{staticClass:"vis-ellipsis-fieldsMenu",attrs:{"variant":"h5","text":_vm.selectedDatamodelName,"tooltipEnabled":true},scopedSlots:_vm._u([{key:"tooltipContent",fn:function(){return [_vm._v(_vm._s(_vm.selectedDatamodelName))]},proxy:true}])})],1),(Object.keys(_vm.selectedDataModel).length)?_c('InputSearch',{attrs:{"customComponentClass":"vis-dataset-search-input"},on:{"keyupSearchData":function($event){_vm.searchField = $event}}}):_vm._e(),_c('el-collapse',{model:{value:(_vm.activeCollapseItem),callback:function ($$v) {_vm.activeCollapseItem=$$v},expression:"activeCollapseItem"}},_vm._l((_vm.selectedDataModel.datasets),function(dataSet){return _c('el-collapse-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.getDatasetFieldsByDatasetId(dataSet.datasetId).length),expression:"getDatasetFieldsByDatasetId(dataSet.datasetId).length"}],key:dataSet.datasetId,staticClass:"vis-collapse-item",class:{
          'vis-white-space-nowrap': _vm.checkDatasetNameLength(dataSet.name),
        },attrs:{"title":_vm.shortCollapseItemTitle(dataSet.name),"name":dataSet.name}},[_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(dataSet.name)+" ")]),[_c('div',{staticClass:"vis-datasetfield-radiogroup"},[_c('ejs-contextmenu',{attrs:{"id":`dashboardDatasetTitleContextMenu-${dataSet.datasetId}`,"items":_vm.contextMenuItems,"select":(arg) =>
                    _vm.handleDashboardDatasetTitleContextMenuItemSelect(
                      arg,
                      dataSet.datasetId
                    )}})],1),_c('i',{staticClass:"vis-collapse-item-three-dot",class:_vm.CustomIcon.VerticalThreeDot,attrs:{"aria-hidden":"true"},on:{"click":function($event){$event.stopPropagation();return _vm.openDashboardDatasetTitleContextMenu(
                  $event,
                  dataSet.datasetId
                )}}})]],2),_c('div',{staticClass:"vis-tools-content vis-mb--1"},[_c('draggable',{staticClass:"list-group",attrs:{"list":_vm.getDatasetFieldsByDatasetId(dataSet.datasetId),"group":{ name: 'people', pull: 'clone', put: false },"sort":false}},_vm._l((_vm.getDatasetFieldsByDatasetId(
                dataSet.datasetId
              )),function(element,index){return _c('ul',{key:element.fieldId || element.datasetId + index,staticClass:"list-group-item"},[_c('li',{staticClass:"vis-flex--spacebeetwen-Ycenter vis-cursor-pointer",class:[
                  {
                    selectedFieldActiveClass:
                      _vm.checkSelectedFieldActive(element),
                  },
                ],attrs:{"draggable":""},on:{"dragend":function($event){return _vm.$emit('passiveFieldBox', false)},"dragstart":function($event){_vm.dragAndDropField(dataSet, element),
                    _vm.$emit('activeFieldBox', true)},"click":[function($event){if(!$event.ctrlKey)return null;$event.stopPropagation();return _vm.$emit('addSelectedFieldsWithKeyCtrl', element)},function($event){if(!$event.shiftKey)return null;$event.stopPropagation();_vm.addSelectedFieldsWithKeyShift(
                    _vm.getDatasetFieldsByDatasetId(dataSet.datasetId),
                    element
                  )}]}},[_c('el-tooltip',{staticClass:"item",attrs:{"placement":"right-start","popper-class":"vis-bg-neon-blue","open-delay":2000}},[_c('div',{staticClass:"vis-mx--05 vis-display-flex vis-flex-direction--column",attrs:{"slot":"content"},slot:"content"},[_c('span',{staticClass:"vis-color-quartz vis-text--xsmall"},[_vm._v(_vm._s(_vm.$t("generalPages.alias")))]),_c('span',{staticClass:"vis-color-white"},[_vm._v(_vm._s(element.alias))]),_c('span',{staticClass:"vis-color-quartz vis-text--xsmall vis-mt--05"},[_vm._v(_vm._s(_vm.$t("generalPages.name")))]),_c('span',{staticClass:"vis-color-white"},[_vm._v(_vm._s(`${dataSet.name}.${element.name}`))])]),_c('div',{staticClass:"vis-flex--Ycenter vis-dataModel-list-icons"},[_c('i',{class:_vm.datasetIcon(element),attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"vis-field-item-span"},[_vm._v(_vm._s(element.alias || element.name))])]),_c('i',{staticClass:"vis-icon icon-dots vis-dataset-dots",attrs:{"aria-hidden":"true"}})])],1)])}),0)],1)],2)}),1)],1),(!_vm.disableArea)?_c('div',{staticClass:"vis-mask"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }