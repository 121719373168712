<template>
  <el-autocomplete
    v-model="state"
    class="vis-width-100"
    :debounce="1000"
    :fetch-suggestions="querySearchAsync"
    :placeholder="$t('generalPages.searchInput')"
    @select="(val) => $emit('handleSelect', val)"
  >
    <i
      class="el-input__icon addAction"
      :class="[customIcon.Plus]"
      slot="suffix"
      aria-hidden="true"
      @click="handleAction"
    >
    </i>
    <template slot-scope="{ item }">
      <el-tooltip
        placement="bottom"
        popper-class="vis-bg-neon-blue"
        :disabled="isTooltipDisabled"
      >
        <template v-slot:content>
          <div class="value vis-line-height-normal">
            {{ item.value }}
          </div>
          <p>
            {{ item.nameSubtitle }}
          </p>
        </template>
        <div @mouseover="controlWidth($event)">
          <div class="value vis-line-height-normal vis-ellipsis">
            {{ item.value }}
          </div>
          <p class="nameSubtitle vis-ellipsis">
            {{ item.nameSubtitle }}
          </p>
        </div>
      </el-tooltip>
    </template>
  </el-autocomplete>
</template>
<script>
import { CustomIcon } from "../../assets/js/custom-icons";
import { checkOffsetAndScrollWidth } from "../../util/utils";

export default {
  props: {
    list: {
      type: Array,
    },
  },
  data() {
    return {
      customIcon: CustomIcon,
      state: "",
      isTooltipDisabled: true,
    };
  },
  methods: {
    handleAction() {
      if (this.state === "") return;

      this.$emit("handleAction");
      this.state = "";
    },
    async querySearchAsync(value, cb) {
      if (value !== "") {
        await this.$emit("querySearchAsync", value);

        setTimeout(() => {
          cb(this.list);
        }, 2000 * Math.random());
      } else cb([]);
    },
    controlWidth(event) {
      this.isTooltipDisabled = checkOffsetAndScrollWidth(event);
    },
  },
};
</script>