var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vis-tools-label-box"},[_c('span',{staticClass:"vis-font-bold"},[_vm._v(_vm._s(_vm.$t(_vm.chartTypeName))+" "+_vm._s(_vm.$t("dashboard.properties.dashboardProperties")))]),(_vm.showCloseIcon)?_c('i',{staticClass:"vis-cursor-pointer",class:_vm.CustomIcon.Close,staticStyle:{"margin-left":"auto"},attrs:{"aria-hidden":"true"},on:{"click":_vm.closeColumnBasedProperties}}):_vm._e()]),_c('el-collapse',{model:{value:(_vm.activeCollapses),callback:function ($$v) {_vm.activeCollapses=$$v},expression:"activeCollapses"}},_vm._l((_vm.dashboardPropertiesCollapses),function(dashboardPropertiesCollapse){return _c('el-collapse-item',{key:dashboardPropertiesCollapse.name,class:{
        'vis-collapse-item': true,
        'p-0': dashboardPropertiesCollapse.hasPadding === false,
      },attrs:{"title":_vm.$t(dashboardPropertiesCollapse.title),"name":dashboardPropertiesCollapse.name,"disabled":_vm.disabledCollapseItem}},_vm._l((dashboardPropertiesCollapse.components),function(dashboardPropertiesCollapseComponent){return _c('RowBox',{key:dashboardPropertiesCollapseComponent.id,style:(dashboardPropertiesCollapseComponent.cssStyle ? dashboardPropertiesCollapseComponent.cssStyle : ''),attrs:{"spacebetween":_vm.spacebetween(dashboardPropertiesCollapseComponent.flexClass),"applyRowBoxStyle":!dashboardPropertiesCollapseComponent.cssStyle,"yCenter":_vm.yCenter(dashboardPropertiesCollapseComponent.flexClass),"paddingLeft":dashboardPropertiesCollapseComponent.paddingLeftClass,"isShowCustomLabel":dashboardPropertiesCollapseComponent.isShowCustomLabel,"customLabelText":dashboardPropertiesCollapseComponent.customLabelText},scopedSlots:_vm._u([(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.SWITCH
          )?{key:"content",fn:function(){return [_c('label',{attrs:{"for":dashboardPropertiesCollapseComponent.id}},[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('el-switch',{attrs:{"id":dashboardPropertiesCollapseComponent.id,"value":dashboardPropertiesCollapseComponent.value,"disabled":dashboardPropertiesCollapseComponent.readOnly ||
              _vm.isCheckDisableSwitch(
                dashboardPropertiesCollapseComponent.property
              )},on:{"change":(value) =>
                _vm.setComponentStyle({
                  property: dashboardPropertiesCollapseComponent.property,
                  value,
                })}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.INPUT
          )?{key:"content",fn:function(){return [_c('label',[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('input',{staticClass:"vis-input",attrs:{"type":dashboardPropertiesCollapseComponent.inputType,"disabled":dashboardPropertiesCollapseComponent.readOnly},domProps:{"value":dashboardPropertiesCollapseComponent.value},on:{"keyup":(e) =>
                _vm.setComponentStyle({
                  property: dashboardPropertiesCollapseComponent.property,
                  value: e.target.value,
                })}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.INPUT_WITH_ENTER
          )?{key:"content",fn:function(){return [_c('label',[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),(!dashboardPropertiesCollapseComponent.readOnly)?_c('input',{staticClass:"vis-input",attrs:{"title":_vm.$t('dashboardPropertiesLocale.general.pressEnter'),"type":dashboardPropertiesCollapseComponent.inputType},domProps:{"value":dashboardPropertiesCollapseComponent.value},on:{"keyup":(e) =>
                _vm.setComponentStyleWithEnterCheck({
                  property: dashboardPropertiesCollapseComponent.property,
                  value: e.target.value,
                  event: e,
                })}}):((dashboardPropertiesCollapseComponent.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(dashboardPropertiesCollapseComponent.value),expression:"dashboardPropertiesCollapseComponent.value"}],staticClass:"vis-input",attrs:{"readonly":"","type":"checkbox"},domProps:{"checked":Array.isArray(dashboardPropertiesCollapseComponent.value)?_vm._i(dashboardPropertiesCollapseComponent.value,null)>-1:(dashboardPropertiesCollapseComponent.value)},on:{"change":function($event){var $$a=dashboardPropertiesCollapseComponent.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(dashboardPropertiesCollapseComponent, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(dashboardPropertiesCollapseComponent, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(dashboardPropertiesCollapseComponent, "value", $$c)}}}}):((dashboardPropertiesCollapseComponent.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(dashboardPropertiesCollapseComponent.value),expression:"dashboardPropertiesCollapseComponent.value"}],staticClass:"vis-input",attrs:{"readonly":"","type":"radio"},domProps:{"checked":_vm._q(dashboardPropertiesCollapseComponent.value,null)},on:{"change":function($event){return _vm.$set(dashboardPropertiesCollapseComponent, "value", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(dashboardPropertiesCollapseComponent.value),expression:"dashboardPropertiesCollapseComponent.value"}],staticClass:"vis-input",attrs:{"readonly":"","type":dashboardPropertiesCollapseComponent.inputType},domProps:{"value":(dashboardPropertiesCollapseComponent.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(dashboardPropertiesCollapseComponent, "value", $event.target.value)}}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.INPUT_NUMBER
          )?{key:"content",fn:function(){return [_c('label',[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('input',{staticClass:"vis-input",attrs:{"type":"number","placeholder":_vm.$t(dashboardPropertiesCollapseComponent.placeHolder),"min":dashboardPropertiesCollapseComponent.minValue,"max":dashboardPropertiesCollapseComponent.maxValue,"step":dashboardPropertiesCollapseComponent.stepValue,"disabled":dashboardPropertiesCollapseComponent.readOnly,"onkeydown":dashboardPropertiesCollapseComponent.discardManualValues ? 'return false' : ''},domProps:{"value":dashboardPropertiesCollapseComponent.value},on:{"input":(e) =>
                _vm.setComponentStyle({
                  property: dashboardPropertiesCollapseComponent.property,
                  value: e.target.value,
                })}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.COLOR_PICKER
          )?{key:"content",fn:function(){return [_c('label',[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('el-color-picker',{attrs:{"value":dashboardPropertiesCollapseComponent.value,"predefine":dashboardPropertiesCollapseComponent.predefineColors,"size":dashboardPropertiesCollapseComponent.size,"disabled":dashboardPropertiesCollapseComponent.readOnly},on:{"change":(value) =>
                _vm.setComponentStyle({
                  property: dashboardPropertiesCollapseComponent.property,
                  value,
                })}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.SELECT_BOX
          )?{key:"content",fn:function(){return [_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('el-select',{attrs:{"value":dashboardPropertiesCollapseComponent.value,"disabled":dashboardPropertiesCollapseComponent.readOnly,"placeholder":_vm.$t('generalPages.selectValue'),"clearable":dashboardPropertiesCollapseComponent.clearable},on:{"change":(value) =>
                _vm.setComponentStyle({
                  property: dashboardPropertiesCollapseComponent.property,
                  value,
                })}},_vm._l((dashboardPropertiesCollapseComponent.selectValues),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.selectboxLabel(item),"value":item.value}})}),1)]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.CUSTOM_MULTIPLE_SELECT_BOX
          )?{key:"content",fn:function(){return [_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('el-select',{attrs:{"value":dashboardPropertiesCollapseComponent.value,"disabled":dashboardPropertiesCollapseComponent.readOnly,"placeholder":_vm.$t('generalPages.selectValue'),"clearable":dashboardPropertiesCollapseComponent.clearable,"multiple":"","collapse-tags":""},on:{"change":(value) =>
              _vm.setComponentStyleWithCustomMultipleSelectBox({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  },
                dashboardPropertiesCollapseComponent)}},[_c('el-option',{key:_vm.selectBoxOption.ALL_ITEM,staticClass:"vis-elselect-option-border-bottom",attrs:{"label":"All Tabs","value":_vm.selectBoxOption.ALL_ITEM}}),_vm._l((dashboardPropertiesCollapseComponent.selectValues),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.selectboxLabel(item),"value":item.value}})})],2)]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.CUSTOM_ICON_SELECT_BOX
          )?{key:"content",fn:function(){return [_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('SelectboxWithIcon',{attrs:{"options":dashboardPropertiesCollapseComponent.selectValues,"value":dashboardPropertiesCollapseComponent.value,"disabled":dashboardPropertiesCollapseComponent.readOnly,"width":"25%"},on:{"handleChange":(value) =>
                _vm.setComponentStyle({
                  property: dashboardPropertiesCollapseComponent.property,
                  value,
                })}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.TEXT_ALIGNMENT_RADIO_BUTTON
          )?{key:"content",fn:function(){return [_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('TextAlignmentRadioButton',{attrs:{"value":dashboardPropertiesCollapseComponent.value},on:{"change":(value) =>
                      _vm.setComponentStyle({
                          property: dashboardPropertiesCollapseComponent.property,
                          value,
                      })}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.ROTATE_ALIGNMENT_RADIO_BUTTON
          )?{key:"content",fn:function(){return [_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('RotateAlignmentRadioButton',{attrs:{"value":dashboardPropertiesCollapseComponent.value},on:{"change":(value) =>
                      _vm.setComponentStyle({
                          property: dashboardPropertiesCollapseComponent.property,
                          value,
                      })}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.OTHER
          )?{key:"content",fn:function(){return [_c(dashboardPropertiesCollapseComponent.component,{tag:"component",attrs:{"value":dashboardPropertiesCollapseComponent.value,"palettes":_vm.colorPalettes},on:{"setComponentStyle":(value) =>
                _vm.setComponentStyle({
                  property: dashboardPropertiesCollapseComponent.property,
                  value,
                }),"setDashboardStyle":(value) =>
                _vm.setDashboardStyle({
                  property:
                    dashboardPropertiesCollapseComponent.dashboardProperty,
                  value,
                })}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.TEXTAREA
          )?{key:"content",fn:function(){return [_c('label',[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('textarea',{staticClass:"vis-textarea vis-textarea-notresize",attrs:{"disabled":dashboardPropertiesCollapseComponent.readOnly,"rows":dashboardPropertiesCollapseComponent.textAreaRow || '2'},domProps:{"value":dashboardPropertiesCollapseComponent.value},on:{"keyup":(event) =>
                _vm.setComponentStyle({
                  property: dashboardPropertiesCollapseComponent.property,
                  value: event.target.value,
                })}})]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.BUTTON
          )?{key:"content",fn:function(){return [_c('el-button',{staticStyle:{"width":"100%"},on:{"click":function($event){return _vm.$emit('onPropertiesEventTriggered', { eventId: dashboardPropertiesCollapseComponent.eventId })}}},[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))])]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.SHOW_BUTTON
          )?{key:"content",fn:function(){return [_c('label',[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$emit('onPropertiesEventTriggered', { eventId: dashboardPropertiesCollapseComponent.eventId })}}},[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.placeholder)))])]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.DATE_RANGE
          )?{key:"content",fn:function(){return [_c('label',[_vm._v(_vm._s(_vm.$t(dashboardPropertiesCollapseComponent.label)))]),_c('div',{staticClass:"vis-flex--spacebeetwen-Ycenter"},[_c('el-date-picker',{class:dashboardPropertiesCollapseComponent.className,attrs:{"value":dashboardPropertiesCollapseComponent.value,"placeholder":_vm.$t(dashboardPropertiesCollapseComponent.placeHolder),"format":"dd/MM/yyyy","picker-options":_vm.pickerOptions(
                  dashboardPropertiesCollapseComponent.isPickerOptionsActive,
                  dashboardPropertiesCollapseComponent.property
                )},on:{"input":(value) =>
                  _vm.setComponentStyle({
                    property: dashboardPropertiesCollapseComponent.property,
                    value,
                  })}})],1)]},proxy:true}:(
            dashboardPropertiesCollapseComponent.componentType ===
            _vm.componentTypes.AUTO_COMPLETE_INPUT_WITH_TABLE
          )?{key:"content",fn:function(){return [_c('AutoCompleteInput',{staticClass:"vis-row-box mb-25",attrs:{"list":_vm.autocompleteQueryList[dashboardPropertiesCollapseComponent.property]},on:{"querySearchAsync":(val) => _vm.handleAutoCompleteSearchWithTable(val, dashboardPropertiesCollapseComponent),"handleSelect":(val) => _vm.handleSelectAutoComplete(val, dashboardPropertiesCollapseComponent),"handleAction":() =>
                _vm.handleAddNewItemByAutoComplete(
                  dashboardPropertiesCollapseComponent.value,
                  dashboardPropertiesCollapseComponent.property
                )}}),_c('PropertiesTable',{attrs:{"columns":dashboardPropertiesCollapseComponent.tableColumns,"rows":dashboardPropertiesCollapseComponent.value},on:{"handleDeleteRow":(id) =>
                _vm.handleDeleteTableRowById(
                  id,
                  dashboardPropertiesCollapseComponent.value,
                  dashboardPropertiesCollapseComponent.property
                )}})]},proxy:true}:null],null,true)})}),1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }