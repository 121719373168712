<template>
  <el-popover
    placement="right"
    trigger="manual"
    popper-class="vis-dropdown-popper vis-custom-popper"
    :value="isAnyPopupVisible"
    class="vis-custom-poper"
  >
    <div slot="reference">
      <PanelCommonsBox
        popups="popups"
        :boxList="selectedPanelOrders"
        :boxName="$t(boxName)"
        :contextMenuId="contextMenuId"
        :draggableList="orderList"
        :panelDataBoxEmptyClass="panelDataBoxEmptyClass"
        :activeFieldBoxClass="activeFieldBoxClass"
        :contextMenuItems="contextMenuItems"
        :selectedDataModel="selectedDataModel"
        @changeDraggableList="onDraggableChanged($event)"
        @openContextMenu="orderContext"
        @setSelectType="setOrder"
        @beforeContextItemHighlight="fieldHighlight"
        @onSortMethodSwitched="onSortMethodSwitched"
      >
      </PanelCommonsBox>
    </div>
  </el-popover>
</template>
<script>
import cloneDeep from "clone-deep";
import PanelCommonsBox from "./PanelCommonsBox.vue";
import {
  getSortOrdersMenuItem,
  getDeleteItemAction,
contextMenuItemKeys,
} from "../../commons/dataModelTypes";
import {
  getCommonsFieldColumnsAndSections,
  updatePanelDataWithAggregationResult,
} from "../../util/data-table/tableCommonsHelper";
import { BOX_KEY_ENUM } from "../../commons/dashboardAndPanel";
import { getHighlightFields } from "../../util/panelDataBoxes";

export default {
  components: {
    PanelCommonsBox,
  },
  props: {
    selectedPanel: {
      type: Object,
    },
    isActiveFieldBox: {
      type: Boolean,
    },
    datasetFields: {
      type: Object,
    },
    designMode: {
      type: Boolean,
    },
    selectedDataModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedOrder: {},
      selectedOrderIndex: null,
      orderByPopup: "",
      measuresfirstValue: "",
      contextMenuId: "ordersMenu",
      boxName: "generalPages.orders",
      contextMenuItems: []
    };
  },
  computed: {
    datasetFieldsByDatasetId() {
      return this.datasetFields?.[this.selectedOrder.datasetId];
    },
    selectedPanelOrders() {
      return this.selectedPanel?.details?.orders;
    },
    selectedPanelOrdersWithBackground() {
      return this.selectedOrder ?? [];
    },
    activeFieldBoxClass() {
      return this.isActiveFieldBox && !this.selectedPanelOrders?.length
        ? "vis-activeFieldBox"
        : "";
    },
    panelDataBoxEmptyClass() {
      return !this.selectedPanelOrders?.length
        ? "vis-panel-data-card-empty"
        : null;
    },
    isAnyPopupVisible() {
      return this.isOrderByPopupVisible;
    },

    isOrderByPopupVisible() {
      return this.orderByPopup == this.selectedOrder?.field;
    },
    orderList() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      return selectedPanelCloned.details?.orders;
    },
  },
  methods: {
    deleteOrder() {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      this.onOtherSortMethodSwitched(
        selectedPanelCloned,
        this.selectedOrder.field,
        null
      );
      selectedPanelCloned.details?.orders?.splice(this.selectedOrderIndex, 1);
      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },
    resetOrderList(value) {
      let field = {};
      let newField = {};
      field = value?.added?.element;
      let params = {
        newField,
        field,
        newIndex: value?.added?.newIndex,
      };
      return params;
    },
    onDraggableChanged(event) {
      const isElementMoved = this.handleElementMoved(event);
      if (!isElementMoved) this.setOrderList(event);
    },
    handleElementMoved(event) {
      if (event?.moved) {
        const { newIndex, oldIndex } = event.moved;

        const selectedPanelCloned = cloneDeep(this.selectedPanel);
        const temp1 = cloneDeep(selectedPanelCloned.details.orders[newIndex]);
        const temp2 = cloneDeep(selectedPanelCloned.details.orders[oldIndex]);

        selectedPanelCloned.details.orders[newIndex] = temp2;
        selectedPanelCloned.details.orders[oldIndex] = temp1;

        this.updateSelectedPanelByDesignMode(selectedPanelCloned);

        return true;
      }
      if (event?.removed) {
        this.selectedOrderIndex = event.removed.oldIndex;
        this.deleteOrder();
        return true;
      }
      return false;
    },
    setOrderList(value) {
      let params = this.resetOrderList(value);
      params.newField = getCommonsFieldColumnsAndSections({
        fieldData: params.field,
        boxKey: BOX_KEY_ENUM.ORDERS.KEY,
        draggedAndDroppedFieldIndex: params.newIndex,
      });
      const selectedPanelCloned = cloneDeep(this.selectedPanel);

      const newOrderField = params.newField;
      selectedPanelCloned?.details?.orders?.push(newOrderField);

      this.onOtherSortMethodSwitched(
        selectedPanelCloned,
        newOrderField?.field,
        newOrderField?.orderType
      );

      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },
    addColumnsInField(selectedPanelCloned, params) {
      const fieldData = params.newField;
      const columns = selectedPanelCloned?.details?.columns;
      const selectedField = columns.find((x) => x.field === fieldData.field);

      if (!selectedField) {
        columns.push(fieldData);

        selectedPanelCloned = updatePanelDataWithAggregationResult(
          selectedPanelCloned,
          params
        );
      }

      return selectedField;
    },
    orderContext(event, params) {
      this.selectedOrder = params?.item;
      this.selectedOrderIndex = params?.index;

      if (!params?.item?.isAuthorize) {
        this.contextMenuItems = [getDeleteItemAction()];
      } else {
        this.contextMenuItems = getSortOrdersMenuItem(params?.item);
      }

      document
        .getElementById(this.contextMenuId)
        .ej2_instances[0].open(event?.y, event?.x);
    },
    setOrder(arg) {
      if (!arg?.item?.value) return;
      
      const value = arg?.item?.value;
      const key = arg?.item?.key;
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      const selectedField = selectedPanelCloned?.details?.orders?.find(
        (f) => f.fieldId === this.selectedOrder?.fieldId
      );

      if (key === contextMenuItemKeys.ORDER_BY) {
        //FOR ORDER BY
        selectedField.orderType = value;
        this.onOtherSortMethodSwitched(
          selectedPanelCloned,
          this.selectedOrder?.field,
          value
        );
        this.updateSelectedPanelByDesignMode(selectedPanelCloned);
      } else if (key === contextMenuItemKeys.AGG_FUNCTION) {
        //FOR AGG FUNCTION
        selectedField.type = value;
        this.updateSelectedPanelByDesignMode(selectedPanelCloned);
      } else {
        //FOR DELETE FIELD ITEM
        this.deleteOrder();
      }
    },
    fieldHighlight(args) {
      getHighlightFields(args, this.selectedOrder);
    },
    updateSelectedPanel(value) {
      this.$emit("updateSelectedPanel", {
        value,
      });
    },
    updateSelectedPanelAndViewChart(value) {
      this.$emit("updateSelectedPanelAndViewChart", {
        value,
      });
    },
    updateSelectedPanelByDesignMode(selectedPanelCloned) {
      if (this.designMode) this.updateSelectedPanel(selectedPanelCloned);
      else this.updateSelectedPanelAndViewChart(selectedPanelCloned);
    },
    closeOrderByPopup(event) {
      this.orderByPopup = event;
    },
    onOtherSortMethodSwitched(selectedPanelCloned, field, sortMethod) {
      if (
        typeof selectedPanelCloned.details === "object" &&
        selectedPanelCloned.details !== null
      ) {
        for (const key in selectedPanelCloned.details) {
          const details = selectedPanelCloned.details[key];

          if (details !== null) {
            for (const detail of details) {
              if (
                detail.boxKey !== BOX_KEY_ENUM.ORDERS.KEY &&
                detail?.field === field
              ) {
                detail.orderType = sortMethod;
              }
            }
          }
        }
      }
    },
    onSortMethodSwitched(sortMethod, order) {
      const selectedPanelCloned = cloneDeep(this.selectedPanel);
      selectedPanelCloned.details.orders.find(
        (i) => i?.field == order?.field
      ).orderType = sortMethod;
      this.onOtherSortMethodSwitched(
        selectedPanelCloned,
        order.field,
        sortMethod
      );
      this.updateSelectedPanelByDesignMode(selectedPanelCloned);
    },
  },
};
</script>
<style scoped></style>
